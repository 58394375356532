import ArchiveIcon from '@mui/icons-material/Archive';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertColor, Box, Button, CardActions, Snackbar, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useSelector } from 'react-redux';
import { getProductPdf, getProductPdfVisualisation, publishProductDocument } from '../api/client/productsApi';
import { RootState } from '../redux/store';
import PdfVersionsDialog from './PdfVersionsDialog';

const DocumentViewer = (props: any) => {
    const { htmlString = '', setHtmlString, sections, selectedDoc, setSelectedDoc, documents, productId, getProductDetails } = props
    const { t } = useTranslation()
    const [numPages, setNumPages] = useState(null);
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const isPublisher = useSelector((state: RootState) => state.authReducer.roles.isPublisher);
    const autoPublished = useSelector((state: RootState) => state.authReducer.user.autoPublished);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const theme = useTheme()
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const windowWidth = useRef(window.innerWidth);
    const [file, setFile] = useState<File>()
    const iframeRef = useRef(null);
    const [isRendering, setIsRendering] = useState(false)
    const [auto, setAuto] = useState('')

    // Use the `onLoad` event to dynamically adjust the iframe height.
    const handleIframeLoad = () => {
        if (iframeRef.current) {
            //@ts-ignore
            iframeRef.current.style.height = `${iframeRef.current.contentWindow.document.body.scrollHeight + 100}px`;
        }
    };

    const handleLoadHtml = async () => {
        try {
            const pdfPreview = await getProductPdfVisualisation(productId, token, selectedDoc?.name)
            setHtmlString(pdfPreview)
            //                 const pdf = await getProductPdf(productId, token, selectedDoc?.name)
            // let tempfile = new File([pdf], "name");
            // setFile(tempfile)
        } catch (e) {
            console.warn(e)
        }
    }

    const handleGeneratePdf = async () => {
        try {
            setIsRendering(true)
            const pdf = await getProductPdf(productId, token, selectedDoc?.name)
            setIsRendering(false)
        } catch (e) {
            setIsRendering(false)
            console.warn(e)
        }
    }

    useEffect(() => {
        if (selectedDoc && documents.length > 0) {
            handleLoadHtml()
        }
    }, [selectedDoc])


    // useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url,
    ).toString();

    //@ts-ignore
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const handleSelect = (document: any) => {
        setSelectedDoc(document);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const checkStatus = () => {
        let status = false
        sections.forEach((section: any) => {
            if (section.status !== "VALIDATED" && section.status !== "PUBLISHED") {
                status = true
                return
            }
        });
        setDisabled(status)
    }

    const handlePublish = async () => {
        setAuto('')
        if(autoPublished){
            setAuto('AutoPublished')
            setOpenAlert(true)
            setAlertSeverity('warning')
        }
        else if (disabled) {
            setOpenAlert(true)
            setAlertSeverity('warning')
        } else {
            setIsLoading(true)
            try {
                const publish = await publishProductDocument(token, productId, selectedDoc?.id)
                const newProd = await getProductDetails(productId)
                setIsLoading(false)
                setOpenAlert(true)
                setAlertSeverity('success')
            } catch (e) {
                setIsLoading(false)
                setOpenAlert(false)
                setAlertSeverity('error')
                console.warn(e)
            }
        }
    }

    useEffect(() => {
        checkStatus()
    }, [sections])

    useEffect(() => {
        // Add an event listener to the window to adjust the iframe when the content changes.
        window.addEventListener('resize', handleIframeLoad);

        // Cleanup the event listener when the component unmounts.
        return () => {
            window.removeEventListener('resize', handleIframeLoad);
        };
    }, []);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <PdfVersionsDialog
                selectedDoc={selectedDoc}
                open={open}
                onClose={handleClose}
            />
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '95%', mt: 2 }}>
                <Box >
                    {documents.length > 0 && documents.map((document: any) => (
                        <Button key={document?.name} sx={{ color: 'white', mr: 1, mb: 1, opacity: selectedDoc?.name === document?.name ? 1 : 0.5 }} onClick={() => handleSelect(document)}>{document?.name}</Button>
                    ))}
                </Box>
                <Box>

                    <LoadingButton
                        loading={isRendering}
                        variant="contained"
                        size="medium"
                        loadingPosition='end'
                        disabled={isRendering}
                        sx={{
                            mr: 1, mb: 1, backgroundColor: isRendering ? theme.palette.grey[400] : theme.palette.secondary.main, '&:hover': {
                                backgroundColor: isRendering ? theme.palette.grey[400] : theme.palette.secondary.main
                            },
                            '&:disabled': {
                                backgroundColor: theme.palette.grey[400]
                            }
                        }}
                        endIcon={<VisibilityIcon />}
                        onClick={handleGeneratePdf}>
                        {t('main.products.product.preview')}
                    </LoadingButton>
                    <LoadingButton
                        loading={false}
                        variant="contained"
                        size="medium"
                        loadingPosition='end' sx={{ color: 'white', mb: 1 }}
                        endIcon={<ArchiveIcon />}
                        onClick={handleClickOpen}>
                        {t('main.products.product.archives')}
                    </LoadingButton>
                </Box>
            </Box>
            <div style={{
                // height: 750, overflowY: 'auto',
                height: '100%', width: '100%', padding: 20,

            }}>
                {/* <div style={{padding:20, width:'100%'}} dangerouslySetInnerHTML={{__html: htmlString}} /> */}
                <iframe srcDoc={htmlString} title='doc' style={{ width: '100%', padding: 20, border: 'none' }} ref={iframeRef} onLoad={handleIframeLoad} />
                {/* <Document
                    // file={selectedDoc.lastVersions[selectedDoc.lastVersions.length - 1].pdfToView}
                    file={file}
                    onLoadSuccess={onDocumentLoadSuccess}
                   className={'pdfCanvas'}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.4}/>
                    ))}
                </Document> */}
            </div>
            <CardActions sx={{
                // justifyContent: 'flex-end',
                margin: 0,
                top: 'auto',
                // right:'6%',
                bottom: 10,
                left: 'auto',
                position: 'sticky',
                // Adding media query..
                '@media (min-width: 400px)': {
                    position: 'fixed',
                    right: '13%'
                },
            }}>
                {isPublisher && <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                    sx={{
                        mt: 5, mb: 2, backgroundColor: disabled || autoPublished ? theme.palette.grey[400] : theme.palette.success.main, '&:hover': {
                            backgroundColor: disabled || autoPublished ? theme.palette.grey[400] : theme.palette.success.main
                        },
                        '&:disabled': {
                            backgroundColor: theme.palette.grey[400]
                        }
                    }}
                    onClick={handlePublish}

                >{t('general.publish')}
                </LoadingButton>}
            </CardActions>
            <Snackbar open={openAlert} autoHideDuration={3000} onClose={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleCloseAlert} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
                    {t(`main.products.product.alertMessage.${alertSeverity}${auto}`)}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default DocumentViewer