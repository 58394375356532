
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClientDetails } from '../api/client/clientCrudApi';
import UsersList from '../components/UsersList';
import { ClientInterface } from '../interfaces/clientInterfaces';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { SiteInterface } from '../interfaces/siteInterfaces';
import { UserInterface } from '../interfaces/userInterfaces';
import { RootState } from '../redux/store';

const UsersListAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const clientId = useSelector((state: RootState) => state.authReducer.user.clientId);
  const [sections, setSections] = useState<SectionInterface[]>([])
  const [sites, setSites] = useState<SiteInterface[]>([])
  const [users, setUsers] = useState<UserInterface[]>([])
  const [client, setClient] = useState<ClientInterface>()

  useEffect(() => {
    getClientDetailsData()
  }, [])
  
  
  const getClientDetailsData = async() =>{
    try{
        if(clientId){
      const clientData = await getClientDetails(clientId, token)
      let clientInfo= {
        id:clientData.id,
        name:clientData.name,
        externalId:clientData.externalId,
        lang:clientData.lang,
        autoPublished:clientData.autoPublished,
        logoUrl:clientData.logoUrl
      }
      setClient(clientInfo)
      setSites(clientData.sites)
      setSections(clientData.productSections)
      setUsers(clientData.users)
        }
    }
    catch(e){
      console.warn(e)
    }
  }


  const handleAddUserClick = () => {
    navigate(`/client/${clientId}/users/add`)
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false} >
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
              {t('general.users')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddUserClick}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <UsersList clientId={clientId!} users={users} sections={sections} />


        </Stack>
      </Container>
    </Box>
  )
}

export default UsersListAdmin