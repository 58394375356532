import { baseDevUrl } from "../urls";

export interface LoginBody {
    username : string;
    password : string;
    rememberMe : boolean; 
}

export const  handleLoginRequest = async (body : LoginBody) => {
	const response = await fetch(`${baseDevUrl}/api/authenticate`, {
     
    // Adding method type
    method: "POST",
     
    // Adding body or contents to send
    body: JSON.stringify(body),
     
    // Adding headers to the requestz
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"

    }
})
	
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}

export const  impersonate = async (token: string, userName : string) => {
	const response = await fetch(`${baseDevUrl}/api/switch/${userName}`, {
     
    // Adding method type
    method: "POST",
     
    // Adding body or contents to send
    // body: JSON.stringify(body),
     
    // Adding headers to the requestz
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"

    }
})
	
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}