import { CachedRefDataInterface, RefDataInterface, RefDataTranslationInterface, RefDataTypeInterface } from "../interfaces/refDataInterfaces"

const getTranslation = (value:CachedRefDataInterface | RefDataInterface, selectedLanguage:string, ref?:boolean) =>{
    //@ts-ignore
    const translatedLabel =  value?.translations?.find(item => item?.lang?.toLowerCase() === selectedLanguage.toLowerCase())
    if(ref){
        return translatedLabel?.label
    }
    return translatedLabel?.label 
    }

const getTypeTranslation = (value:RefDataTypeInterface, selectedLanguage:string) =>{
    //@ts-ignore
    const translatedLabel =  value?.translations?.find(item => item?.lang?.toLowerCase() === selectedLanguage.toLowerCase())
    // if(ref){
    //     return translatedLabel?.label
    // }
    return translatedLabel?.label
    }
export  {getTypeTranslation, getTranslation}
