import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useQueryState = <T extends string, U extends string>(
  query1: string,
  query2: string
): [T | null, U | null, (value1: T, value2: U) => void] => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery = useCallback(
    (value1: T, value2: U) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(query1, value1);
      searchParams.set(query2, value2);

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [navigate, location, query1, query2]
  );

  return [
    new URLSearchParams(location.search).get(query1) as T | null,
    new URLSearchParams(location.search).get(query2) as U | null,
    setQuery,
  ];
};
