import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sections:[]
}

const productReducer = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplate: (state, action) => {
        state.sections = action.payload
     },
    // setSelectedType: (state, action) => {
    //     state.selectedType = action.payload
    //  }
    },
  },
)

export const { setTemplate } = productReducer.actions

export default productReducer.reducer
