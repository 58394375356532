import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEditableRefData } from '../api/client/clientEditableRefDataApi';
import { getTranslation } from '../helpers/translations';
import { RefDataInterface } from '../interfaces/refDataInterfaces';
import { RootState } from '../redux/store';
import CustomColumnMenu from '../utils/CustomColumnMenu';
import CustomActionsCell from './CustomActionsCell';
import CustomBooleanCell from './CustomBooleanCell';

interface ClientReferenceDataListPropsInterface {
  clientId?: string
  typeId?: string
  setMaxKey: (key : string)=>void
}
const UserRefList = (props: ClientReferenceDataListPropsInterface) => {
  const { clientId, typeId, setMaxKey} = props
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const [userRefDataRows, setUserRefDataRows] = useState<RefDataInterface[]>([])
  const navigate = useNavigate()

  const getEditableRefDataById = async () => {
    try {
      if (clientId) {
        const userRefData = await getEditableRefData(parseInt(clientId!), parseInt(typeId!), token)
        let tmpUserDataRef: any[] = []
        userRefData.forEach((refData: RefDataInterface) => {
          tmpUserDataRef.push({
            id: refData.id,
            key: refData.key,
            labelFR: getTranslation(refData, 'fr', true) || '',
            labelEN: getTranslation(refData, 'en', true)  || '',
            labelNL: getTranslation(refData, 'nl', true)  || '',
            active:refData.active
          });
        });
        setUserRefDataRows(tmpUserDataRef)
        let maxKey = userRefData.length>0 ? Math.max(...userRefData.map((ref:RefDataInterface) => parseInt(ref.key))) : 0
        let newKey = maxKey+1
        setMaxKey(newKey.toString())
      }
    }
    catch (e) {
      console.warn(e)
    }
  }



  useEffect(() => {

    getEditableRefDataById()
    
  }, [])


  const handleEdit = (keyId: number) => {
      navigate(`/client/${clientId}/userRef/${typeId}/keys/edit/${keyId}`)
  }

  const handleEvent: GridEventListener<'cellClick'> = (
    params,  // GridCellParams<any>
    event,   // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    if(params.field !=='actions'){
    handleEdit(params.row.id)
  }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align:'left' },
    {
      field: 'key',
      headerName: `${t('main.refData.form.key')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'labelFR',
      headerName: `${t('main.refData.form.labelFR')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'labelEN',
      headerName: `${t('main.refData.form.labelEN')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'labelNL',
      headerName: `${t('main.refData.form.labelNL')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'active',
      headerName: `${t('main.refData.form.active')}`,
      editable: false,
      flex: 2,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        <CustomBooleanCell value={params.value} />
      )
    },
    {
      field: 'actions',
      headerName: `${t('general.actions')}`,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      maxWidth:160,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} />
      )
    }
  ];


  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={userRefDataRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              }
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            onCellClick={handleEvent}
            slots={{ columnMenu: CustomColumnMenu }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default UserRefList