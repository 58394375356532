import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClientRefDataTypes } from '../api/client/clientRefDataApi';
import { getCommunRefDataTypes } from '../api/shared/communRefDataApi';
import { getTypeTranslation } from '../helpers/translations';
import { RefDataTypeInterface } from '../interfaces/refDataInterfaces';
import { setSelectedType } from '../redux/reducers/selectedItemsReducer';
import { RootState } from '../redux/store';
import CustomColumnMenu from '../utils/CustomColumnMenu';
import CustomActionsCell from './CustomActionsCell';

interface RefDataTypesListPropsInterface {
  clientId?: string
  commun?:boolean
}
const RefDataTypesList = (props: RefDataTypesListPropsInterface) => {
  const { clientId, commun=false } = props
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const [refDataTypesRows, setRefDataTypesRows] = useState<RefDataTypeInterface[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const getClientTypes = async () => {
    try {
      if (clientId) {
        const refDataTypes = await getClientRefDataTypes(parseInt(clientId!), token)
        let tmpDataRefTypes: any[] = []
        refDataTypes.forEach((type: RefDataTypeInterface) => {
            tmpDataRefTypes.push({
              id: type.id,
              labelFR: getTypeTranslation(type, 'fr') || '',
              labelEN: getTypeTranslation(type, 'en') || '',
              labelNL: getTypeTranslation(type, 'nl') || '',
              type: type.type,
            })
        });
        setRefDataTypesRows(tmpDataRefTypes)

      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  const getCommunTypes = async () => {
    try {
        const refDataTypes = await getCommunRefDataTypes( token)
        let tmpDataRefTypes: any[] = []
        refDataTypes.forEach((type: RefDataTypeInterface) => {
            tmpDataRefTypes.push({
              id: type.id,
              labelFR: getTypeTranslation(type, 'fr') || '',
              labelEN: getTypeTranslation(type, 'en') || '',
              labelNL: getTypeTranslation(type, 'nl') || '',
              type: type.type,
            })
        });
        setRefDataTypesRows(tmpDataRefTypes)
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if(commun){
      getCommunTypes()
    }else{
    getClientTypes()
}
  }, [commun])


    const handleEdit = (typeId: number) => {
      if(commun){
        navigate(`/commun/referenceDataType/edit/${typeId}`)

      }else{
        navigate(`/client/${clientId}/referenceDataType/edit/${typeId}`)
      }
    }

    const handleEvent: GridEventListener<'cellClick'> = (
      params,  // GridCellParams<any>
      event,   // MuiEvent<React.MouseEvent<HTMLElement>>
      details, // GridCallbackDetails
    ) => {
      if (params.field !== 'actions') {
        dispatch(setSelectedType({name:params.row.type, id:params.row.id}))
        if(commun){
          navigate(`/commun/referenceData/${params.id}/list`)
  
        }else{
          navigate(`/client/${clientId}/referenceData/${params.id}/list`)
        }
      }
    }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align:'left'  },
    {
      field: 'type',
      headerName: `${t('main.refData.form.type')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'labelFR',
      headerName: `${t('main.refData.form.labelFR')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'labelEN',
      headerName: `${t('main.refData.form.labelEN')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'labelNL',
      headerName: `${t('main.refData.form.labelNL')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: `${t('general.actions')}`,
      editable: false,
      sortable:false,
      filterable:false,
      disableColumnMenu:true,
      flex: 1,
      maxWidth:160,
      renderCell: (params: GridRenderCellParams) => (
        <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} />
      )
    }
  ];


  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={refDataTypesRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              }
            }}
            pageSizeOptions={[5, 10]}
            onCellClick={handleEvent}
            disableRowSelectionOnClick
            slots={{ columnMenu: CustomColumnMenu }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default RefDataTypesList