import DescriptionIcon from '@mui/icons-material/Description';
import { Tooltip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProductDocumentsInterface, ProductDocumentsVersionsInterface } from '../interfaces/productInterfaces';

interface CustomPublishedDocumentCellInterface {
    documents?: ProductDocumentsInterface[]
}

const CustomPublishedDocumentCell = (props : CustomPublishedDocumentCellInterface) => {
    const {documents} = props
    const {t} = useTranslation()
    const theme= useTheme()
    const handleClick = (lastVersions : ProductDocumentsVersionsInterface[]) =>{
        if(lastVersions.length===0){
            return
        }
        window.open(lastVersions[0].url, "_blank")
    }

    const getTooltipTitle = (document: ProductDocumentsInterface) =>{
        if(document.lastVersions.length>0){
            return `${t('main.products.product.version')}: ${document.lastVersions[0]?.version}, ${document.lastVersions[0]?.publishedAt.slice(0, 10)}  ${document.lastVersions[0]?.publishedAt.slice(11, 19)} ${t('general.by')}: ${document.lastVersions[0]?.publishedBy}`
        }else{
            return `${t('main.products.list.noVersion')}`
        }
    }

    
  return (
    <div className='MuiDataGrid-cellContent' style={{display:'flex', flexDirection:'column',}}>
        {documents && documents?.length>0 && documents?.map((document:ProductDocumentsInterface)=>(
            <Tooltip key={document.id} title={getTooltipTitle(document)}>
            <div onClick={()=>{handleClick(document.lastVersions)}}>
            <DescriptionIcon color={document.lastVersions.length>0? 'success' : 'disabled'} />
            </div>
            </Tooltip>

        )
        
        )}
   {/* { documents.length && ?<Link
              href={`${url}`}
              underline="hover"
              variant="subtitle2"
              target="_blank" 
              rel="noopener"
            >
              Url
            </Link>:<div className='MuiDataGrid-cellContent'>No url</div>}
            {/* <OpenInNewIcon sx={{color:theme.palette.secondary.main, fontSize:'0.95rem', marginLeft:0.4}} /> 
            <Avatar src={`${url}`} sx={{marginLeft : 0.8}}  children={<ImageIcon />} /> */}
            </div>
  )
}

export default CustomPublishedDocumentCell