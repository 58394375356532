import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableHeader } from '../interfaces/dynamicFormInterfaces';

interface ProductTablePropsInterface {
  headers: TableHeader[]
  rows: any[]
}
const ProductTable = (props: ProductTablePropsInterface) => {
  const { headers=[], rows=[] } = props

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ minWidth: 400 }}>
          <TableRow>
            {
              headers?.map((header: TableHeader, index:number) => (
                <TableCell key={header.key} >{header.label['fr']}</TableCell>
              ))
            }

          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, idx:number) => (
            <TableRow
              key={idx}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             {
              headers?.map((header: TableHeader, index:number) => (
                <TableCell sx={{ minWidth: 180 }} key={header.key + index.toString()} >{typeof(row[header.key])!== 'object'? row[header.key] : row[header.key]?.join(', ')}</TableCell>
              ))
            }
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ProductTable