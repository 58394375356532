import { Grid, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { getTranslation } from '../helpers/translations'
import useSearchByKey from '../hooks/useSearchByKey'
import { CodeValue } from '../interfaces/dynamicFormInterfaces'
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces'

const Phrases = (props: any) => {
    const { control, phrases, setValue, selectedLanguage, fieldName, formState, setFormState, disabled=false } = props
    let keysToSearch = phrases?.map((phrase: CodeValue) => phrase.code)
    const searchResults = useSearchByKey(keysToSearch)


    const getPhrase = (key: string) => {
        let refDataObj = searchResults.find((item: CachedRefDataInterface) => item.key === key)
        if (refDataObj) {
            const label = getTranslation(refDataObj, selectedLanguage)
            return label
        }
    }

    useEffect(() => {
        phrases?.map((phrase: CodeValue) => {
            if (phrase.label) {
                setValue(phrase.code, phrase.label)
            } else {
                const label = getPhrase(phrase.code)
                setValue(phrase.code, label)
                if (searchResults) {
                    const tmpState = formState[fieldName];
                    const statementToUpdate: CodeValue = tmpState?.find(
                        (statement: CodeValue) => statement.code === phrase.code
                    );
                    if (statementToUpdate) {
                        statementToUpdate.label = label!
                        setFormState((formState: any) => ({
                            ...formState,
                            [fieldName]: tmpState
                        }));
                    }

                }
            }
        })
    }, [phrases, searchResults])

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, phrase: CodeValue) => {
        //Step 1: make a copy of the current state
        const tmpState = [...formState[fieldName]] || [];

        // Step 2: Find the hazard statement to update
        const statementToUpdate = tmpState?.find(
            (statement: CodeValue) => statement.code === phrase.code
        );

        // Step 3: Modify the label of the hazard statement
        statementToUpdate.label = event.target.value

        // Step 4: Update the state object with the modified hazard statement
        setValue(phrase.code, event.target.value)
        setFormState((formState: any) => ({
            ...formState,
            [fieldName]: tmpState
        }));
    }


    return (
        <Grid container spacing={3}>
            {
                phrases?.map((phrase: CodeValue) => (
                    <Grid
                        item
                        xs={12}
                        // md={(field.inputType === 'separator' || !field.display)? 12 : field.display}
                        md={12}
                        key={phrase.code}
                    >
                        <Controller
                            control={control}
                            name={phrase.code}
                            // rules={rules}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    // sx={fieldName==='exp1'?exp1:null}
                                    {...field}
                                    InputLabelProps={{ shrink: field?.value ? true : false }}
                                    multiline
                                    fullWidth
                                    disabled={disabled}
                                    label={'Phrase : ' + phrase.code}
                                    type="text"
                                    error={error !== undefined}
                                    onChange={(e) => { handleFieldChange(e, phrase) }}
                                // helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                                />
                            )}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Phrases
