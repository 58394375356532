import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSite } from '../api/client/siteCrudApi';
import SiteForm from '../components/SiteForm';
import { RootState } from '../redux/store';

const NewSite = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, siteId } = useParams()
  const [site, setSite] = useState()
  const getSiteData = async () => {
    try {
      if (siteId && clientId) {
        const resp = await getSite(parseInt(clientId), parseInt(siteId), token)
        setSite(resp)
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (clientId && siteId) {
      getSiteData()
    }
  }, [clientId, siteId])

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
              {!site ? t('main.site.form.addSite') : t('main.site.form.editSite')}
              </Typography>
              <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
            </div>
            <div>
              <Grid
                container
                spacing={3}
              >
                {/* <Grid
              xs={12}
              md={6}
              lg={4}
            >
              <AccountProfile />
            </Grid> */}
                <Grid
                  xs={12}
                  md={8}
                  lg={8}
                >
                  <SiteForm site={site} clientId={clientId? parseInt(clientId) : undefined} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    )
  }

  export default NewSite