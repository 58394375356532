import { baseDevUrl } from "../urls";

export const  getAccountDetails = async ( token:string) => {

	const response = await fetch(`${baseDevUrl}/api/account`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    
    return data
    // return response.ok
}