import ImageIcon from '@mui/icons-material/Image';
import { Avatar, ImageListItem, useTheme } from '@mui/material';
interface CustomUrlCellInterface {
    value: string
    logo?:boolean
}
const CustomUrlCell = (props : CustomUrlCellInterface) => {
    const {value, logo=false} = props
    const theme= useTheme()
  return (
    <div className='MuiDataGrid-cellContent' style={{display:'flex', alignItems:'center'}}>

            {/* <OpenInNewIcon sx={{color:theme.palette.secondary.main, fontSize:'0.95rem', marginLeft:0.4}} /> */}
            {logo && value?
            <ImageListItem sx={{ width: 80, height: 50 }}>

            <img
              style={{ borderRadius: 12, width: 80, height: 50, objectFit: 'contain' }}
              src={value}
              alt={'logo'}
              loading="lazy"
            />
          </ImageListItem> 
          :<Avatar src={`${value}`} children={<ImageIcon />} />}
            </div>
  )
}

export default CustomUrlCell