import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { persistor, store } from './redux/store'
import './translations/config'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createTheme } from './theme';
import './index.css'
import { PersistGate } from 'redux-persist/integration/react';
import { init as i18nInit } from './translations/config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = createTheme();
const onBeforeLift = async () => {
  await i18nInit();
};


root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
    <ThemeProvider theme={theme}>
            <CssBaseline />

      <App />
      </ThemeProvider>
      </PersistGate>
    </Provider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
