import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertColor,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Unstable_Grid2 as Grid,
    Snackbar,
    Switch,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addEditableRefData, editEditableRefData } from '../api/client/clientEditableRefDataApi';
import { AddRefDataBody } from '../api/client/clientRefDataApi';
import { getTranslation } from '../helpers/translations';
import { RefDataInterface } from '../interfaces/refDataInterfaces';
import { RootState } from '../redux/store';

export interface RefDataFormPros {
    refData?: RefDataInterface
    typeId?: number
    keyId?: number
    clientId?: number
}
const UserRefForm = (props: RefDataFormPros) => {
    const { refData, typeId, keyId, clientId,  } = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState('Create')
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
    const [open, setOpen] = useState(false);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const theme = useTheme()
    const { control, handleSubmit, setValue, reset } = useForm({
        reValidateMode: 'onBlur'
    })
    const { state } = useLocation();

    const [errorType, setErrorType] = useState('')

   


    useEffect(() => {
        if (refData) {
            setValue('key', refData.key)
            setValue('labelFR', getTranslation(refData, 'fr', true) || '')
            setValue('labelEN', getTranslation(refData, 'en', true) || '')
            setValue('labelNL', getTranslation(refData, 'nl', true) || '')
            setValue('active', refData.active)
            setMode('Edit')
        } else {
            setMode('Create')
            setValue('key', state?.key)
        }

    }, [refData, state])



    const handleAddRefData = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body: AddRefDataBody = {
                key: data.key,
                active:data.active,
                translations: [
                    {
                        lang: 'FR',
                        label: data.labelFR
                    },
                    {
                        lang: 'EN',
                        label: data.labelEN
                    },
                    {
                        lang: 'NL',
                        label: data.labelNL
                    }
                ]
            }
           
                const resp = await addEditableRefData(body, clientId!,typeId!, token)

            
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            reset()
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }

    const handleEditRefData = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body: AddRefDataBody = {
                key: data.key,
                active:data.active,
                translations: [
                    {
                        lang: 'FR',
                        label: data.labelFR
                    },
                    {
                        lang: 'EN',
                        label: data.labelEN
                    },
                    {
                        lang: 'NL',
                        label: data.labelNL
                    }
                ]
            }
            
                const resp = await editEditableRefData(body,clientId!,typeId!, keyId!, token)
            
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            //  navigate('/', {replace:true})
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }
    const handleFormSubmit = async (data: FieldValues) => {
        if (!refData) {
            handleAddRefData(data)
        } else {
            handleEditRefData(data)
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string | undefined) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} >
            <Card>
                <CardHeader
                    subheader={t('main.refData.form.addRefDataDesc')}
                // title="Profile"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="key"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        // minLength: 4,
                                        // maxLength: 20
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            label={t('main.refData.form.key')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelFR"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        // minLength: 4,
                                        // maxLength: 20
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            label={t('main.refData.form.labelFR')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelEN"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.labelEN')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelNL"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.labelNL')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                xs={12}
                md={4}
              >
                <Box sx={{display:'flex', alignItems:'center',height:'100%'}}>
                <Typography variant="subtitle2" sx={{mr:2}}>
                   {t('main.refData.form.active') + ' : ' }
              </Typography>
                <Controller
                  control={control}
                  name="active"
                  defaultValue={true}
                  render={({ field, fieldState: { error } }) => (
                    <Switch {...field}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                    
                  )}
                />
                </Box>
              </Grid>
                           
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 2 }}
                        type="submit"

                    >{t('general.save')}
                    </LoadingButton>
                </CardActions>
            </Card>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
                    {t(`main.refData.alertMessage.${alertSeverity}${mode}${errorType}`)}
                </Alert>
            </Snackbar>
        </form>
    );
}

export default UserRefForm