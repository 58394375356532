import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog,
    DialogTitle,
    Divider,
    Unstable_Grid2 as Grid,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddRefDataBody, addEditableRefData, disableEditableRefData } from '../api/client/clientEditableRefDataApi';
import { getTranslation } from '../helpers/translations';
import { CodeValue } from '../interfaces/dynamicFormInterfaces';
import { RefDataInterface } from '../interfaces/refDataInterfaces';
import { RootState } from '../redux/store';


const ChangeableListDialog = (props: any) => {
    const { refData, open, setOpen, selectedItems, selectedItem, setFormState, fieldName, typeId, rechargeList, update, setUpdate, multipleSelect } = props
    const { control, handleSubmit, setValue, reset, getValues } = useForm({
        reValidateMode: 'onBlur'
    })
    const { t } = useTranslation()
    const theme = useTheme()
    const [showForm, setShowForm] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const clientLang = useSelector((state: RootState) => state.authReducer.user.clientLang);
    const clientId = useSelector((state: RootState) => state.authReducer.user.clientId);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const [tmpSelectedItems, setTmpSelectedItems] = useState(selectedItems)
    const [tmpSelectedItem, setTmpSelectedItem] = useState(selectedItem)
    const [isLoading, setIsLoading] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<RefDataInterface | undefined>()


    const handleClose = () => {
        setOpen(false)
    }

    const handleToggle = (value: any) => () => {
        const currentIndex = tmpSelectedItems?.map((e: any) => e.code).indexOf(value.key);
        const newChecked = [...tmpSelectedItems];
        if (currentIndex === -1) {
            const selected = {code : value.key, label: getTranslation(value, clientLang )}
            newChecked.push(selected);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setTmpSelectedItems(newChecked);
    };

    const handleRadioSelect = (value: any) => () => {

        const selected = { code: value.key, label: getTranslation(value, clientLang) }

        setTmpSelectedItem(selected);
    };
    

    const updateValues = () => {
        if(multipleSelect){
        const tmpRefData = refData.filter((ref:RefDataInterface) =>
            tmpSelectedItems.some((element:CodeValue) =>( element.code === ref.key && ref.active===true))
          );
        const newValues = tmpRefData.map((val:RefDataInterface)=> ({code:val.key, label:getTranslation(val,clientLang)}))
        
        setFormState((formState: any) => ({
            ...formState,
            [fieldName]: newValues
        }));
    }else{
        const tmpRefData = refData.filter((ref:RefDataInterface) => tmpSelectedItem?.code === ref.key && ref.active===true)
          
        const newValue = tmpRefData.length>0?  { code: tmpRefData[0].key, label: getTranslation(tmpRefData[0], clientLang) } : {} 
        setFormState((formState: any) => ({
            ...formState,
            [fieldName]: newValue
        }));
    }
        setUpdate(true)
        setOpen(false)
    }

    const toggleDeleteAlert = (item : RefDataInterface) =>{
        setItemToDelete(item)
        setShowDelete(true)
    }

    const handleDelete = async() =>{
        try{
            const del = await disableEditableRefData(clientId!,typeId!, parseInt(itemToDelete?.id!), token)
            const refresh = await rechargeList()
            setShowDelete(false)
        }catch(e){
            console.warn(e)
        }
    }

    const cancelDelete = () =>{
        setItemToDelete(undefined)
        setShowDelete(false)
    }
    
    const handleUpdate = async() => { 
        const data = getValues()
            setIsLoading(true)
            let maxKey = refData.length>0? Math.max(...refData.map((ref:RefDataInterface) => parseInt(ref.key))) : 0
            let newKey = maxKey+1

            // setAlertSeverity(undefined)
            try {
                let body: AddRefDataBody = {
                    key: `${newKey?.toString()}`,
                    translations: [
                        {
                            lang: 'FR',
                            label: data?.labelFR || ''
                        },
                        {
                            lang: 'EN',
                            label: data?.labelEN || ''
                        },
                        {
                            lang: 'NL',
                            label: data?.labelNL || ''
                        }
                    ]
                }
               
                    const resp = await addEditableRefData(body, clientId!,typeId!, token)
    
                    const rech = await rechargeList()
                setIsLoading(false)
                setShowForm(false)
                // setAlertSeverity('success')
                // setOpen(true)
                reset()
            }
            catch (e) {
                // setOpen(true)
                // setAlertSeverity('error')
                setIsLoading(false)
                console.warn(e)
            }
        
    }
    

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth:600 }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {t('main.products.dialog.refList')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
            {!showForm && !showDelete && <List sx={{ width: '100%', minWidth: 400, bgcolor: 'background.paper' }}>
                {refData?.map((value: RefDataInterface) => {
                    const labelId = `checkbox-list-label-${value.key}`;
                    if(value.active){
                    return (
                        <ListItem
                            key={value.key}
                            secondaryAction={
                                <IconButton edge="end" aria-label="comments" onClick={()=>{toggleDeleteAlert(value)}}>
                                    <DeleteIcon />
                                </IconButton>
                            }
                            disablePadding
                        >
                           {multipleSelect? <ListItemButton role={undefined} onClick={handleToggle(value)} dense>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={tmpSelectedItems?.map((e: any) => e.code).indexOf(value.key) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${getTranslation(value, clientLang)}`} />
                            </ListItemButton>:

                            <ListItemButton role={undefined} onClick={handleRadioSelect(value)} dense>
                                <ListItemIcon>
                                    <Radio
                                            checked={tmpSelectedItem?.code === value.key}
                                            value={selectedItem?.code}
                                            // onChange={handleRadioSelect(value)}
                                        />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${getTranslation(value, clientLang)}`} />
                            </ListItemButton>}
                        </ListItem>
                    );
                }})}
                <Divider />
                <div style={{display:'flex', justifyContent:'space-between', padding:8}}>
                    <Button startIcon={<AddIcon />} onClick={()=>setShowForm(true)}>
                        {t('main.products.dialog.addLabel')}
                    </Button>
                
                <Button onClick={updateValues}>
                       {t('general.save')}
                    </Button>
                    </div>
            </List> }
                {showForm && !showDelete && <form  >

                    <Card>
                        <CardHeader
                            // subheader={t('main.refData.form.addRefDataDesc')}
                            title={t('main.refData.form.addRefData')}
                        />
                        <CardContent sx={{ pt: 0, minWidth:600 }}>
                            <Box >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        xs={12}
                                    >
                                        <Controller
                                            control={control}
                                            name={`label${clientLang.toUpperCase()}`}
                                            defaultValue=""
                                            rules={{
                                                required: true,
                                                // minLength: 4,
                                                // maxLength: 20
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <TextField
                                                    {...field}
                                                    required
                                                    fullWidth
                                                    label={t(`main.refData.form.label${clientLang.toUpperCase()}`)}
                                                    type="text"
                                                    error={error !== undefined}
                                                    helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'space-between' }}>

                            <LoadingButton
                                loading={false}
                                variant="contained"
                                size="medium"
                                sx={{ mt: 5, mb: 2 }}
                                onClick={()=>setShowForm(false)}
                            >{t('general.cancel')}
                            </LoadingButton>
                            <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                size="medium"
                                sx={{ mt: 5, mb: 2 }}
                                onClick={handleUpdate}

                            >{t('general.save')}
                            </LoadingButton>
                        </CardActions>
                    </Card>

                </form>
            }
            {showDelete && <div style={{  minWidth:600 }}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', padding:8}}>
                <Typography>{t('main.products.dialog.confirmDeleteMessage')}</Typography>
                <Typography fontWeight={600}>{getTranslation(itemToDelete!, clientLang)}</Typography>
            </div>
            <Divider />
                <div style={{display:'flex', justifyContent:'space-between', padding:8}}>
                    <Button onClick={cancelDelete}>
                        {t('general.cancel')}
                    </Button>
                
                <Button onClick={handleDelete}>
                       {t('general.delete')}
                    </Button>
                    </div>
            </div>
            }
        </Dialog>
    );
}

export default ChangeableListDialog