import { baseDevUrl } from "../urls";

export interface AddDocumentBody {
    name: string;
    comment: string;
}


export const getDocument = async (clientId: number, documentId: number, token: string) => {

    const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/documents/${documentId}`, {

        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    })
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data
    // return response.ok
}

export const addDocument = async (body: AddDocumentBody, clientId: number, token: string,) => {

    const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/documents`, {

        method: "POST",
        body: JSON.stringify(body),
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    })
    if (!response.ok) {
        if (response.status === 409) {
            throw new Error(`DOCUMENT_NAME_ALREADY_USED`);
        } else {
            throw new Error('uknown');

        }
    }

    // const data = await response.json();
    // return data
    return response.ok
}

export const editDocument = async (body: AddDocumentBody, clientId: number, documentId: number, token: string,) => {

    const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/documents/${documentId}`, {

        method: "PUT",
        body: JSON.stringify(body),
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    })
    if (!response.ok) {
        if (response.status === 409) {
            throw new Error(`DOCUMENT_NAME_ALREADY_USED`);
        } else {
            throw new Error('uknown');

        }
    }

    // const data = await response.json();
    // return data
    return response.ok
}
