import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    impersonator: {
        token: '',
        user: {
            sub: '',
            auth: '',
            exp: 0,
            clientId: 0,
            clientName: '',
            clientLang: '',
            firstName: '',
            lastName: ''
        },
        roles: {
            rolesBySections: [],
            isPublisher: false
        }
    }
}


const impersonationReducer = createSlice({
    name: 'impersonation',
    initialState,
    reducers: {
        setImpersonator: (state, action) => {
            state.impersonator = action.payload
        },
        removeImpersonator: (state) => {
            state.impersonator = {
                token: '',
                user: {
                    sub: '',
                    auth: '',
                    exp: 0,
                    clientId: 0,
                    clientName: '',
                    clientLang: '',
                    firstName: '',
                    lastName: ''
                },
                roles: {
                    rolesBySections: [],
                    isPublisher: false
                }
            }
        }
    }
}
)

export const { setImpersonator, removeImpersonator } = impersonationReducer.actions

export default impersonationReducer.reducer
