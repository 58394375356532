import { SectionInterface, SectionRolesInterface } from "../../interfaces/sectionInterfaces";
import { SiteInterface } from "../../interfaces/siteInterfaces";
import { baseDevUrl } from "../urls";

export interface AddUserBody {
    login : string;
    firstName : string;
    lastName : string;
    email : string;
    isAdmin: boolean;
    isPublisher: boolean;
    activated?:boolean;
    sitesIds:SiteInterface[],
    rolesBySections:SectionRolesInterface[]
}


export const  getUser = async (clientId : number, userId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/users/${userId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}

export const  addUser = async (body : AddUserBody, clientId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/users`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
        if(response.status=== 400){
            let error = await response.json();
            throw new Error(`${error.key}`);
        }else{
            throw new Error(`HTTP error! status: ${response.status}`);

        }
	}
	// const data = await response.json();
    // return data
    return response.ok
}

export const  editUser = async (body : AddUserBody, clientId:number, userId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/users/${userId}`, {
     
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	// const data = await response.json();
    // return data
    return response.ok
}
