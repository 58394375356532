import { Box, Grid, ImageListItem, Tooltip } from '@mui/material'
import { getTranslation } from '../helpers/translations'
import useSearchByTypeId from '../hooks/useSearchByTypeId'
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces'

const ImageListSelect = (props: any) => {
    const { formState, setFormState, fieldName, optionsByTypeId, selectedLanguage, disabled } = props
    const itemData = useSearchByTypeId(optionsByTypeId)

    const handleSelection = (item: any) => {
        if (!disabled) {
            let tmpSelectedValues = formState[fieldName] ? [...formState[fieldName]] : []
            if (tmpSelectedValues.includes(item.key)) {
                tmpSelectedValues = tmpSelectedValues.filter(val => val !== item.key)
            } else {
                tmpSelectedValues.push(item.key)
            }
            setFormState((formState: any) => ({
                ...formState,
                [fieldName]: [...tmpSelectedValues]
            }));
        }
    }


    return (
        <Box>
            <Grid
                container
            >
                {itemData.map((item: CachedRefDataInterface) => (
                    <Tooltip key={item.key} title={getTranslation(item, selectedLanguage)} arrow >
                        <ImageListItem sx={{ cursor: 'pointer', width: 120, height: 120, paddingRight: 1, paddingBottom: 1 }} onClick={() => { handleSelection(item) }}>

                            <img
                                style={{ borderRadius: 12, opacity: formState[fieldName]?.includes(item.key) ? 1 : 0.3, objectFit:'contain' }}
                                src={`${item.imageUrl}`}
                                alt={getTranslation(item, selectedLanguage)}
                                loading="lazy"
                            />
                        </ImageListItem></Tooltip>
                ))}
            </Grid>
        </Box >

    )
}

export default ImageListSelect