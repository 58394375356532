import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Button, Card, CardActions, CardContent, Divider, ImageListItem, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClientInterface } from '../interfaces/clientInterfaces';

interface ClientInformationsCardPropsInterface {
    client?: ClientInterface
}

const ClientInformationsCard = (props: ClientInformationsCardPropsInterface) => {
    const { client } = props
    const { t } = useTranslation()
    const theme = useTheme()
    const navigate = useNavigate()
    const handleClick = () =>{
        navigate(`/clients/edit/${client?.id}`)
    }

    return (
        <Card sx={{minWidth: 240}}>
            <CardContent>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {client?.logoUrl?
                    <ImageListItem sx={{ width: 80, height: 50, marginRight: 1 }}>

                    <img
                      style={{ borderRadius: 12, width: 80, height: 50, objectFit: 'contain' }}
                      src={client.logoUrl}
                      alt={'logo'}
                      loading="lazy"
                    />
                  </ImageListItem> :<Avatar
                        //   src={user.avatar}
                        sx={{
                            height: 50,
                            mb: 2,
                            width: 50
                        }}
                    />}
                    <Typography
                        gutterBottom
                        variant="h5"
                    >
                        {client?.name}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        variant="body2"
                    >
                        {client?.externalId}
                    </Typography>
                </Box>
            </CardContent>
            <Divider />
            <CardActions>
                <Button
                variant="text"
                disableElevation
                fullWidth
                onClick={handleClick}
                endIcon={<EditIcon/>}
                sx={{
                    backgroundColor: 'transparent', color: 'grey', '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }}>
                    <Typography color="text.secondary" variant="subtitle2">{t('general.edit')}</Typography>
                </Button>
            </CardActions>
        </Card>
    )
}

export default ClientInformationsCard