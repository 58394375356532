import { Tooltip } from '@mui/material';
import * as React from 'react';

interface CustomExpandableCellInterface {
    value?: string
    charNumber?: number

}
const  CustomExpandableCell = (props: CustomExpandableCellInterface)=> {
    const { value='', charNumber=50} = props
    const [expanded, setExpanded] = React.useState(false);
 const children = <div className='MuiDataGrid-cellContent'>{value? value?.length<charNumber ? value : value?.slice(0, charNumber)+'...' : ''}&nbsp;</div>
    return (
      <Tooltip title={value} children={children} />
    );
  }

export default CustomExpandableCell