export const arraysHaveSameContent = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}

export const fuseArraysById = (arr1: any, arr2: any) => {

  const arr3 = arr1.map((t1: any) => ({ ...t1, ...arr2.find((t2: any) => t2.id === t1.id) }))

  arr3.sort((a: any, b: any) => (
    a.order - b.order
  ))
  return arr3;
}
