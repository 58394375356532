import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getSection } from '../api/client/sectionCrudApi';
import SectionForm from '../components/SectionForm';
import { RootState } from '../redux/store';

const NewSection = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, sectionId } = useParams()
  const [section, setSection] = useState()
  const getSectionData = async () => {
    try {
      if (sectionId && clientId) {
        const resp = await getSection(parseInt(clientId), parseInt(sectionId), token)
        setSection(resp)
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (clientId && sectionId) {
      getSectionData()
    }
  }, [clientId, sectionId])

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
              {!section ? t('main.section.form.addSection') : t('main.section.form.editSection')}
              </Typography>
              <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
            </div>
            <div>
              <Grid
                container
                spacing={3}
              >
                {/* <Grid
              xs={12}
              md={6}
              lg={4}
            >
              <AccountProfile />
            </Grid> */}
                <Grid
                  xs={12}
                  md={8}
                  lg={8}
                >
                  <SectionForm section={section} clientId={clientId? parseInt(clientId) : undefined} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    )
  }

  export default NewSection