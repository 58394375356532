import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { enUS, frFR, nlNL } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setDataGridTranslations, setSelectedLanguage } from '../redux/reducers/languageReducer';
import { RootState } from '../redux/store';




const countries = [
    {
      label: "FR",
      src: "/assets/flags/france.png",
      link: " ",
      value: "fr"
    },
    {
      label: "EN",
      src: "/assets/flags/uk.png",
      link: " ",
      value: "en"
    },
    {
      label: "NL",
      src: "/assets/flags/netherlands.png",
      link: " ",
      value: "nl"
    }
  ];

 const LanguageMenu = () => {
    const { i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const selectedLanguage = useSelector((state: RootState) => state.languageReducer.selectedLanguage)
    const dispatch = useDispatch()

  
    const handleChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
    dispatch(setSelectedLanguage(event.target.value));
    switch (event.target.value) {
      case 'fr':
        dispatch(setDataGridTranslations(frFR))
        break;
      case 'en':
        dispatch(setDataGridTranslations(enUS))
        break;
      case 'nl':
        dispatch(setDataGridTranslations(nlNL))
        break;
      default:
        break;
    }
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  return (
    // <React.Fragment>
    //     {/* <Tooltip title="Select Language">
    //       <IconButton
    //         onClick={handleClick}
    //         size="small"
    //         sx={{ ml: 2 }}
    //         aria-controls={open ? 'language-menu' : undefined}
    //         aria-haspopup="true"
    //         aria-expanded={open ? 'true' : undefined}
    //       >
    //         <Flag code='fr' height="60" />
    //       </IconButton>
    //     </Tooltip> */}
    //     <Button
    //     id="demo-customized-button"
    //     aria-controls={open ? 'language-menu' : undefined}
    //     aria-haspopup="true"
    //     aria-expanded={open ? 'true' : undefined}
    //     variant="contained"
    //     disableElevation
    //     onClick={handleClick}
    //     endIcon={<KeyboardArrowDownIcon color='primary' />}
    //     sx={{p:1, 
    //         boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px rgba(0, 0, 0, 0.06)',
    //         backdropFilter: "blur(4px)",
    //         backgroundColor: 'rgba(255,255,255,0.4)',}}
    //   >
    //     <Flag code='fr' height="16" />
    //   </Button>
    //   <Menu
    //     anchorEl={anchorEl}
    //     id="language-menu"
    //     open={open}
    //     onClose={handleClose}
    //     onClick={handleClose}
    //     PaperProps={{
    //       elevation: 0,
    //       sx: {
    //         overflow: 'visible',
    //         filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    //         mt: 1.5,
    //         '& .MuiAvatar-root': {
    //           width: 32,
    //           height: 32,
    //           ml: -0.5,
    //           mr: 1,
    //         },
    //         '&:before': {
    //           content: '""',
    //           display: 'block',
    //           position: 'absolute',
    //           top: 0,
    //           right: 14,
    //           width: 10,
    //           height: 10,
    //           bgcolor: 'background.paper',
    //           transform: 'translateY(-50%) rotate(45deg)',
    //           zIndex: 0,
    //         },
    //       },
    //     }}
    //     transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    //     anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    //   >
    //     <MenuItem onClick={handleClose}>
    //         <Flag code="fr" style={{margin: "0.2em 0.25em",
    // height: "0.9em",
    // alignItems: "justify",}} />
    //     </MenuItem>
    //     <MenuItem onClick={handleClose}>
    //         <Flag code="gb" style={{margin: "0.2em 0.25em",
    // height: "0.8em",
    // alignItems: "justify",}}/>
    //     </MenuItem>
    //     <MenuItem onClick={handleClose}>
    //     <Flag code="nl" style={{margin: "0.2em 0.25em",
    // height: "0.9em",
    // alignItems: "justify",}}  />
    //     </MenuItem>
    //   </Menu>
    // </React.Fragment>
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
        size='small'
        sx={{maxWidth:80, py:0, m:0,
            '& img': {
            maxWidth: '62%',
        }}}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          defaultValue={selectedLanguage}
          value={selectedLanguage}
          name="country"
          onChange={handleChange}
          inputProps={{
            id: "open-select"
          }}
        >
          {countries.map((option, key) => (
            <MenuItem sx={{maxWidth:80,px:0,
                '& img': {
                maxWidth: '30%',
            }}} value={option.value} key={key}>
              <img src={option.src} alt={option.label} />
            </MenuItem>
          ))}
        </Select>
        </FormControl>
  );
}

export default LanguageMenu