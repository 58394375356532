import { Box, Button, Switch, TextField, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DynamicFieldData } from '../interfaces/dynamicFormInterfaces';
import { RootState } from '../redux/store';
import ImageListDisplay from './ImageListDisplay';
import ImageListSelect from './ImageListSelect';
import MultiSelectRefData from './MultiSelectRefData';
import Phrases from './Phrases';
import ProductTable from './ProductTable';
import SelectRefData from './SelectRefData';
import TextTable from './TextTable';
import UserCustomField from './UserCustomField';


const DynamicField = (props: DynamicFieldData | any) => {
    const {role,auth, inputType, fieldName, defaultValue, rules = {}, disabled = false, control, label, formState, setFormState, errors, tableHeader, value, setValue, optionsByTypeId, formStateField = false, multipleSelect=true } = props
    const { t } = useTranslation()
    const selectedLanguage = useSelector((state: RootState) => state.languageReducer.selectedLanguage);
    const clientLang = useSelector((state: RootState) => state.authReducer.user.clientLang);
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    const disabledByRole = role === 'USER' || auth.includes('ROLE_SUPER_ADMIN')

//     const exp1={'.MuiFilledInput-root':{
//         borderColor:'gold',
//         borderStyle: 'solid',
//           borderWidth: 1,
       
//     },
//     '.MuiFilledInput-root.Mui-focused':{
//         borderColor: theme.palette.secondary.main,
//         boxShadow: `${theme.palette.secondary.main} 0 0 0 1px`
//     }
// }
const exp1={'.MuiFilledInput-root':{
    backgroundColor:'gold'
},
'.MuiFilledInput-root.Mui-focused':{
   backgroundColor:'transparent'
}
}

    useEffect(() => {
        if (inputType === 'multiSelect') {
            setFormState((formState: any) => ({
                ...formState,
                [fieldName]: value
            }));
        }
        if (inputType === 'imageListSelect') {
            setFormState((formState: any) => ({
                ...formState,
                [fieldName]: value
            }));
        }
        if (inputType === 'phrases') {
            setFormState((formState: any) => ({
                ...formState,
                [fieldName]: value
            }));
        }
        if (inputType === 'userCustomField') {
            if(multipleSelect){
            setFormState((formState: any) => ({
                ...formState,
                [fieldName]: value? value : []
            }));
            }else{
                setFormState((formState: any) => ({
                    ...formState,
                    [fieldName]: value? value : {}
                }));
            }
        }
    }, [value])

    
    

    useEffect(() => {
        if (inputType === 'text' || inputType === 'select' ) {
            setValue(fieldName, value)
        }
    }, [value])


    const handleFieldChange = (event: any) => {
        // event.persist();
        setFormState((formState: any) => ({
            ...formState,
            [event.target.name]:
                event.target.type === "checkbox"
                    ? event.target.checked
                    : event.target.value
        }));
    };

    switch (inputType) {
        case "text":
            return (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                        // sx={fieldName==='exp1'?exp1:null}
                            {...field}
                            multiline
                            disabled={disabled || disabledByRole}
                            required={rules.required ? rules.required : false}
                            fullWidth
                            label={label[selectedLanguage] || label[clientLang]}
                            type="text"
                            error={error !== undefined}
                            helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                        />
                    )}
                />
            );
        case "select": {
            return (
                <SelectRefData control={control} value={value} defaultValue={defaultValue} fieldName={fieldName} rules={rules} selectedLanguage={selectedLanguage} clientLang={clientLang} errors={errors} label={label} optionsByTypeId={optionsByTypeId} disabled={disabled || disabledByRole} />
            );
        }
        case "multiSelect": {
            return (
                <MultiSelectRefData control={control} value={value} fieldName={fieldName} rules={rules} selectedLanguage={selectedLanguage} errors={errors} clientLang={clientLang} label={label} optionsByTypeId={optionsByTypeId} formState={formState} handleFieldChange={handleFieldChange} disabled={disabled || disabledByRole} />
            );
        }
        case "number":
            return (
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required={rules.required ? rules.required : false}
                            fullWidth
                            label={label[selectedLanguage] || label[clientLang]}
                            type="number"
                            error={error !== undefined}
                            helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                        />
                    )}
                />
            );
        case "checkbox":
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                        {label[selectedLanguage] || label[clientLang]}
                    </Typography>
                    <Controller
                        control={control}
                        name={fieldName}
                        defaultValue={false}
                        render={({ field, fieldState: { error } }) => (
                            <Switch {...field}
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value}
                            />

                        )}
                    />
                </Box>
            );
        case "separator":
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.contrastText, borderRadius: 12 }}>
                    <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        {label[selectedLanguage] || label[clientLang]}
                    </Typography>

                </Box>
            );
        case "table":
            return (
                <>
                    {/* <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] + ' :'}
                    </Typography> */}
                    <ProductTable headers={tableHeader} rows={value} />
                </>
            );
        case "imageList":
            return (
                <>
                    <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] || label[clientLang]  + ' :'}
                    </Typography>
                    <ImageListDisplay values={value} selectedLanguage={selectedLanguage} />
                </>
            );
        case "imageListSelect":
            return (
                <>
                    <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] || label[clientLang] + ' :'}
                    </Typography>
                    <ImageListSelect disabled={disabled || disabledByRole} selectedLanguage={selectedLanguage} formState={formState} setFormState={setFormState} fieldName={fieldName} optionsByTypeId={optionsByTypeId} />
                </>
            );
        case "phrases":
            return (
                <>
                    <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] || label[clientLang] + ' :'}
                    </Typography>
                    <Phrases phrases={value} control={control} setValue={setValue} selectedLanguage={selectedLanguage} fieldName={fieldName} formState={formState} setFormState={setFormState} disabled={disabled || disabledByRole} />
                </>
            );
        case "userCustomField":
            return (
                <div style={{ display: 'flex', padding: 16, flexDirection: 'column', alignItems: 'flex-start', borderStyle: 'solid', borderWidth: 1, borderColor: theme.palette.secondary.main, borderRadius: 12 }}>
                <div style={{display:'flex', justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                    <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] || label[clientLang] + ' :'}
                    </Typography>
                    <Button sx={{ mr: 2, mb: 2 }} disabled={disabled || disabledByRole} variant='contained' onClick={()=>setOpen(true)}>{t('general.select')}</Button>
                    </div>
                    <UserCustomField fieldValue={value} control={control} setValue={setValue} selectedLanguage={selectedLanguage} fieldName={fieldName} formState={formState} setFormState={setFormState} disabled={disabled || disabledByRole} open={open} setOpen={setOpen} typeId={optionsByTypeId} multipleSelect={multipleSelect} />
                </div>
            );
        case "textTable":
            return (
                <div style={{ display: 'flex', padding: 16, flexDirection: 'column', alignItems: 'flex-start', borderStyle: 'solid', borderWidth: 1, borderColor: theme.palette.secondary.main, borderRadius: 12 }}>
                    <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
                        {label[selectedLanguage] || label[clientLang] + ' :'}
                    </Typography>
                    <TextTable values={value} selectedLanguage={selectedLanguage} />
                </div>
            );
        // case "changeableList":
        //     return (
        //         <div style={{ display: 'flex', padding: 16, flexDirection: 'column', alignItems: 'flex-start', borderStyle: 'solid', borderWidth: 1, borderColor: theme.palette.secondary.main, borderRadius: 12 }}>
        //             <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
        //             <Typography variant="subtitle2" sx={{ mr: 2, mb: 2 }}>
        //                 {label[selectedLanguage] || label[clientLang] + ' :'}
        //             </Typography>
        //             <Button onClick={()=>setOpen(true)}>Edit</Button>
        //             </div>
        //             <ChangeableListDisplay values={value} selectedLanguage={selectedLanguage} open={open} setOpen={setOpen} />
        //         </div>
        //     );
        default:
            return <input type="text" />;
    }
}

export default DynamicField