export const convertToBlob = async (imageUrl: string, token:string) => {
    const response = await fetch(imageUrl, {
      headers: {
        "Authorization": `Bearer ${token}`,
      }
    });
    //@ts-ignore
    const contentType = response.headers.get('Content-Type');
    const arrayBuffer = await response.arrayBuffer();
    const blob = new Blob([arrayBuffer], { type: contentType || '' });

    return { blob, contentType };
  };