import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  refData : []
}


const refDataReducer = createSlice({
  name: 'refData',
  initialState,
  reducers: {
    setRefData: (state, action) => {
      state.refData = action.payload
    },
    removeRefData: (state) => {
      state.refData = []
    }
  },
},
)

export const { setRefData, removeRefData } = refDataReducer.actions

export default refDataReducer.reducer
