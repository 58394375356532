import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockResetIcon from '@mui/icons-material/LockReset';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { handleResetInitRequest } from '../api/auth/resetPasswordApi';

const RequestResetPassword = () => {
  const { t } = useTranslation();
  const theme = useTheme()
  const [showMessage, setShowMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onBlur'
  })

  const handleResetPassword = async (data: FieldValues) => {
    setIsLoading(true)
    try{
      const body = {
        mail:data.email
      }
     const resp = await handleResetInitRequest(body)
     setShowMessage(true)
     setIsLoading(false)
    }
    catch(e){
      setShowMessage(false)
      setIsLoading(false)
      console.warn(e)
    }

  }

  return (
    <Box
      sx={{
        // backgroundColor: 'red',
        flex: '1 1 auto',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 550,
          // maxHeight:800,
          // mr:12,
          px: 3,
          py: '20px',
          mb: 3,
          width: '80%',
          boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px rgba(0, 0, 0, 0.06)',
          backdropFilter: "blur(4px)",
          backgroundColor: 'rgba(255,255,255,0.4)',

        }}
      >
        <div>
          <Stack
            spacing={1}
            sx={{ mb: 5 }}
          >
            <Typography variant="h4">
              ProData
            </Typography>

          </Stack>
          <form onSubmit={handleSubmit(handleResetPassword)} >
            <Stack spacing={3}>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                rules={{
                  required: true,
                  // pattern:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label={t('auth.form.email')}
                    type="text"
                    error={error !== undefined}
                    helperText={error ? t(`auth.errors.${error.type}`) : ''}
                  />
                )}
              />
            </Stack>
            {showMessage && <Alert
              color="success"
              icon={<CheckCircleIcon />}
              sx={{ mt: 1, backgroundColor: 'transparent' }}
            >
              <Typography
                color={theme.palette.success.main}
                // color="text.secondary"
                variant="body2"
              >
                {t('auth.form.requestSuccess')}
              </Typography>
            </Alert>}
            <LoadingButton
              loading={isLoading}
              fullWidth
              size="large"
              sx={{ mt: 5, mb: 2 }}
              type="submit"
              variant="contained"
              loadingPosition="start"
              startIcon={<LockResetIcon />}
            >
              {t('auth.buttons.resetPassword')}
            </LoadingButton>
          </form>
        </div>
      </Card>
      <Typography
        color="text.secondary"
        variant="body2"
      >
        Copyright © QuickFDS
      </Typography>
    </Box>
  )
}

export default RequestResetPassword