import React from 'react'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
const CustomToolbar = () => {
    const theme = useTheme()
  return (

          <GridToolbarContainer sx={{mb:2}}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            {/* <GridToolbarExport /> */}
          </GridToolbarContainer>
  )
}

export default CustomToolbar