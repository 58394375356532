import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getClientRefDataType } from '../api/client/clientRefDataApi';
import { getCommunRefDataType } from '../api/shared/communRefDataApi';
import RefDataTypeForm from '../components/RefDataTypeForm';
import { RootState } from '../redux/store';


const NewRefDataType = () => {
    const { t } = useTranslation()
    const token = useSelector((state: RootState) => state.authReducer.token);
    const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
    const { clientId, typeId } = useParams()
    const [type, setType] = useState()
    const [commun, setCommun] = useState(false)
    const {pathname} = useLocation()

    const getTypeData = async () => {
      try {
        if (typeId && clientId) {
          const resp = await getClientRefDataType(parseInt(clientId), parseInt(typeId), token)
          setType(resp)
        }
      }
      catch (e) {
        console.warn(e)
      }
    }

    const getCommunTypeData = async () => {
      try {
        if (typeId) {
          const resp = await getCommunRefDataType(parseInt(typeId), token)
          setType(resp)
        }
      }
      catch (e) {
        console.warn(e)
      }
    }
  
    useEffect(() => {
      if(pathname.includes('/commun/')){
        setCommun(true)
      }else{
        setCommun(false)
      }
    }, [pathname])

    useEffect(() => {
      if(commun){
        if (typeId  ) {
          getCommunTypeData()
        }
      }else{
      if (typeId && clientId ) {
        getTypeData()
      }
    }
    }, [clientId, typeId, commun])
  
      return (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={3}>
              <div>
                <Typography variant="h4">
                {!type ? t('main.refData.form.addType') : t('main.refData.form.editType')}
                </Typography>
                {!commun && <Typography variant="subtitle2" color='text.secondary'>
                  {t('general.selectedClient', { client: selectedClient.name })}
                </Typography>}
              </div>
              <div>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    xs={12}
                    md={8}
                    lg={8}
                  >
                    <RefDataTypeForm  clientId={clientId? parseInt(clientId) : undefined} type={type} commun={commun} />
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </Container>
        </Box>
      )
}

export default NewRefDataType