import { styled } from "@mui/material";
const SIDE_NAV_WIDTH = 280;

 
    // @ts-ignore 
const LayoutRoot = styled('div')(({ theme, user }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft:  user.auth.includes('ROLE_USER')? 0 : SIDE_NAV_WIDTH
    }
  }));

export default LayoutRoot