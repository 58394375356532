import PrintIcon from '@mui/icons-material/Print';
import { IconButton, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


export interface PdfVersionsDialogProps {
    open: boolean;
    selectedDoc: any;
    onClose: () => void;
}

const PdfVersionsDialog = (props: PdfVersionsDialogProps) => {
    const { onClose, selectedDoc, open } = props;
    const { t } = useTranslation()
    const theme = useTheme()

    const handleClose = () => {
        onClose();
    };
    const getDate = (date: string) => {
        return date?.slice(0, 10) || ''
    }
    const getTime = (date: string) => {
        return date?.slice(11, 19) || ''
    }


    return (
        <Dialog onClose={handleClose} open={open} >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'center' }}>{selectedDoc?.name + ' ' + t('main.products.product.version')}</DialogTitle>
            <List sx={{ pt: 0, minWidth: 400 }}>
                {selectedDoc?.lastVersions?.toReversed().map((doc: any) => (
                    <ListItem key={doc?.version} secondaryAction={
                        <IconButton edge="end" aria-label="print" href={doc?.url} target="_blank" rel="noopener noreferrer">
                            <PrintIcon />
                        </IconButton>
                    } >
                        <ListItemAvatar>
                            <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
                                <Typography>
                                    {doc.version}
                                </Typography>
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${t('main.products.product.published')} : ${getDate(doc.publishedAt)}, ${getTime(doc.publishedAt)}`} secondary={`${t('general.by')} : ${doc.publishedBy}`} />
                    </ListItem>
                ))}

            </List>
        </Dialog>
    );
}

export default PdfVersionsDialog