import { Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { getEditableRefData } from '../api/client/clientEditableRefDataApi'
import { CodeValue } from '../interfaces/dynamicFormInterfaces'
import { RefDataInterface } from '../interfaces/refDataInterfaces'
import { RootState } from '../redux/store'
import ChangeableListDialog from './ChangeableListDialog'

const UserCustomField = (props: any) => {
    const { control, fieldValue, setValue, selectedLanguage, fieldName, formState, setFormState, disabled=false, open, setOpen, typeId, multipleSelect } = props
    const [list, setlist] = useState<RefDataInterface[]>()
    const clientId = useSelector((state: RootState) => state.authReducer.user.clientId);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const [update, setUpdate] = useState(false)
    // let keysToSearch = items?.map((item: CodeValue) => item.code)
    // const searchResults = useSearchByKey(keysToSearch)


    // const getitem = (key: string) => {
    //     let refDataObj = searchResults.find((item: CachedRefDataInterface) => item.key === key)
    //     if (refDataObj) {
    //         const label = getTranslation(refDataObj, selectedLanguage)
    //         return label
    //     }
    // }

    const getRefList = async () => {
        try {
            const userRefData = await getEditableRefData(clientId!, typeId!, token)
            setlist(userRefData)
        }
        catch (e) {
          console.warn(e)
        }
      }

    useEffect(() => {
     getRefList()
    }, [])
    
    // set values for the first render
    useEffect(() => {
        if(multipleSelect){
            fieldValue?.map((item: CodeValue) => {
            if (item.label) {
                setValue(item.code, item.label)
            }
        })
    }else{
        if(fieldValue){
            setValue(fieldValue.code, fieldValue.label)
        }
    }
    }, [fieldValue])
    
    //update values when saving from the list
    useEffect(() => {
        if(update){
        if(multipleSelect){
            formState[fieldName]?.map((item: CodeValue) => {
            if (item.label) {
                setValue(item.code, item.label)
            }
        })
    }else{
        if(formState[fieldName]?.label){
            setValue(formState[fieldName]?.code, formState[fieldName]?.label)
        }
    }
        setUpdate(false)
    }
    }, [update])

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, item: CodeValue) => {

       if(multipleSelect){
         //Step 1: make a copy of the current state
        const tmpState = [...formState[fieldName]] || [];

        // Step 2: Find the reference to update
        const statementToUpdate = tmpState?.find(
            (statement: CodeValue) => statement.code === item.code
        );

        // Step 3: Modify the label of the reference
        statementToUpdate.label = event.target.value

        // Step 4: Update the state object with the modified reference
        setValue(item.code, event.target.value)
        setFormState((formState: any) => ({
            ...formState,
            [fieldName]: tmpState
        }));
    }else{
        setValue(item?.code, event.target.value)
        const tmpState={code:item?.code, label:event.target.value}
        setFormState((formState: any) => ({
            ...formState,
            [fieldName]: tmpState
        }));
    }
    }

    return (
        <>
       {open && <ChangeableListDialog refData={list} open={open} setOpen={setOpen} selectedItems={formState[fieldName]? formState[fieldName] : []} selectedItem={formState[fieldName]? formState[fieldName] : {}} setFormState={setFormState} fieldName={fieldName} typeId={typeId} rechargeList={getRefList} update={update} setUpdate={setUpdate} multipleSelect={multipleSelect} />}

        <Grid container spacing={3}>
            {
                multipleSelect? formState[fieldName]?.map((item: CodeValue) => (
                    <Grid
                        item
                        xs={12}
                        // md={(field.inputType === 'separator' || !field.display)? 12 : field.display}
                        md={12}
                        key={item.code}
                    >
                        <Controller
                            control={control}
                            name={item.code}
                            // rules={rules}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    // sx={fieldName==='exp1'?exp1:null}
                                    {...field}
                                    InputLabelProps={{ shrink: field?.value ? true : false }}
                                    multiline
                                    fullWidth
                                    disabled={disabled}
                                    hiddenLabel
                                    type="text"
                                    error={error !== undefined}
                                    onChange={(e) => { handleFieldChange(e, item) }}
                                // helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                                />
                            )}
                        />
                    </Grid>
                )):
                formState[fieldName]?.code && <Grid
                        item
                        xs={12}
                        // md={(field.inputType === 'separator' || !field.display)? 12 : field.display}
                        md={12}
                        key={formState[fieldName]?.code}
                    >
                        <Controller
                            control={control}
                            name={formState[fieldName]?.code}
                            // rules={rules}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    // sx={fieldName==='exp1'?exp1:null}
                                    {...field}
                                    InputLabelProps={{ shrink: field?.value ? true : false }}
                                    multiline
                                    fullWidth
                                    disabled={disabled}
                                    hiddenLabel
                                    type="text"
                                    error={error !== undefined}
                                    onChange={(e) => { handleFieldChange(e, formState[fieldName]) }}
                                // helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                                />
                            )}
                        />
                    </Grid>
                // <></>
            }
        </Grid>
        </>
    )
}

export default UserCustomField 

