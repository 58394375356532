import { MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { getTranslation } from '../helpers/translations'
import useSearchByTypeId from '../hooks/useSearchByTypeId'

const SelectRefData = (props:any) => {
    const {control, fieldName, rules, value, selectedLanguage, errors, label, optionsByTypeId, defaultValue, clientLang, disabled=false} = props
    const options = useSearchByTypeId(optionsByTypeId)
    


  return (
    <Controller
    control={control}
    name={fieldName}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field, fieldState: { error } }) => (
        <TextField
            {...field}
            required={rules.required ? rules.required : false}
            fullWidth
            label={label[selectedLanguage] || label[clientLang]}
            select
            disabled={disabled}
            SelectProps={{ native: false }}
            error={error !== undefined}
            helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
        >
            {options.map((option: any) => (
                <MenuItem
                    key={option.key}
                    value={option.key}
                >
                    {getTranslation(option,selectedLanguage)}
                </MenuItem>
            ))}
        </TextField>
    )}
/>
  )
}

export default SelectRefData