import { LoadingButton } from '@mui/lab';
import { Alert, AlertColor, Box, CardActions, CardContent, Grid, Snackbar, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { updateProductSectionStatus } from '../api/client/productsApi';
import { DynamicFieldData } from '../interfaces/dynamicFormInterfaces';
import { SectionInterface, SectionRolesInterface } from '../interfaces/sectionInterfaces';
import { RootState } from '../redux/store';
import DynamicField from './DynamicField';

interface DynamicFormPropsInterface {
    fields: DynamicFieldData[]
    product: any
    tabToSelect: number
    currentTab: number
    // setTabToSelect:()=>void
    checkFieldsChange: (initialObj: any, newObj: any) => boolean
    getProductDetails: (id: number) => Promise<void>
    changeProduct: () => void
    productToSelect: { id: number, name: string } | null
    setTriggerReset: (val: boolean) => void
    triggerReset: boolean
    hasEffectRun: boolean
    setHasEffectRun: (val: boolean) => void
    sectionId: number
    sections: SectionInterface[]
}
const DynamicForm = (props: DynamicFormPropsInterface) => {
    const { fields, product, getProductDetails, tabToSelect, currentTab, checkFieldsChange, productToSelect, changeProduct, setTriggerReset, triggerReset, hasEffectRun, setHasEffectRun, sectionId , sections} = props
    const { t } = useTranslation()
    const theme = useTheme()
    const [formState, setFormState] = useState<any>({});
    const [initialObj, setInitialObj] = useState<any>({});
    const { control, handleSubmit, setValue, reset, getValues } = useForm({
        reValidateMode: 'onBlur'
    })
    const token = useSelector((state: RootState) => state.authReducer.token);
    const roles = useSelector((state: RootState) => state.authReducer.roles);
    const auth = useSelector((state: RootState) => state.authReducer.user.auth);
    const sectionRole: SectionRolesInterface = roles?.rolesBySections.find((role: SectionRolesInterface) => role.sectionId === sectionId) || { sectionId: 0, role: '' }
    const [mode, setMode] = useState('')
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
    const [open, setOpen] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const currentSection = sections?.find((section: SectionInterface)=> section?.id=== sectionId)
    
    useEffect(() => {
        //delay time until render has completed and formstate value changed for the first time
        const timer = setTimeout(() => {
            if (!hasEffectRun) {
                setHasEffectRun(true)
            }

        }, 1000);
        return () => clearTimeout(timer);
    }, [hasEffectRun]);

    useEffect(() => {
        if (hasEffectRun) {
            let values = getValues()
            const updatedValues = formatValuesObject(values)
            setInitialObj(updatedValues)
        }
    }, [hasEffectRun])
    // useEffect(() => {
    //     if (product) {
    //         let values = getValues()
    //         const updatedValues = formatValuesObject(values)
    //         setInitialObj(updatedValues)
    //     }
    // }, [product])

    useEffect(() => {
        if (tabToSelect !== currentTab && tabToSelect !== -1) {
            let values = getValues()
            const updatedValues = formatValuesObject(values)
            checkFieldsChange(initialObj, updatedValues)
        }
    }, [tabToSelect])

    useEffect(() => {
        if (productToSelect) {
            let values = getValues()
            const updatedValues = formatValuesObject(values)
            checkFieldsChange(initialObj, updatedValues)
        }
    }, [productToSelect])

    useEffect(() => {
        if (triggerReset) {
            reset()
            setTriggerReset(false)
        }
    }, [triggerReset])


    const formatValuesObject = (values: any) => {

        let updatedValues = {}
        fields?.map((field: DynamicFieldData) => {
            if (!field.quickfds && field.fieldName !== 'separator') {
                let objKey = field.fieldName
                if (field.formStateField) {
                    let objVal = formState[objKey]
                    Object.assign(updatedValues, { [objKey]: objVal });
                } else {
                    let objVal = values[objKey]
                    Object.assign(updatedValues, { [objKey]: objVal });
                }
            }
        })
        return updatedValues

    }
    const handleSave = async () => {
        if(currentSection?.autoValidated){
            setMode('AutoValidate')
            setAlertSeverity('warning')
            setOpen(true)
            return
        }
        setSaveIsLoading(true)
        setMode('Save')
        let values = getValues()
        const updatedValues = formatValuesObject(values)
        try {
            let body = {
                prodata: updatedValues,
                requestedAction: 'SAVE',
            }
            // const res = await updateProduct(body, token, product.id)
            const status = await updateProductSectionStatus(body, token, product.id, sectionId)
            const newProd = await getProductDetails(product.id)
            setAlertSeverity('success')
            setOpen(true)
            setSaveIsLoading(false)
            setHasEffectRun(false)
        }
        catch (e) {
            setAlertSeverity('error')
            setOpen(true)
            setSaveIsLoading(false)
            console.warn(e)
        }
    }

    const handleFormSubmit = async (data: any) => {
        if(currentSection?.autoValidated){
            setMode('AutoValidate')
            setAlertSeverity('warning')
            setOpen(true)
            return
        }
        setIsLoading(true)
        const updatedValues = formatValuesObject(data)
        const action = sectionRole?.role === 'VALIDATOR' ? 'VALIDATE' : 'PRE_VALIDATE'
        setMode(sectionRole?.role === 'VALIDATOR' ? 'Validate' : 'Pre_validate')
        try {
            let body = {
                prodata: updatedValues,
                requestedAction: action,
            }
            // const res = await updateProduct(body, token, product.id)
            const status = await updateProductSectionStatus(body, token, product.id, sectionId)
            const newProd = await getProductDetails(product.id)
            setAlertSeverity('success')
            setOpen(true)
            setIsLoading(false)
            setHasEffectRun(false)
        }
        catch (e) {
            setAlertSeverity('error')
            setOpen(true)
            setIsLoading(false)
            console.warn(e)
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string | undefined) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>

            <CardContent sx={{ pt: 2 }}>
                <Box sx={{ m: -1.5 }}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            fields.map((field, i) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={(field.inputType === 'separator' || !field.display) ? 12 : field.display}
                                    // md={ 12}
                                    key={i}
                                >
                                    <DynamicField  {...field} control={control} formState={formState} setFormState={setFormState} setValue={setValue} value={field.quickfds ? product?.data?.quickfds[field.fieldName] : product?.data?.prodata[field.fieldName]} setHasEffectRun={setHasEffectRun} role={sectionRole?.role} auth={auth} />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </CardContent>
            {/* <Divider /> */}
            <CardActions sx={{
                justifyContent: 'flex-end',
                margin: 0,
                top: 'auto',
                bottom: 10,
                left: 'auto',
                position: 'sticky',
                // Adding media query..
                '@media (min-width: 400px)': {
                    position: 'fixed',
                    right:'13%'

                },
            }}>

                {sectionRole?.role && sectionRole?.role !== 'USER' &&
                    <LoadingButton
                        loading={saveIsLoading}
                        disabled={isLoading || saveIsLoading}
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 2,backgroundColor: currentSection?.autoValidated? theme.palette.grey[400]: theme.palette.secondary.main, '&:hover': {
                            backgroundColor:currentSection?.autoValidated? theme.palette.grey[400]: theme.palette.secondary.main
                        },
                        '&:disabled':{
                            backgroundColor: theme.palette.grey[400]
                        }
                    }}
                        onClick={handleSave}
                    // type="submit"

                    >{t('general.save')}
                    </LoadingButton>}
                {sectionRole?.role && sectionRole?.role === 'CONTRIBUTOR' && <LoadingButton
                    loading={isLoading}
                    disabled={isLoading || saveIsLoading}
                    variant="contained"
                    size="large"
                    sx={{
                        mt: 5, mb: 2, backgroundColor: currentSection?.autoValidated? theme.palette.grey[400]:theme.palette.warning.main, '&:hover': {
                            backgroundColor:currentSection?.autoValidated? theme.palette.grey[400]: theme.palette.warning.main
                        },
                        '&:disabled':{
                            backgroundColor: theme.palette.grey[400]
                        }
                    }}
                    type='submit'
                >{t('general.pre_validate')}
                </LoadingButton>}
                {sectionRole?.role && sectionRole?.role === 'VALIDATOR' && <LoadingButton
                    loading={isLoading}
                    disabled={isLoading || saveIsLoading}
                    variant="contained"
                    size="large"
                    sx={{
                        mt: 5, mb: 2, backgroundColor: currentSection?.autoValidated? theme.palette.grey[400]:theme.palette.success.main, '&:hover': {
                            backgroundColor: currentSection?.autoValidated? theme.palette.grey[400]:theme.palette.success.main
                        },
                        '&:disabled':{
                            backgroundColor: theme.palette.grey[400]
                        }
                    }}
                    type='submit'

                >{t('general.validate')}
                </LoadingButton>}
            </CardActions>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
                    {t(`main.products.product.alertMessage.${alertSeverity}${mode}`)}
                </Alert>
            </Snackbar>
        </form>
    )
}

export default DynamicForm