import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertColor,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Unstable_Grid2 as Grid,
    ImageListItem,
    Snackbar,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddRefDataBody, addClientRefData, editClientRefData } from '../api/client/clientRefDataApi';
import { addCommunRefData, editCommunRefData } from '../api/shared/communRefDataApi';
import { getTranslation } from '../helpers/translations';
import { RefDataInterface } from '../interfaces/refDataInterfaces';
import { RootState } from '../redux/store';

export interface RefDataFormPros {
    refData?: RefDataInterface
    image?: File | undefined
    typeId?: number
    keyId?: number
    clientId?: number
    commun?: boolean
}
const RefDataForm = (props: RefDataFormPros) => {
    const { refData, image, typeId, keyId, clientId, commun = false } = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState('Create')
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
    const [open, setOpen] = useState(false);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const theme = useTheme()
    const { control, handleSubmit, setValue, reset } = useForm({
        reValidateMode: 'onBlur'
    })

    const [file, setFile] = useState<File>()
    const [imageUrl, setImageUrl] = useState('')
    const [errorType, setErrorType] = useState('')


    const getImageUrl = (imageFile: File) => {
        if (imageFile) {
            const reader = new FileReader();
            reader.readAsDataURL(imageFile);

            reader.addEventListener('load', () => {
                // Get the data URL string
                //@ts-ignore
                setImageUrl(reader.result)
            });
        }
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles?.length) {
            setFile(acceptedFiles[0])
            getImageUrl(acceptedFiles[0])
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive, } = useDropzone({
        maxFiles: 1,
        accept: {
            'image/*': []
        },
        onDrop
    })


    const removeFile = () => {
        setFile(undefined)
    }


    useEffect(() => {
        if (refData) {
            setValue('key', refData.key)
            setValue('labelFR', getTranslation(refData, 'fr', true) || '')
            setValue('labelEN', getTranslation(refData, 'en', true) || '')
            setValue('labelNL', getTranslation(refData, 'nl', true) || '')
            setFile(image)
            setMode('Edit')
            setImageUrl(refData.imageUrl)
        } else {
            setMode('Create')
        }

    }, [refData, image])



    const handleAddRefData = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body: AddRefDataBody = {
                key: data.key,
                translations: [
                    {
                        lang: 'FR',
                        label: data.labelFR
                    },
                    {
                        lang: 'EN',
                        label: data.labelEN
                    },
                    {
                        lang: 'NL',
                        label: data.labelNL
                    }
                ]
            }
            if (commun) {
                const resp = await addCommunRefData(body, file, typeId!, token)

            } else {
                const resp = await addClientRefData(body, file, clientId!, typeId!, token)

            }
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            setFile(undefined)
            reset()
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }

    const handleEditRefData = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body: AddRefDataBody = {
                key: data.key,
                translations: [
                    {
                        lang: 'FR',
                        label: data.labelFR
                    },
                    {
                        lang: 'EN',
                        label: data.labelEN
                    },
                    {
                        lang: 'NL',
                        label: data.labelNL
                    }
                ]
            }
            if (commun) {
                const resp = await editCommunRefData(body, file, typeId!, keyId!, token)
            } else {
                const resp = await editClientRefData(body, file, clientId!, typeId!, keyId!, token)
            }
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            //  navigate('/', {replace:true})
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }
    const handleFormSubmit = async (data: FieldValues) => {
        if (!refData) {
            handleAddRefData(data)
        } else {
            handleEditRefData(data)
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string | undefined) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} >
            <Card>
                <CardHeader
                    subheader={t('main.refData.form.addRefDataDesc')}
                // title="Profile"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="key"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        // minLength: 4,
                                        // maxLength: 20
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            label={t('main.refData.form.key')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelFR"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        // minLength: 4,
                                        // maxLength: 20
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            label={t('main.refData.form.labelFR')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelEN"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.labelEN')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="labelNL"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.labelNL')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Box display='flex' alignItems='center' marginLeft={2} >
                                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{t('dropzone.image')} </Typography>
                                    {/* <Typography color={'primary'} sx={{fontSize: 14,fontWeight: 500}}>*</Typography> */}
                                </Box>
                                <Box
                                    {...getRootProps({
                                        className: 'dropzone'
                                    })}
                                    sx={{
                                        mt: 1,
                                        width: '100%',
                                        minHeight: 150,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            opacity: [0.9, 0.8, 0.7],
                                        },
                                        borderStyle: 'dashed',
                                        borderColor: theme.palette.secondary.main,
                                        borderRadius: 1,
                                        padding: 2
                                    }}
                                >
                                    <input {...getInputProps()} />
                                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <CloudUploadOutlinedIcon color='secondary' sx={{ fontSize: 50 }} />
                                        <Typography textAlign='center' variant='body2'>{t('dropzone.drop')}</Typography>
                                    </Box>
                                </Box>
                                {file ?
                                    <Box sx={{ mt: 1 }}>
                                        <Typography variant='subtitle1' >{t('dropzone.uploaded') + ': '}</Typography>
                                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                                            <Typography noWrap={true} variant='body2' >{file.name}</Typography>
                                            <div onClick={removeFile} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <CancelIcon color='error' />
                                            </div>
                                        </Box>
                                        <ImageListItem sx={{ width: 240, height: 240, paddingRight: 1, paddingBottom: 1 }}>

                                            <img
                                                style={{ borderRadius: 12, width: 240, height: 240, objectFit: 'contain' }}
                                                src={imageUrl}
                                                alt={file.name}
                                                loading="lazy"
                                            />
                                        </ImageListItem>
                                    </Box> : <></>}

                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 2 }}
                        type="submit"

                    >{t('general.save')}
                    </LoadingButton>
                </CardActions>
            </Card>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
                    {t(`main.refData.alertMessage.${alertSeverity}${mode}${errorType}`)}
                </Alert>
            </Snackbar>
        </form>
    );
}

export default RefDataForm