import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSections } from '../api/client/sectionCrudApi';
import ProductsList from '../components/ProductsList';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { RootState } from '../redux/store';

const Products = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { clientId } = useParams()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const [sections, setSections] = useState<SectionInterface[]>([])

  useEffect(() => {
    getSectionsData()
  }, [])
  
  
  const getSectionsData = async() =>{
    try{
      let  sectionsData : SectionInterface[]
        if(clientId){
           sectionsData = await getSections(token, clientId!)
      }else{
        sectionsData = await getSections(token)
      }
      sectionsData.sort((a:SectionInterface,b:SectionInterface)=>(
        a.order!-b.order!
      ))
      setSections(sectionsData)
        
    }
    catch(e){
      console.warn(e)
    }
  }


  const handleEditProduct = () => {
    
    navigate(`/product/edit`)
    
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 4
      }}
    >
      <Container maxWidth={false}>
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <div>
              <Typography variant="h4">
              {t('main.products.list.title')}
              </Typography>
              {!user.auth.includes('ROLE_USER') && <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>}
            </div>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleEditProduct}
              >
                {t('general.search')}
              </Button>
            </div>
          </Stack>
          <ProductsList sections={sections} />

        </Stack>
      </Container>
    </Box>
  )
}

export default Products