import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getClientDetails } from '../api/client/clientCrudApi';
import { AddUserBody, addUser, editUser } from '../api/client/userCrudApi';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { SiteInterface } from '../interfaces/siteInterfaces';
import { RoleBySectionInterface, UserInterface } from '../interfaces/userInterfaces';
import { RootState } from '../redux/store';

export interface UserFormPros{
  user?: UserInterface
  clientId? : number
}

const roles = [
  {
    value: 'USER',
  },
  {
    value: 'CONTRIBUTOR',
  },
  {
    value: 'VALIDATOR',
  }
];
const DocumentRoles = [
  {
    value: 'USER',
  },
  {
    value: 'PUBLISHER',
  }
];

const UserForm = (props:UserFormPros) => {
  const {user, clientId} = props
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('Create')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor|undefined>()
  const [open, setOpen] = useState(false);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [sections, setSections] = useState<SectionInterface[]>([])
  const [sites, setSites] = useState<SiteInterface[]>([])
  const [formState, setFormState] = useState({
    userSites: []
  });
  const { control, handleSubmit, setValue, reset } = useForm({
    reValidateMode: 'onBlur'
  })
  const [errorType, setErrorType] = useState('')

useEffect(() => {
  if(user){
    setValue('login', user.login)
    setValue('firstName', user.firstName)
    setValue('lastName', user.lastName)
    setValue('email', user.email)
    setValue('isAdmin', user.isAdmin)
    setValue('isPublisher', user.isPublisher)
    // setSites(user.sites)
    let selectedSites=user.sites?.map((site:SiteInterface)=> site.id)
    setFormState(
      {
        //@ts-ignore
        userSites:selectedSites
      }
      )
    user.rolesBySections?.map((item : RoleBySectionInterface)=>(
      setValue(`roleBySection${item.sectionId}`, item.role)
      ))
    setMode('Edit')
  }else{
    setMode('Create')
  }

}, [user])

useEffect(() => {
  getClientDetailsData()
}, [])


const getClientDetailsData = async() =>{
  try{
    const clientData = await getClientDetails(clientId!, token)
    setSites(clientData.sites)
    setSections(clientData.productSections)
  }
  catch(e){
    console.warn(e)
  }
}

  const handleAddUser = async(data:FieldValues) =>{
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    let rolesBySections = sections?.map((section:SectionInterface)=>(
      {
        sectionId:section.id,
        role:data[`roleBySection${section.id}`]
      }))
    try {
      let body : AddUserBody = {
        login: data.login,
        firstName:data.firstName,
        lastName: data.lastName,
        email:data.email,
        isAdmin: data.isAdmin,
        isPublisher: data.isPublisher,
        sitesIds:formState.userSites,
        rolesBySections:rolesBySections,
        activated:true
      }
      const resp = await addUser(body, clientId!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      setFormState({
        userSites: []
      });
      reset()
    }
    catch (e : unknown) {
      if (e instanceof Error) {
        setErrorType(e.message)
      }else{
        setErrorType('')
      }

      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }

  const handleEditUser = async(data:FieldValues) =>{
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    let rolesBySections = sections?.map((section:SectionInterface)=>(
      {
        sectionId:section.id,
        role:data[`roleBySection${section.id}`]
      }))
    try {
      let body : AddUserBody = {
        login: data.login,
        firstName:data.firstName,
        lastName: data.lastName,
        email:data.email,
        isAdmin: data.isAdmin,
        isPublisher: data.isPublisher,
        sitesIds:formState.userSites,
        rolesBySections:rolesBySections,
        activated:true
      }
      const resp = await editUser(body,clientId!, user?.id!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      //  navigate('/', {replace:true})
    }
    catch (e) {
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }
  const handleFormSubmit = async (data: FieldValues) => {
    if(!user){
        handleAddUser(data)
    }else{
        handleEditUser(data)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string|undefined) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleFieldChange = (event:any) => {
    // event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value
    }));
  };


  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} >
      <Card>
        <CardHeader
          subheader={t('main.user.form.addUserDesc')}
        // title="Profile"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name="login"
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.user.form.login')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name="firstName"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.user.form.firstName')}
                      type='text'
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name="lastName"
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.user.form.lastName')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  rules={{
                    required: true,
                    pattern:/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.user.form.email')}
                      type='text'
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              {sections?.map((section : SectionInterface)=>(<Grid
                xs={12}
                md={4}
                key={section.id}
              >
                <Controller
                  control={control}
                  name={`roleBySection${section.id}`}
                  defaultValue={'USER'}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={`${t('main.user.form.selectRoleBySection')} ${section.name}`}
                      select
                      SelectProps={{ native: true }}
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    >
                    {roles.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {t(`main.user.role.${option.value.toLocaleLowerCase()}`)}
                      </option>
                    ))}
                    </TextField>
                  )}
                />
              </Grid>))}
              {/* <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name={'roleBySectionDocument'}
                  defaultValue={'User'}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={`${t('main.user.form.selectRoleBySection')} Document`}
                      select
                      SelectProps={{ native: true }}
                      error={error !== undefined}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    >
                    {DocumentRoles.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {t(`main.user.role.${option.value.toLocaleLowerCase()}`)}
                      </option>
                    ))}
                    </TextField>
                  )}
                />
              </Grid> */}
              <Grid
                xs={12}
                md={4}
              >
                <Box sx={{display:'flex', alignItems:'center',height:'100%'}}>
                <Typography variant="subtitle2" sx={{mr:2}}>
                   {t('main.user.form.isPublisher') + ' : ' }
              </Typography>
                <Controller
                  control={control}
                  name="isPublisher"
                  defaultValue={false}
                  render={({ field, fieldState: { error } }) => (
                    <Switch {...field}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                    
                  )}
                />
                </Box>
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Controller
                  control={control}
                  name={'userSites'}
                  defaultValue={sites.length>0? sites[0].name : " "}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.user.form.selectSites')}
                      select
                      SelectProps={{ multiple:true, 
                         }}
                      error={error !== undefined}
                      value={formState.userSites}
                      onChange={handleFieldChange}
                      helperText={error ? t(`main.user.errors.${error.type}`) : ''}
                    >
                    {sites?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Box sx={{display:'flex', alignItems:'center',height:'100%'}}>
                <Typography variant="subtitle2" sx={{mr:2}}>
                   {t('main.user.form.isAdmin') + ' : ' }
              </Typography>
                <Controller
                  control={control}
                  name="isAdmin"
                  defaultValue={false}
                  render={({ field, fieldState: { error } }) => (
                    <Switch {...field}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                    
                  )}
                />
                </Box>
              </Grid>
             

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            sx={{ mt: 5, mb: 2 }}
            type="submit"

          >{t('general.save')}
          </LoadingButton>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color:'white' }} variant='filled' >
          {t(`main.user.alertMessage.${alertSeverity}${mode}${errorType}`)}
        </Alert>
      </Snackbar>
    </form>
  );
};
export default UserForm