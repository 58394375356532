import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDocument } from '../api/client/documentCrudApi';
import DocumentForm from '../components/DocumentForm';
import { RootState } from '../redux/store';

const NewDocument = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, documentId } = useParams()
  const [document, setDocument] = useState()
  const getDocumentData = async () => {
    try {
      if (documentId && clientId) {
        const resp = await getDocument(parseInt(clientId), parseInt(documentId), token)
        setDocument(resp)
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (clientId && documentId) {
      getDocumentData()
    }
  }, [clientId, documentId])

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
              {!document ? t('main.document.form.addDocument') : t('main.document.form.editDocument')}
              </Typography>
              <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
            </div>
            <div>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  xs={12}
                  md={8}
                  lg={8}
                >
                  <DocumentForm document={document} clientId={clientId? parseInt(clientId) : undefined} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    )
  }

  export default NewDocument