import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getEditableRefDataByKey } from '../api/client/clientEditableRefDataApi';
import UserRefForm from '../components/UserRefForm';
import { RootState } from '../redux/store';


const NewUserRef = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedType = useSelector((state: RootState) => state.selectedItemsReducer.selectedType);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, typeId, keyId } = useParams()
  const [refData, setRefData] = useState()
  const {pathname} = useLocation()
  

  const getEditableRefDataDetails = async () => {
    try {
      if (typeId && clientId && keyId) {
        const resp = await getEditableRefDataByKey(parseInt(clientId), parseInt(typeId), parseInt(keyId), token)
        setRefData(resp)
      }
    }
    catch (e) {
      console.warn(e)
    }
  }



  useEffect(() => {

    if (typeId && clientId && keyId) {
      getEditableRefDataDetails()
    }
  
  }, [clientId, typeId, keyId])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              {!refData ? t('main.refData.form.addCustomRefData') : t('main.refData.form.editCustomRefData')}
            </Typography>
            <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
          </div>
          <div>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={8}
                lg={8}
              >
                <UserRefForm refData={refData} clientId={clientId ? parseInt(clientId) : undefined} typeId={typeId ? parseInt(typeId) : undefined} keyId={keyId ? parseInt(keyId) : undefined} />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default NewUserRef