import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedClient: {
    name:'',
    id:null
  },
  selectedType:{
    name:'',
    id:null
  }
}

const selectedItemsReducer = createSlice({
  name: 'selectedItems',
  initialState,
  reducers: {
    setSelectedClient: (state, action) => {
        state.selectedClient = action.payload
     },
    setSelectedType: (state, action) => {
        state.selectedType = action.payload
     }
    },
  },
)

export const { setSelectedClient, setSelectedType } = selectedItemsReducer.actions

export default selectedItemsReducer.reducer
