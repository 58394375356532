import { Autocomplete, Box, Card, Container, Divider, Unstable_Grid2 as Grid, MenuItem, Stack, Tab, Tabs, TextField, Typography, alpha, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getProduct, searchProducts } from '../api/client/productsApi';
import { getSections } from '../api/client/sectionCrudApi';
import DocumentViewer from '../components/DocumentViewer';
import DocumentsTabData from '../components/DocumentsTabData';
import DynamicForm from '../components/DynamicForm';
import ProductChangesDialog from '../components/ProductChangesDialog';
import TabData from '../components/TabData';
import { arraysHaveSameContent, fuseArraysById } from '../helpers/arrayHelpers';
import useDebounce from '../hooks/useDebounce';
import { useQueryState } from '../hooks/useQueryState';
import { ProductFormDescription } from '../interfaces/dynamicFormInterfaces';
import { ProductDocumentsInterface, ProductFileInterface } from '../interfaces/productInterfaces';
import { SectionInterface, SectionStatusInterface } from '../interfaces/sectionInterfaces';
import { RootState } from '../redux/store';

const Product = () => {
    const { t } = useTranslation()
    const token = useSelector((state: RootState) => state.authReducer.token);
    const template = useSelector((state: RootState) => state.productReducer.sections);
    //@ts-ignore
    const selectedLanguage = useSelector((state: RootState) => state.languageReducer.selectedLanguage);
    const [product, setProduct] = useState<ProductFileInterface>()
    const [productsList, setProductsList] = useState([])
    const [tabData, setTabData] = useState<SectionStatusInterface>()
    const [selectedProduct, setSelectedProduct] = useState<{ id: number, name: string } | null>({ id: -1, name: '' });
    const [inputValue, setInputValue] = useState('')
    const debouncedSearch = useDebounce(inputValue, 500)
    const { control, handleSubmit, setValue, reset } = useForm({
        reValidateMode: 'onBlur'
    })
    const [tab, setTab] = useState(-1);
    const [tabsNumber, setTabsNumber] = useState(template.length + 1);
    const { state } = useLocation();
    const [htmlString, setHtmlString] = useState('')
    const [showPopup, setShowPopup] = useState(false)
    const [tabToSelect, setTabToSelect] = useState(-1)
    const [sectionIdToSelect, setSectionIdToSelect] = useState(-1)
    const [productToSelect, setProductToSelect] = useState<{ id: number, name: string } | null>(null)
    const [triggerReset, setTriggerReset] = useState(false)
    const [hasEffectRun, setHasEffectRun] = useState(false);
    const [selectedSectionId, setSelectedSectionId] = useState(-1)
    const [selectedDoc, setSelectedDoc] = useState<ProductDocumentsInterface>()
    const [sections, setSections] = useState<SectionInterface[]>([])
    const [productsData, setProductsData] = useState<any[]>([])
    const theme = useTheme()
    const [file, setfile] = useState<File| undefined>()
    const auth = useSelector((state: RootState) => state.authReducer.user.auth);
    const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
    const [documentCode, setDocumentCode] = useState('')
    const [queryId, queryName, setQueryIdAndName] = useQueryState<string, string>("id", "name");

    // handle tab change :  setting temporary tab to select to check if there is no data loss before making the tab change
    const handleChange = async (newValue: number, sectionIdToSelect: number) => {
        //first render
        if (tab === tabsNumber - 1) {
            setTabToSelect(-1)
            setTab(newValue)
            setSelectedSectionId(sectionIdToSelect)
            handleTabData(sectionIdToSelect)
        } else {
            setTabToSelect(newValue)
            setSectionIdToSelect(sectionIdToSelect)
        }

        // setTab(newValue);
    };

    const changeTab = () => {
        setTab(tabToSelect)
        setSelectedSectionId(sectionIdToSelect)
        handleTabData(sectionIdToSelect)
        setTabToSelect(-1)
        setProductToSelect(null)
        setHasEffectRun(false)
    }

    const checkFieldsChange = (initialObj: any, newObj: any) => {
        if (tab !== tabsNumber - 1) {
            const keys = Object.keys(initialObj);
            for (const key of keys) {
                const value1 = initialObj[key];
                const value2 = newObj[key];
                // Check if both values are undefined or empty arrays
                if (
                    (value1 === undefined && value2 === undefined) ||
                    (Array.isArray(value1) && value1.length === 0 && Array.isArray(value2) && value2.length === 0)
                ) {
                    continue; // Values are considered the same, move to the next key
                }

                // Check if value1 was null and became an empty string, or vice versa
                if (value1 !== value2) {
                    if ((value1 === null && value2 === '') || (value2 === null && value1 === '')) {
                        continue; // Values are considered the same, move to the next key
                    }
                    // Check if both values are arrays with the same content
                    if (Array.isArray(value1) && Array.isArray(value2) && arraysHaveSameContent(value1, value2)) {
                        continue; // Arrays have the same content, move to the next key
                    }

                    setShowPopup(true)
                    return true; // Values are different
                }
            }
            setShowPopup(false)
            if(productToSelect){
                changeProduct()
            }
            if(tabToSelect!==-1){
                changeTab()
            }
            return false; // All values are the same


        }
        setShowPopup(false)
        if(productToSelect){
            changeProduct()
        }
        if(tabToSelect!==-1){
            changeTab()
        }
        return false;
    }


    const handleTabData = (id: number) => {
        let sectionStatus = product?.sections.find(section => section.id === id)
        setTabData(sectionStatus)
    }

    const getProductDetails = async (id: number) => {
        try {
            if (id !== -1) {
                // let clientId = undefined
                // if(auth.includes('ROLE_SUPER_ADMIN')){
                //      clientId = selectedClient.id
                // }
                // //@ts-ignore
                const productData = await getProduct(id, token)
                setProduct(productData)
                setDocumentCode(productData?.data?.quickfds?.document_code)
                if(selectedDoc){
                    let tmpSelectedDoc = productData.documents.filter((document:ProductDocumentsInterface)=> selectedDoc.name === document.name)
                    setSelectedDoc(tmpSelectedDoc[0])
                }else{
                    setSelectedDoc(productData?.documents[0])
                }
            }
        }
        catch (e) {
            console.warn(e)
        }
    }

    const getProductsList = async (debouncedSearch: string) => {
        try {
            if (debouncedSearch) {
                const body = {
                    name: debouncedSearch
                }
                const data = await searchProducts(body, token);
                setProductsList(data || []);
            }
        }
        catch (e) {
            console.warn(e)
        }

    };

    useEffect(() => {
        getSectionsDetails()

    }, [])
    
    useEffect(() => {
        if(sections){
        const fusedArray = fuseArraysById(template, sections)
        setProductsData([...fusedArray])
}
    }, [sections])

    

    const getSectionsDetails = async () => {
        try {
            let sectionsDetails: SectionInterface[]
            // let clientId = undefined
            //     if(auth.includes('ROLE_SUPER_ADMIN')){
            //          clientId = selectedClient.id
            //     }
            //     //@ts-ignore
            sectionsDetails = await getSections(token)
            sectionsDetails.sort((a,b)=>(
                a.order!-b.order!
            ))
            setSections(sectionsDetails)

        }
        catch (e) {
            console.warn(e)
        }
    }

    //Fetch Prodcut data when selecting product
    useEffect(() => {
        if (selectedProduct) {
            getProductDetails(selectedProduct.id)
        }
    }, [selectedProduct])

    useEffect(() => {
        if (selectedProduct && selectedProduct.id!==-1){
            if(queryId && queryName && selectedProduct.id !== parseInt(queryId)){
                setQueryIdAndName(selectedProduct.id.toString(), selectedProduct.name)
            }else if(!queryId && !queryName){
                setQueryIdAndName(selectedProduct.id.toString(), selectedProduct.name)
            }
        }
    }, [selectedProduct, queryId, queryName])

    //Setting selectedProdcut from the passed data in navigation when selecting a product from the product list => triggers fetching data
    // useEffect(() => {
    //     if (state && state.preSelectedProduct) {
    //         setSelectedProduct(state.preSelectedProduct)
    //     }
    // }, [state])
    useEffect(() => {
        if (queryId && queryName && selectedProduct?.id===-1) {
            setSelectedProduct({id:parseInt(queryId), name:queryName})
        }
    }, [queryId, queryName, selectedProduct])


    useEffect(() => {
        if (tab === -1 && product && productsData) {
            handleTabData(productsData[0]?.id)
            setSelectedSectionId(productsData[0]?.id)
            setTab(0)
        }
    }, [product, productsData])

    useEffect(() => {
        if (tab !== -1 && product && selectedSectionId) {
            handleTabData(selectedSectionId)
            // setSelectedSectionId(productsData[0].sectionId)
            // setTab(0)
        }
    }, [product])

    useEffect(() => {
        getProductsList(debouncedSearch);
    }, [debouncedSearch]);


    const handleProductChange = (newValue : { id: number, name: string } | null) =>{
        if(selectedProduct?.id === -1){
            setSelectedProduct(newValue)
            setHasEffectRun(false)
            setProductToSelect(null);
            setTabToSelect(-1)
    
        }else{
            setProductToSelect(newValue);
        }
        
        // setSelectedProduct(newValue);
    }
    const changeProduct = () =>{
        setSelectedProduct(productToSelect)
        setHasEffectRun(false)
        setProductToSelect(null);
        setTabToSelect(-1)
        
    }



    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                pt: 4,
                pb:14
            }}
        >
            <ProductChangesDialog open={showPopup} setOpen={setShowPopup} changeTab={changeTab} changeProduct={changeProduct} productToSelect={productToSelect} setTriggerReset={setTriggerReset} setTabToSelect={setTabToSelect} setProductToSelect={setProductToSelect} />
            <Container maxWidth="lg">
                <Stack spacing={3}>
                    <div>
                        <Typography variant="h4">
                            {t('main.products.product.title')}
                        </Typography>
                    </div>
                    <div>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={12}
                                lg={12}
                            >
                                <Card>
                                    <Grid
                                        xs={12}
                                    >
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Autocomplete
                                                filterOptions={(x) => x}
                                                value={selectedProduct}
                                                onChange={(event: any, newValue: { id: number, name: string } | null) => {
                                                    handleProductChange(newValue)
                                                }}
                                                inputValue={inputValue}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputValue(newInputValue);

                                                }}
                                                id="controllable-states-demo"
                                                options={productsList}
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <MenuItem {...props} key={option.id}>
                                                            {option.name}
                                                        </MenuItem>
                                                    );
                                                }}
                                                noOptionsText={t('main.products.product.noProducts')} 
                                                renderInput={(params) => <TextField {...params} fullWidth label={t('main.products.product.selectProduct')} />}
                                            />
                                        </Box>
                                    </Grid>
                                    <Divider />
                                    {tab !== -1 &&
                                        <>
                                            <Box sx={{ borderBottom: 2, borderColor: 'divider', backgroundColor:alpha(theme.palette.secondary.main, 0.15)  }}>
                                                <Tabs
                                                    value={tab}
                                                    // onChange={handleChange}
                                                    variant="scrollable"
                                                    scrollButtons
                                                    indicatorColor='secondary'
                                                    textColor='secondary'

                                                >
                                                    {sections?.map((section: SectionInterface, index: number) => (
                                                        <Tab key={index} onClick={() => handleChange(index, section.id)} label={section.name} sx={{ minWidProductFormDescriptionth: 150 }} />
                                                    ))}
                                                    <Tab label={t('general.documents')} onClick={() => handleChange((tabsNumber - 1), 0)} sx={{ minWidProductFormDescriptionth: 150 }} />


                                                </Tabs>
                                            </Box>
                                            {tab === tabsNumber - 1 ? <DocumentsTabData sectionsData={product?.sections!} sections={sections} selectedDoc={selectedDoc} documentCode={documentCode} /> :
                                                <TabData tabData={tabData!} documentCode={documentCode} />}
                                        </>
                                    }
                                    <Divider />
                                    {productsData?.map((productTemplate: ProductFormDescription, index: number) => (
                                        <div key={index}>
                                            {tab === index && <DynamicForm sectionId={productTemplate.id} fields={productTemplate.fields} product={product} getProductDetails={getProductDetails} tabToSelect={tabToSelect} currentTab={tab} checkFieldsChange={checkFieldsChange} productToSelect={productToSelect} changeProduct={changeProduct} triggerReset={triggerReset} setTriggerReset={setTriggerReset} hasEffectRun={hasEffectRun} setHasEffectRun={setHasEffectRun} sections={sections} />}
                                        </div>
                                    ))}
                                    {tab === tabsNumber - 1 && <DocumentViewer file={file} htmlString={htmlString} setHtmlString={setHtmlString} sections={product?.sections} selectedDoc={selectedDoc} setSelectedDoc={setSelectedDoc} documents={product?.documents} productId={product?.id} getProductDetails={getProductDetails} />}

                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Stack>
            </Container>
        </Box>
    )
}


export default Product