import { createSlice } from '@reduxjs/toolkit'

// export interface UserInfo {
//   sub: string
//   auth: string
//   exp: number
// }

const initialState = {
  token: '',
  user: {
    sub: '',
    auth: '',
    exp: 0,
    clientId: 0,
    clientName: '',
    clientLang: '',
    firstName:'',
    lastName:'',
    logoUrl:'' ,
    autoPublished:false,
    sitesNames:[]
  },
  roles:{
    rolesBySections: [],
    isPublisher:false
  }
}


const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    removeAuth: (state) => {
      state.token = ''
      state.user = {
        sub: '',
        auth: '',
        exp: 0,
        clientId: 0,
        clientName:'',
        clientLang: '',
        firstName:'',
        lastName:'',
        logoUrl:'' ,
        autoPublished:false,
        sitesNames:[]
      }
      state.roles ={
        rolesBySections: [],
        isPublisher:false
      }
    }
  }

}
)

export const { setToken, setUser, setRoles, removeAuth } = authReducer.actions

export default authReducer.reducer
