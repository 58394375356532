import { baseDevUrl } from "../urls";

export interface AddSectionBody {
    name : string;
    order: number,
    autoValidated: boolean
}


export const  getSection = async (clientId : number, sectionId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sections/${sectionId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    return data
    // return response.ok
}

export const  addSection = async (body : AddSectionBody, clientId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sections`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`SECTION_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
	// const data = await response.json();
    // return data
    return response.ok
}

export const  editSection = async (body : AddSectionBody, clientId:number, sectionId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sections/${sectionId}`, {
     
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`SECTION_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const  getSections = async (token:string, clientId?:string) => {
    //param client id
    let clientIdString = clientId? `?clientId=${clientId}` : ''

     let url = `${baseDevUrl}/api/sections${clientIdString}`

        const response = await fetch(url, {
        method: "GET",
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            
        },
    })
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data
        // return response.ok
    }