import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  Snackbar,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addSection, editSection } from '../api/client/sectionCrudApi';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { RootState } from '../redux/store';

export interface SectionFormPros{
  section?: SectionInterface
  clientId? : number
}

const SectionForm = (props:SectionFormPros) => {
  const {section, clientId} = props
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('Create')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor|undefined>()
  const [open, setOpen] = useState(false);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const { control, handleSubmit, setValue, reset } = useForm({
    reValidateMode: 'onBlur'
  })
  const [errorType, setErrorType] = useState('')


useEffect(() => {
  if(section){
    setValue('name', section.name)
    setValue('order', section.order)
    setValue('autoValidated', section.autoValidated)
    setMode('Edit')
  }else{
    setMode('Create')
  }

}, [section])

  const handleAddSection = async(data:FieldValues) =>{
    setIsLoading(true)
    setErrorType('')
    setAlertSeverity(undefined)
    try {
      let body = {
        name: data.name,
        order:parseInt(data.order),
        autoValidated: data.autoValidated
      }
      const resp = await addSection(body, clientId!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      reset()
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }

  const handleEditSection = async(data:FieldValues) =>{
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    try {
      let body = {
        name: data.name,
        order:parseInt(data.order),
        autoValidated: data.autoValidated
      }
      const resp = await editSection(body,clientId!, section?.id!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      //  navigate('/', {replace:true})
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }
  const handleFormSubmit = async (data: FieldValues) => {
    if(!section){
        handleAddSection(data)
    }else{
        handleEditSection(data)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string|undefined) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} >
      <Card>
        <CardHeader
          subheader={t('main.section.form.addSectionDesc')}
        // title="Profile"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.section.form.name')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.section.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="order"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.section.form.order')}
                      type='number'
                      error={error !== undefined}
                      helperText={error ? t(`main.section.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Box sx={{display:'flex', alignItems:'center'}}>
                <Typography variant="subtitle2" sx={{mr:2}}>
                   {t('main.section.form.autoValidation') + ' : ' }
              </Typography>
                <Controller
                  control={control}
                  name="autoValidated"
                  defaultValue={false}
                  render={({ field, fieldState: { error } }) => (
                    <Switch {...field}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                    
                  )}
                />
                </Box>
              </Grid>
             

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            sx={{ mt: 5, mb: 2 }}
            type="submit"

          >{t('general.save')}
          </LoadingButton>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color:'white' }} variant='filled' >
          {t(`main.section.alertMessage.${alertSeverity}${mode}${errorType}`)}
        </Alert>
      </Snackbar>
    </form>
  );
};
export default SectionForm