import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getClientRefDataByKey } from '../api/client/clientRefDataApi';
import { getCommunRefDataByKey } from '../api/shared/communRefDataApi';
import RefDataForm from '../components/RefDataForm';
import { convertToBlob } from '../helpers/imageToBlob';
import { RootState } from '../redux/store';


const NewRefData = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedType = useSelector((state: RootState) => state.selectedItemsReducer.selectedType);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, typeId, keyId } = useParams()
  const [refData, setRefData] = useState()
  const [image, setImage] = useState<File>()
  const [commun, setCommun] = useState(false)
  const {pathname} = useLocation()
  
  const getClientRefDataDetails = async () => {
    try {
      if (typeId && clientId && keyId) {
        const resp = await getClientRefDataByKey(parseInt(clientId), parseInt(typeId), parseInt(keyId), token)
        setRefData(resp)
        if (resp.imageUrl) {
          const {blob, contentType} = await convertToBlob(resp.imageUrl, token)
          const fileName = `${resp.key}` + (contentType ? `.${contentType.split('/')[1]}` : '')
          const image = new File([blob], fileName, { type: contentType || 'image/jpg' });
          setImage(image)
        }
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  const getCommunRefDataDetails = async () => {
    try {
      if (typeId  && keyId) {
        const resp = await getCommunRefDataByKey( parseInt(typeId), parseInt(keyId), token)
        setRefData(resp)
        if (resp.imageUrl) {
          const {blob, contentType} = await convertToBlob(resp.imageUrl, token)
          const fileName = `${resp.key}` + (contentType ? `.${contentType.split('/')[1]}` : '')
          const image = new File([blob], fileName, { type: contentType || 'image/jpg' });
          setImage(image)
        }
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if(pathname.includes('/commun/')){
      setCommun(true)
    }else{
      setCommun(false)
    }
  }, [pathname])


  useEffect(() => {
    if(commun){
      if (typeId && keyId) {
        getCommunRefDataDetails()
      }
    }else{
    if (typeId && clientId && keyId) {
      getClientRefDataDetails()
    }
  }
  }, [clientId, typeId, keyId,commun])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              {!refData ? t('main.refData.form.addRefData') : t('main.refData.form.editRefData')}
            </Typography>
            <Typography variant="subtitle2" color='text.secondary'>
              {commun?  t('general.selectedType',{type:selectedType.name}) : t('general.selectedClient',{client:selectedClient.name})+ ' / ' + t('general.selectedType',{type:selectedType.name})}
            </Typography>
          </div>
          <div>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={8}
                lg={8}
              >
                <RefDataForm refData={refData} image={image} clientId={clientId ? parseInt(clientId) : undefined} typeId={typeId ? parseInt(typeId) : undefined} keyId={keyId ? parseInt(keyId) : undefined} commun={commun}/>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default NewRefData