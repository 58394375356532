
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserRefList from '../components/UserRefList';
import { RootState } from '../redux/store';

const UserRefDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { clientId, typeId } = useParams()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedType = useSelector((state: RootState) => state.selectedItemsReducer.selectedType);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const {pathname} = useLocation()
  const [maxKey, setMaxKey] = useState('')


  const handleAddRefData = () => {
   
    navigate(`/client/${clientId}/userRef/${typeId}/keys/add`,{state:{key:maxKey}})
    
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false} >
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <div>
              <Typography variant="h4">
              {t('main.refData.form.customReferenceData')}
              </Typography>
              <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
            </div>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddRefData}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <UserRefList clientId={clientId!} typeId={typeId!} setMaxKey={setMaxKey} />

        </Stack>
      </Container>
    </Box>
  )
}

export default UserRefDetails