import { frFR } from '@mui/x-data-grid'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedLanguage: 'fr',
  dataGridTranslations : frFR
}

const languageReducer = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action) => {
        state.selectedLanguage = action.payload
     },
    setDataGridTranslations: (state, action) => {
      state.dataGridTranslations = action.payload
     }
    },
  },
)

export const { setSelectedLanguage, setDataGridTranslations } = languageReducer.actions

export default languageReducer.reducer
