import { Box, Typography } from '@mui/material';
import { getTranslation } from '../helpers/translations';
import useSearchByKey from '../hooks/useSearchByKey';
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces';


const TextTable = (props: any) => {
    const { values = [], selectedLanguage } = props
    const searchResults = useSearchByKey(values)
    
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems:'flex-start' }}>
            {searchResults?.map((value: CachedRefDataInterface) => (
                <div key={value.key} style={{display:'flex', flexDirection:'row', alignItems:'center', flexWrap:'wrap' }}>
                    <Typography variant="subtitle2" color='secondary' sx={{ mr: 2 }}>
                        {value?.key + ' :'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ mr: 2 }}>
                        {getTranslation(value, selectedLanguage)}
                    </Typography>
                </div>
            ))}

        </Box>
    )
}

export default TextTable