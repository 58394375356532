import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface CustomBooleanCellInterface {
    value: boolean
}
const CustomBooleanCell = (props : CustomBooleanCellInterface) => {
    const { value = false } = props
  return (
    <>
    {value? <CheckCircleIcon color='success' /> : <CheckCircleIcon color='disabled' />}
    </>
  )
}

export default CustomBooleanCell