import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getClient } from '../api/client/clientCrudApi';
import ClientForm from '../components/ClientForm';
import { convertToBlob } from '../helpers/imageToBlob';
import { RootState } from '../redux/store';

const NewClient = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams();
  const token = useSelector((state: RootState) => state.authReducer.token);
  const { clientId } = useParams()
  const [client, setClient] = useState()
  const [image, setImage] = useState<File>()


  const getClientData = async () => {
    try {
      if (clientId) {
        const resp = await getClient(parseInt(clientId), token)
        setClient(resp)
        if (resp.logoUrl) {
          
          const {blob, contentType} = await convertToBlob(resp.logoUrl, token)
          const fileName = `${resp.name}` + (contentType ? `.${contentType.split('/')[1]}` : '')
          const image = new File([blob], fileName, { type: contentType || 'image/jpg' });
          setImage(image)
        }
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (clientId) {
      getClientData()
    }
  }, [clientId])

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <div>
            <Typography variant="h4">
              {!client ? t('main.client.form.addClient') : t('main.client.form.editClient')}
            </Typography>
          </div>
          <div>
            <Grid
              container
              spacing={3}
            >
              {/* <Grid
              xs={12}
              md={6}
              lg={4}
            >
              <AccountProfile />
            </Grid> */}
              <Grid
                xs={12}
                md={8}
                lg={8}
              >
                <ClientForm client={client}  image={image} />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  )
}

export default NewClient