import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { alpha, styled } from '@mui/material/styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.secondary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface CustomActionsCellPropsInterface {
    showDelete?: boolean;
    showProducts?: boolean;
    impersonation?: boolean;
    handleEdit?: () => any
    handleDelete?: () => any
    handleShowProducts?: () => any
    handleImpersonation?: () => any
}

const CustomActionsCell = (props: CustomActionsCellPropsInterface) => {
    const { showDelete = false,impersonation = false, handleEdit = () => { }, handleDelete = () => { } , handleShowProducts = () => { }, showProducts = false, handleImpersonation  = () => { } } = props
    const { t } = useTranslation()
    const user = useSelector((state: RootState) => state.authReducer.user.auth);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                onClick={handleClick}
                sx={{
                    backgroundColor: 'transparent', color: 'grey', '&:hover': {
                        backgroundColor: 'transparent'
                    }
                }}
            >
                <MoreHorizIcon />
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit} disableRipple>
                    <EditIcon />
                    {t('general.edit')}
                </MenuItem>
                {showDelete && <MenuItem onClick={handleDelete} disableRipple>
                    <DeleteIcon />
                    {t('general.delete')}
                </MenuItem>
                }
                {showProducts && <MenuItem onClick={handleShowProducts} disableRipple>
                    <ArticleIcon />
                    {t('general.products')}
                </MenuItem>
                }
                {impersonation && user.includes('ROLE_SUPER_ADMIN') && <MenuItem onClick={handleImpersonation} disableRipple>
                    <SocialDistanceIcon />
                    {t('general.impersonate')}
                </MenuItem>
                }
            </StyledMenu>
        </div>
    );
}

export default CustomActionsCell