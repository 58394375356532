import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

const ProductChangesDialog = (props:any) => {
const {open, setOpen, changeTab, changeProduct, productToSelect, setTriggerReset, setTabToSelect, setProductToSelect} = props
const {t} = useTranslation()


  const handleClose = () => {
    setOpen(false);
    setTabToSelect(-1)
    setProductToSelect(null)
  };
  const handleAgree = () => {
    let timer:any
    if(productToSelect){
      setTriggerReset(true)
      timer = setTimeout(() => {
        changeProduct()
      }, 200);
      
      // changeProduct()
    }else{
      changeTab()
    }
    setOpen(false);
    return () => clearTimeout(timer);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('main.products.dialog.unsaved')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {t('main.products.dialog.unsavedMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('main.products.dialog.cancel')}</Button>
          <Button onClick={handleAgree} autoFocus>
          {t('main.products.dialog.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default ProductChangesDialog