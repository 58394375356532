import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  Snackbar,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addSite, editSite } from '../api/client/siteCrudApi';
import { SiteInterface } from '../interfaces/siteInterfaces';
import { RootState } from '../redux/store';


export interface SiteFormPros{
  site?: SiteInterface
  clientId? : number
}

const SiteForm = (props:SiteFormPros) => {
  const {site, clientId} = props
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('Create')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor|undefined>()
  const [open, setOpen] = useState(false);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const { control, handleSubmit, setValue, reset } = useForm({
    reValidateMode: 'onBlur'
  })
  const [errorType, setErrorType] = useState('')


useEffect(() => {
  if(site){
    setValue('name', site.name)
    setValue('ref', site.ref)
    setMode('Edit')
  }else{
    setMode('Create')
  }

}, [site])

  const handleAddSite = async(data:FieldValues) =>{
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    try {
      let body = {
        name: data.name,
        ref:data.ref
      }
      const resp = await addSite(body, clientId!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      reset()
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }

  const handleEditSite = async(data:FieldValues) =>{
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    try {
      let body = {
        name: data.name,
        ref:data.ref
      }
      const resp = await editSite(body,clientId!, site?.id!, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      //  navigate('/', {replace:true})
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setAlertSeverity('error')
      setOpen(true)
      setIsLoading(false)
      console.warn(e)
    }
  }
  const handleFormSubmit = async (data: FieldValues) => {
    if(!site){
        handleAddSite(data)
    }else{
        handleEditSite(data)
    }
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string|undefined) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} >
      <Card>
        <CardHeader
          subheader={t('main.site.form.addSiteDesc')}
        // title="Profile"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="ref"
                  defaultValue=""
                  rules={{
                    required: true,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.site.form.ref')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.site.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.site.form.name')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.site.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              {/* <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Last name"
                  name="lastName"
                //   onChange={handleChange}
                  required
                //   value={values.lastName}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                //   onChange={handleChange}
                  required
                //   value={values.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                //   onChange={handleChange}
                  type="number"
                //   value={values.phone}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  fullWidth
                  label="Country"
                  name="country"
                //   onChange={handleChange}
                  required
                //   value={values.country}
                />
              </Grid> */}

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>

          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            sx={{ mt: 5, mb: 2 }}
            type="submit"

          >{t('general.save')}
          </LoadingButton>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical:'bottom', horizontal:'center' }}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color:'white' }} variant='filled' >
          {t(`main.site.alertMessage.${alertSeverity}${mode}${errorType}`)}
        </Alert>
      </Snackbar>
    </form>
  );
};
export default SiteForm