
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Divider, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getClientDetails } from '../api/client/clientCrudApi';
import ClientInformationsCard from '../components/ClientInformationsCard';
import RefDataTypesList from '../components/RefDataTypesList';
import SectionsList from '../components/SectionsList';
import SitesList from '../components/SitesList';
import UserRefTypesList from '../components/UserRefTypesList';
import UsersList from '../components/UsersList';
import { ClientInterface } from '../interfaces/clientInterfaces';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { SiteInterface } from '../interfaces/siteInterfaces';
import { UserInterface } from '../interfaces/userInterfaces';
import { RootState } from '../redux/store';
import DocumentsList from '../components/DocumentsList';
import { DocumentInterface } from '../interfaces/documentInterface';

const ClientDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { clientId } = useParams()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [sections, setSections] = useState<SectionInterface[]>([])
  const [sites, setSites] = useState<SiteInterface[]>([])
  const [documents, setDocuments] = useState<DocumentInterface[]>([])
  const [users, setUsers] = useState<UserInterface[]>([])
  const [client, setClient] = useState<ClientInterface>()

  useEffect(() => {
    getClientDetailsData()
  }, [])
  
  
  const getClientDetailsData = async() =>{
    try{
        if(clientId){
      const clientData = await getClientDetails(parseInt(clientId!), token)
      let clientInfo= {
        id:clientData.id,
        name:clientData.name,
        externalId:clientData.externalId,
        lang:clientData.lang,
        autoPublished:clientData.autoPublished,
        logoUrl:clientData.logoUrl
      }
      setClient(clientInfo)
      setSites(clientData.sites)
      setSections(clientData.productSections)
      setUsers(clientData.users)
      setDocuments(clientData.documents)
        }
    }
    catch(e){
      console.warn(e)
    }
  }

  const handleAddSiteClick = () => {
    navigate(`/client/${clientId}/sites/add`)
  }
  const handleAddDocumentClick = () => {
    navigate(`/client/${clientId}/documents/add`)
  }
  const handleAddSectionClick = () => {
    navigate(`/client/${clientId}/sections/add`)
  }
  const handleAddUserClick = () => {
    navigate(`/client/${clientId}/users/add`)
  }
  const handleAddType = () => {
    navigate(`/client/${clientId}/referenceDataType/add`)
  }
  const handleAddUserRefType = () => {
    navigate(`/client/${clientId}/userRefType/add`)
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false} >
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <ClientInformationsCard client={client} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t('general.sites')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddSiteClick}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <SitesList sites={sites} clientId={clientId!} />
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t('general.documents')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddDocumentClick}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <DocumentsList documents={documents} clientId={clientId!} />
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {t('general.sections')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddSectionClick}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <SectionsList sections={sections} clientId={clientId!} />
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
              {t('general.users')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddUserClick}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <UsersList clientId={clientId!} users={users} sections={sections} />
          <Divider />
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
              {t('general.type')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddType}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <RefDataTypesList clientId={clientId!} />

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
              {t('main.refData.form.customType')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddUserRefType}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <UserRefTypesList clientId={clientId!} />

        </Stack>
      </Container>
    </Box>
  )
}

export default ClientDetails