import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridFilterModel, GridRenderCellParams, GridSortModel, GridValueGetterParams, getGridSingleSelectOperators, getGridStringOperators } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProducts, getTemplate } from '../api/client/productsApi';
import { ProductInterface } from '../interfaces/productInterfaces';
import { SectionInterface } from '../interfaces/sectionInterfaces';
import { setTemplate } from '../redux/reducers/productReducer';
import { RootState } from '../redux/store';
import CustomColumnMenu from '../utils/CustomColumnMenu';
import CustomToolbar from '../utils/CustomToolbar';
import CustomDocumentsCell from './CustomDocumentsCell';
import CustomExpandableCell from './CustomExpandableCell';
import CustomLinkCell from './CustomLinkCell';
import CustomPublishedDocumentCell from './CustomPublishedDocumentCell';
import CustomStatusCell from './CustomStatusCell';

interface ProductsListPropsInterface {
  sections: SectionInterface[]
}
interface SiteFilterOptions {
  label: string
  value:string
}

const ProductsList = (props: ProductsListPropsInterface) => {
  const { sections } = props
  const { t } = useTranslation()
  const { clientId } = useParams()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const [sitesOptions, setSitesOptions] = useState<SiteFilterOptions[]>()
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const [products, setProducts] = useState<ProductInterface[]>([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [sortModel, setSortModel] = useState<any>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>();


  const [totalCount, setTotalCount] = useState(0);


  useEffect(() => {
   let siteFilterOptions = user.sitesNames.map(site=>({label:site, value:site}))
   setSitesOptions(siteFilterOptions)
  }, [])
  


  useEffect(() => {
    fetchData();
  }, [paginationModel, sortModel, filterModel]);

  const fetchData = async () => {

    try {
      let clientIdTouse = user.clientId ? user.clientId : parseInt(clientId!)
      const params: any = {
        page: paginationModel.page,
        size: paginationModel.pageSize,
      };

      if (sortModel.length > 0) {
        params.sort = sortModel[0].field;
        params.direction = sortModel[0].sort;
      }
      if (filterModel && filterModel.items.length > 0 && filterModel.items[0].value) {
        const section_id = parseInt(filterModel.items[0].field);

        if (!isNaN(section_id)) {
          // check if selected column is a section
          params.section_id= section_id
          params.section_status= filterModel.items[0].value
        } else {
          // use the field name as the filter option 
          params[filterModel.items[0].field] = filterModel.items[0].value
        }
      }

      if (clientIdTouse) {
        const productsData = await getProducts(clientIdTouse, token, params)
        setProducts(productsData.data)
        setTotalCount(productsData.totalCount)
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchTemplateForAdmin = async () => {

    try {
      let clientIdTouse =  parseInt(clientId!)
      if (clientIdTouse) {
        const temp = await getTemplate(token)
     dispatch(setTemplate(temp.sections))
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleSortModelChange = (model: GridSortModel) => {
    if (model) {
      setSortModel(model)
    } else {
      setSortModel([])
    }
    ;
  };

  const handleFilterModelChange = (model : GridFilterModel) => {
    if (model) {
      setFilterModel(model);
    } else {
      setFilterModel(undefined);
    }
  };

  //Get the status of section
  const getStatusValue = (params: GridValueGetterParams) => {
    const statusIndex = params.row.sections.findIndex((section: SectionInterface) => section.id.toString() === params.field)
    //Getting the status & nesting template strings to return the final value to display
    let status = `${params.row.sections[statusIndex]?.status.toLowerCase()}`
    let stringToDisplay = `${status}`
    return `${t(stringToDisplay)}`
  }

  //Create the dynamic part of the users table by sorting through the sections and creating the columns
  const sectionsColumns: GridColDef[] = sections.map((section: SectionInterface) => (
    {
      field: `${section.id}`,
      headerName: section.name,
      editable: false,
      hideable:false,
      maxWidth: 80,
      flex: 1,
      headerAlign: 'center',
      align:'center',
      sortable: false,
      type:'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === 'is'),
      valueOptions:[
        {label:t('main.products.product.draft'),value:'DRAFT'},
        {label:t('main.products.product.validated'),value:'VALIDATED'},
        {label:t('main.products.product.pre_validated'),value:'PRE_VALIDATED'},
        {label:t('main.products.product.published'),value:'PUBLISHED'}
      ],
      valueGetter: getStatusValue,
      renderCell: (params: GridRenderCellParams) => (
        <CustomStatusCell value={params.value} />
      )
    }
  ))

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex:1,
    filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains')
  },
    // { field: 'quickFdsId', headerName: 'quickFdsId', width: 160 },
    {
      field: 'name',
      headerName: `${t('main.products.list.name')}`,
      editable: false,
      minWidth: 300,
      flex: 1,
      filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains'),
      renderCell: (params: GridRenderCellParams) => (
        <CustomExpandableCell value={params.row.name} charNumber={55} />
      )
    },
    {
      field: 'ref',
      headerName: `${t('main.products.list.ref')}`,
      editable: false,
      // minWidth: 200,
      flex: 1,
      filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains'),
      renderCell: (params: GridRenderCellParams) => (
        <CustomLinkCell link={`/product/edit?id=${params.row.id}&name=${params.row.name}`} value={params.row.ref} />
      )
    },
    {
      field: 'externalRef',
      headerName: `${t('main.products.list.externalRef')}`,
      editable: false,
      // minWidth: 200,
      flex: 1,
      filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains')
    },
    {
      field: 'supplier',
      headerName: `${t('main.products.list.supplier')}`,
      editable: false,
      minWidth: 250,
      flex: 1,
      filterOperators: getGridStringOperators().filter(operator => operator.value === 'contains'),
      renderCell: (params: GridRenderCellParams) => (
        <CustomExpandableCell value={params.row.supplier} charNumber={40} />
      )
    },
    {
      field: 'site',
      headerName: `${t('main.products.list.site')}`,
      //   headerName: `${t('main.user.form.email')}`,
      editable: false,
      // minWidth: 200,
      flex: 1,
      type:'singleSelect',
      filterOperators: getGridSingleSelectOperators().filter(operator => operator.value === 'is'),
      valueOptions:sitesOptions,
      renderCell: (params: GridRenderCellParams) => (
        <CustomExpandableCell value={params.row.site} charNumber={55} />
      )
    },

    ...sectionsColumns,
    {
      field: 'documents',
      headerName: `${t('main.products.list.documents')}`,
      editable: false,
      hideable:false,
      // minWidth: 250,
      flex: 1,
      filterable:false,
      sortable: false,
      disableColumnMenu:true,
      // valueGetter: getStatusValue,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        // <CustomDocumentsCell  />
        <CustomDocumentsCell documents={params.row.documents} />
      )
    },
    {
      field: 'published',
      headerName: `${t('main.products.list.published')}`,
      editable: false,
      hideable:false,
      // minWidth: 200,
      flex: 1,
      sortable: false,
      filterable:false,
      disableColumnMenu:true,
      headerAlign: 'center',
      align:'center',
      // valueGetter: getStatusValue,
      renderCell: (params: GridRenderCellParams) => (
        // <CustomPublishedDocumentCell    />
        <CustomPublishedDocumentCell  documents={params.row.documents}  />
      )
    }
  ];



  const handleEvent: GridEventListener<'cellClick'> = (
    params,  // GridCellParams<any>
    event,   // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    if(params.field !=='published' ){
      navigate(`/product/edit?id=${params.row.id}&name=${params.row.name}`)
    }
  }

  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <Box sx={{  width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            getRowHeight={() => 'auto'}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  siteName: false,
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={products}
            columns={columns}
            paginationModel={paginationModel}
            paginationMode="server"
            rowCount={totalCount}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20,50]}
            sortingMode="server"
            sortModel={sortModel}
            filterMode='server'
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            disableRowSelectionOnClick
            slots={{ columnMenu: CustomColumnMenu, toolbar: CustomToolbar }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
            }}
            onCellClick={handleEvent}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default ProductsList