import { Box, Card, CardContent } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getClientsList } from '../api/client/clientCrudApi'
import { ClientInterface } from '../interfaces/clientInterfaces'
import { setSelectedClient } from '../redux/reducers/selectedItemsReducer'
import { RootState } from '../redux/store'
import CustomColumnMenu from '../utils/CustomColumnMenu'
import CustomActionsCell from './CustomActionsCell'
import CustomBooleanCell from './CustomBooleanCell'
import CustomUrlCell from './CustomUrlCell'


const ClientsList = () => {
  const { t } = useTranslation()
  const [clients, setClients] = useState<ClientInterface[]>([])
  const token = useSelector((state: RootState) => state.authReducer.token);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    getClientsListData()

  }, [])

  const handleEdit = (clientId: number) => {
    navigate(`/clients/edit/${clientId}`)

  }
  const handleShowProducts = (clientId: number, clientName: string) => {
    dispatch(setSelectedClient({name:clientName, id:clientId}))
    navigate(`/client/${clientId}/products`)

  }
  const handleEvent: GridEventListener<'cellClick'> = (
    params,  // GridCellParams<any>
    event,   // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    if(params.field !== 'actions'){
    dispatch(setSelectedClient({name:params.row.name, id:params.row.id}))
    navigate(`/client/${params.id}`)
  }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1, type: 'number', headerAlign: 'left', align:'left' },
    { field: 'externalId', headerName: `${t('main.client.form.externalId')}`, flex: 2, },
    {
      field: 'name',
      headerName: `${t('main.client.form.name')}`,
      editable: false,
      minWidth: 200,
      flex: 2
    },
    {
      field: 'lang',
      headerName: `${t('main.client.form.lang')}`,
      editable: false,
      flex: 2
    },
    {
      field: 'autoPublished',
      headerName: `${t('main.client.form.autoPublished')}`,
      editable: false,
      flex: 2,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        <CustomBooleanCell value={params.value} />
      )
    },
    {
      field: 'logoUrl',
      headerName: `${t('main.refData.form.image')}`,
      editable: false,
      minWidth: 160,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        <CustomUrlCell value={params.value} logo={true} />
      )
    },
    {
      field: 'actions',
      headerName: `${t('general.actions')}`,
      editable: false,
      sortable:false,
      filterable:false,
      disableColumnMenu:true,
      flex: 1,
      maxWidth:160,
      renderCell: (params: GridRenderCellParams) => (
        <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} showProducts={true} handleShowProducts={()=>{handleShowProducts(params.row.id, params.row.name)}} />
      )
    }
  ];


  const getClientsListData = async () => {
    try {
      const resp = await getClientsList(token)
      setClients(resp)
    }
    catch (e) {
      console.warn(e)
    }
  }


  return (
    <Card>

      <CardContent sx={{ p: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={clients}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              },
            }}
            pageSizeOptions={[5, 10]}
            onCellClick={handleEvent}
            disableRowSelectionOnClick
            slots={{ columnMenu: CustomColumnMenu }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default ClientsList