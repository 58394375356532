import { Tooltip, alpha, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CustomStatusCellInterface {
    value: string
    fullText? : boolean
}
const CustomStatusCell = (props : CustomStatusCellInterface) => {
    const { value , fullText=false } = props
    const theme = useTheme()
    const {t} = useTranslation()
    let color
    let customStyle = fullText? {minWidth: 20 } : { width:15, height:15 }

    const cellStyle={
        alignItems: 'center',
        borderRadius: 12,
        cursor: 'default',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        // fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 2,
        fontWeight: 600,
        justifyContent: 'center',
        letterSpacing: 0.5,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        ...customStyle
        
    }
    let children = <div></div>
    switch (value) {
      case 'draft':
        color = theme.palette.grey[500]
        children = <div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:color}} ></span></div>
        break;
      case 'pre_validated':
        color = theme.palette.primary.main
        children= <div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:color}} ></span></div>
        break;
      case 'validated':
        color = theme.palette.warning.main
        children = <div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:color}} ></span></div>
        break;
      case 'published':
        color = theme.palette.success.main
        children = <div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:color}} ></span></div>
        break
      default:
        return null
    }
  return (
    <>
    {fullText? 
    (() => {
        switch (value) {
          case 'draft':
            color = theme.palette.grey[600]
            return<div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:alpha(color, 0.12)}} >{t(`main.products.product.${value.toLowerCase()}`)}</span></div>
          case 'pre_validated':
            color = theme.palette.primary.main
            return<div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:alpha(color, 0.12)}} >{t(`main.products.product.${value.toLowerCase()}`)}</span></div>
          case 'validated':
            color = theme.palette.warning.main
            return<div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:alpha(color, 0.12)}} >{t(`main.products.product.${value.toLowerCase()}`)}</span></div>
          case 'published':
            color = theme.palette.success.main
            return<div className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color, backgroundColor:alpha(color, 0.12)}} >{t(`main.products.product.${value.toLowerCase()}`)}</span></div>
          default:
            return null
        }
      })()
      :
       <Tooltip title={value.toUpperCase()} children={children} />
       }
    </>
  )
}

export default CustomStatusCell