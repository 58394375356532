import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
import PowerIcon from '@heroicons/react/24/solid/PowerIcon';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ImageListItem,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import { Theme, alpha, useTheme, } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeAuth, setRoles, setToken, setUser } from '../redux/reducers/authReducer';
import { removeImpersonator } from '../redux/reducers/impersonationReducer';
import { removeRefData } from '../redux/reducers/refDataReducer';
import { RootState } from '../redux/store';
import LanguageMenu from './LanguageMenu';
// import { usePopover } from 'src/hooks/use-popover';
// import { AccountPopover } from './account-popover';


interface TopNavProps {
  onNavOpen: () => void
}
const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;

const TopNav = (props: TopNavProps) => {
  const { onNavOpen } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const dispatch = useDispatch()
  const location = useLocation()
  const [showBackButton, setShowBackButton] = useState(false)
  const theme = useTheme()
  const user = useSelector((state: RootState) => state.authReducer.user);
  const impersonatorInfo = useSelector((state: RootState) => state.impersonationReducer.impersonator);


  //   const accountPopover = usePopover();
  const logout = async () => {
    dispatch(removeAuth())
    dispatch(removeRefData())
    dispatch(removeImpersonator())
    navigate('auth/login')
  }

  const goBack = () => {
    navigate(-1)
  }

  const stringAvatar = () => {
    return {
      sx: {
        bgcolor: theme.palette.primary.main,
        height: 37,
        width: 37
      },
      children: `${user?.firstName[0]}${user?.lastName[0]}`,
    };
  }

  const endImpersonation = () => {
    dispatch(setToken(impersonatorInfo.token))
    dispatch(setUser(impersonatorInfo.user))
    dispatch(setRoles(impersonatorInfo.roles))
    navigate('/', { replace: true })
    dispatch(removeImpersonator())
  }

  useEffect(() => {
    if (location.pathname !== '/') {
      setShowBackButton(true)
    } else {
      if (showBackButton) {
        setShowBackButton(false)
      }
    }

  }, [location])

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: user.auth.includes('ROLE_USER') ? `calc(100% - ${0}px)` : `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {(!lgUp || user.auth.includes('ROLE_USER')) && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <Bars3Icon />
                </SvgIcon>
              </IconButton>
            )}
            {showBackButton && (
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <KeyboardBackspaceIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={goBack}
              >
                {t('general.back')}
              </Button>
            )
            }
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {impersonatorInfo.token && <Tooltip title={t('general.endImpersonation')}>
              <IconButton onClick={endImpersonation}>
                <SvgIcon fontSize="medium">
                  <SettingsBackupRestoreIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>}
            <LanguageMenu />
            {user?.logoUrl? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 2, border: '1px solid', borderColor: theme.palette.grey[300], height: 45, px: 2 }}>
              <ImageListItem sx={{ width: 80, height: 40, marginRight: 1 }}>

                <img
                  style={{ borderRadius: 12, width: 80, height: 40, objectFit: 'contain' }}
                  src={user.logoUrl}
                  alt={'logo'}
                  loading="lazy"
                />
              </ImageListItem>

              <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
                <Avatar {...stringAvatar()} />
              </Tooltip>
            </Box> : 
            <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
            <Avatar {...stringAvatar()} />
          </Tooltip>
          }

            <Tooltip title={t('general.logout')}>
              <IconButton onClick={logout}>
                <SvgIcon fontSize="medium">
                  <PowerIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Box>
      {/* <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      /> */}
    </>
  );
};

export default TopNav
