import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertColor,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Unstable_Grid2 as Grid,
  ImageListItem,
  Snackbar,
  Switch,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { addClient, editClient } from '../api/client/clientCrudApi';
import { ClientInterface } from '../interfaces/clientInterfaces';
import { RootState } from '../redux/store';

export interface ClientFormPros {
  client?: ClientInterface
  image?: File | undefined
}
const languages = [
  {
    value: 'fr',
    label: 'FR'
  },
  {
    value: 'en',
    label: 'EN'
  },
  {
    value: 'nl',
    label: 'NL'
  }
];
const ClientForm = (props: ClientFormPros) => {
  const { client, image } = props
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [mode, setMode] = useState('Create')
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
  const [open, setOpen] = useState(false);
  const token = useSelector((state: RootState) => state.authReducer.token);
  const { control, handleSubmit, setValue, reset } = useForm({
    reValidateMode: 'onBlur'
  })
  const [file, setFile] = useState<File>()
  const [imageUrl, setImageUrl] = useState('')
  const theme = useTheme()
  const [errorType, setErrorType] = useState('')


  const getImageUrl = (imageFile: File) => {
    if (imageFile) {
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);

      reader.addEventListener('load', () => {
        // Get the data URL string
        //@ts-ignore
        setImageUrl(reader.result)
      });
    }
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.length) {
      setFile(acceptedFiles[0])
      getImageUrl(acceptedFiles[0])
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive, } = useDropzone({
    maxFiles: 1,
    accept: {
      'image/*': []
    },
    onDrop
  })


  const removeFile = () => {
    setFile(undefined)
  }

  useEffect(() => {
    if (client) {
      setValue('name', client.name)
      setValue('externalId', client.externalId)
      setValue('lang', client.lang)
      setValue('autoPublished', client.autoPublished)
      setFile(image)
      setMode('Edit')
      setImageUrl(client.logoUrl)
    } else {
      setMode('Create')
    }

  }, [client,image])


  const handleAddClient = async (data: FieldValues) => {
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    try {
      let body = {
        name: data.name,
        externalId: data.externalId,
        lang: data.lang,
        autoPublished:data.autoPublished
      }
      const resp = await addClient(body, file, token)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      reset()
      //  navigate('/', {replace:true})
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }
  const handleEditClient = async (data: FieldValues) => {
    setIsLoading(true)
    setAlertSeverity(undefined)
    setErrorType('')
    try {
      let body = {
        name: data.name,
        externalId: data.externalId,
        lang: data.lang,
        autoPublished:data.autoPublished
      }
      const resp = await editClient(body, file, token, client?.id!)
      setIsLoading(false)
      setAlertSeverity('success')
      setOpen(true)
      //  navigate('/', {replace:true})
    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setErrorType(e.message !=='uknown'? e.message : '')
      }else{
        setErrorType('')
      }
      setOpen(true)
      setAlertSeverity('error')
      setIsLoading(false)
      console.warn(e)
    }
  }

  const handleFormSubmit = async (data: FieldValues) => {
    if (!client) {
      handleAddClient(data)
    } else {
      handleEditClient(data)
    }
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string | undefined) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} >
      <Card>
        <CardHeader
          subheader={t('main.client.form.addClientDesc')}
        // title="Profile"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="name"
                  defaultValue=""
                  rules={{
                    required: true,
                    minLength: 4,
                    maxLength: 20
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.client.form.name')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.client.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name="externalId"
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.client.form.externalId')}
                      type="text"
                      error={error !== undefined}
                      helperText={error ? t(`main.client.errors.${error.type}`) : ''}
                    />
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Controller
                  control={control}
                  name={'lang'}
                  defaultValue={'fr'}
                  rules={{
                    required: true,
                  }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      required
                      fullWidth
                      label={t('main.client.form.lang')}
                      select
                      SelectProps={{ native: true }}
                      error={error !== undefined}
                      helperText={error ? t(`main.client.errors.${error.type}`) : ''}
                    >
                      {languages.map((option) => (
                        <option
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid
                xs={12}
                md={4}
              >
                <Box sx={{display:'flex', alignItems:'center',height:'100%'}}>
                <Typography variant="subtitle2" sx={{mr:2}}>
                   {t('main.client.form.autoPublished') + ' : ' }
              </Typography>
                <Controller
                  control={control}
                  name="autoPublished"
                  defaultValue={false}
                  render={({ field, fieldState: { error } }) => (
                    <Switch {...field}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                    
                  )}
                />
                </Box>
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <Box display='flex' alignItems='center' marginLeft={2} >
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>{t('dropzone.image')}</Typography>
                  {/* <Typography color={'primary'} sx={{fontSize: 14,fontWeight: 500}}>*</Typography> */}
                </Box>
                <Box
                  {...getRootProps({
                    className: 'dropzone'
                  })}
                  sx={{
                    mt: 1,
                    width: '100%',
                    minHeight: 150,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: 'transparent',
                      opacity: [0.9, 0.8, 0.7],
                    },
                    borderStyle: 'dashed',
                    borderColor: theme.palette.secondary.main,
                    borderRadius: 1,
                    padding: 2
                  }}
                >
                  <input {...getInputProps()} />
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CloudUploadOutlinedIcon color='secondary' sx={{ fontSize: 50 }} />
                    <Typography textAlign='center' variant='body2'>{t('dropzone.drop')}</Typography>
                  </Box>
                </Box>
                {file ?
                  <Box sx={{ mt: 1 }}>
                    <Typography variant='subtitle1' >{t('dropzone.uploaded') + ': '}</Typography>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Typography noWrap={true} variant='body2' >{file.name}</Typography>
                      <div onClick={removeFile} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CancelIcon color='error' />
                      </div>
                    </Box>
                    <ImageListItem sx={{ width: 80, height: 50, paddingRight: 1, paddingBottom: 1 }}>

                      <img
                        style={{ borderRadius: 12, width: 80, height: 50, objectFit: 'contain' }}
                        src={imageUrl}
                        alt={file.name}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </Box> : <></>}

              </Grid>

            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>

          <LoadingButton

            loading={isLoading}
            variant="contained"
            size="large"
            sx={{ mt: 5, mb: 2 }}
            type="submit"

          >{t('general.save')}
          </LoadingButton>
        </CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
          {t(`main.client.alertMessage.${alertSeverity}${mode}${errorType}`)}
        </Alert>
      </Snackbar>
    </form>
  );
};
export default ClientForm