
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import RefDataTypesList from '../components/RefDataTypesList';
import { RootState } from '../redux/store';

const CommunRefData = () => {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);


  const handleAddType = () => {
    navigate(`/commun/referenceDataType/add`)
  }
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false} >
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
              {t('general.type')}
              </Typography>
            </Stack>
            <div>
              <Button
                startIcon={(
                  <SvgIcon fontSize="small">
                    <PlusIcon />
                  </SvgIcon>
                )}
                variant="contained"
                onClick={handleAddType}
              >
                {t('general.add')}
              </Button>
            </div>
          </Stack>
          <RefDataTypesList commun={true} />

        </Stack>
      </Container>
    </Box>
  )
}

export default CommunRefData