import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './en.json';
import translationFR from './fr.json';
import translationNL from './nl.json';
import { store } from "../redux/store";

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  nl: {
    translation: translationNL
  }
};

export const init = () => {
  const state = store.getState()
   const lng = state.languageReducer.selectedLanguage? state.languageReducer.selectedLanguage : 'fr'
return(
 
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng,
    fallbackLng:'fr',
    keySeparator: ".",  // to support nested translations
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })
 )
}
  export default i18n;