import { stringify } from "querystring";
import { RefDataTranslationInterface, RefDataTypeTranslationInterface } from "../../interfaces/refDataInterfaces";
import { baseDevUrl } from "../urls";
import { AddRefDataBody, AddRefDataTypeBody } from "../client/clientRefDataApi";


export const  getCommunRefDataTypes = async ( token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.reference-data-types+json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    
    return data
    // return response.ok
}
export const  getCommunRefDataType = async ( typeId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    
    return data
    // return response.ok
}

export const  addCommunRefDataType = async (body : AddRefDataTypeBody, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`TYPE_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const  editCommunRefDataType = async (body : AddRefDataTypeBody, typeId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}`, {
     
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const addCommunRefData = async (body : AddRefDataBody, image:File | undefined, typeId:number, token:string,) => {
    const formData = new FormData();
    const data = JSON.stringify(body)
    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')
    if(image){
    formData.append("image", image)
}
const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}/keys`, {
     
    method: "POST",
    body: formData,     
    
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`KEY_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const editCommunRefData = async (body : AddRefDataBody, image:File | undefined, typeId:number,keyId:number, token:string,) => {
    const formData = new FormData();
    const data = JSON.stringify(body)
    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')
    if(image){
    formData.append("image", image)
}
const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}/keys/${keyId}`, {
     
    method: "PUT",
    body: formData,     
    
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const  getCommunRefDataByKey = async ( typeId:number, keyId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}/keys/${keyId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    
    return data
    // return response.ok
}
export const  getCommunRefData = async (typeId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/reference-data/${typeId}/keys`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    
    return data
    // return response.ok
}