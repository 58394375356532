import { baseDevUrl } from "../urls";

export interface ResetPasswordInitBody {
    mail : string;
}
export interface ResetPasswordFinishBody {
    key : string;
    newPassword : string;
}

export const  handleResetInitRequest = async (body : ResetPasswordInitBody) => {

	const response = await fetch(`${baseDevUrl}/api/account/reset-password/init`, {
     
    // Adding method type
    method: "POST",
     
    // Adding body or contents to send
    body: JSON.stringify(body),
     
    // Adding headers to the requestz
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"

    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const  handleResetFinishRequest = async (body : ResetPasswordFinishBody) => {

	const response = await fetch(`${baseDevUrl}/api/account/reset-password/finish`, {
     
    // Adding method type
    method: "POST",
     
    // Adding body or contents to send
    body: JSON.stringify(body),
     
    // Adding headers to the requestz
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"

    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	// const data = await response.json();
    return response.ok
}