import { useEffect, useState } from 'react';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces';

const useSearchByKey = (keys: string[]) => {
  const [results, setResults] = useState([]);
  const refData = useSelector((state: RootState) => state.refDataReducer.refData);

  useEffect(() => {
    const filteredResults = refData.filter(
      (item: CachedRefDataInterface) => keys?.includes(item.key)
    );

    setResults(prevResults => {
      // Only update the results if the filtered results have changed
      if (!arraysEqual(filteredResults, prevResults)) {
        return filteredResults;
      }
      return prevResults;
    });
  }, [keys, refData]);

  return results;
};

// Helper function to compare two arrays for equality
const arraysEqual = (a: any[], b: any[]) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export default useSearchByKey;
