import { Tooltip } from '@mui/material'
import { SiteInterface } from '../interfaces/siteInterfaces'

interface CustomSitesCellInterface {
    value: SiteInterface[]
}
const CustomSitesCell = (props:CustomSitesCellInterface) => {
    const {value} = props
    const sites = value.map((site:SiteInterface)=>site.name)
    const sitesString= [...sites].join(', ')
    const renderElement =
        <div className='MuiDataGrid-cellContent'>{sitesString}</div>
  return (
    <Tooltip title={sitesString} children={renderElement} />
  
  )
}

export default CustomSitesCell