import {
    Box,
    Unstable_Grid2 as Grid,
    Hidden,
    Typography,
    useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import LanguageMenu from '../components/LanguageMenu';

const AuthLayout = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                backgroundSize: '60%',
                backgroundColor:'#FDFDFD'
                // backgroundImage: `url(${"/assets/loginBackground.svg"})`
            }}
        >
            <Grid
                container
                sx={{
                    flex: '1 1 auto',
                }}
            >
<Hidden lgDown>
                <Grid
                    xs={0}
                    lg={8}
                    sx={{
                        alignItems: 'center',
                        // background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Box sx={{ p: 3, display:'flex',flexDirection:'column', justifyContent: 'center' }}>
                    <Typography variant="h1" color={'secondary'}>
                            {t('general.proData')}
                        </Typography>
                    <Typography variant="h6" color={'black'}>
                    {t('general.prodataDesc')}
                        </Typography>
                    </Box>
                </Grid>
                </Hidden>
                <Grid
                    xs={12}
                    lg={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                     <Box component={Grid} xs={0} lg={12}  display={{ xs: "none", lg:'flex'}} sx={{justifyContent:'flex-end', mt: 2}} >
                            
                            <LanguageMenu />
                        </Box>
                    <Grid
                        xs={12}
                        lg={0}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}

                    >
                        <Grid xs={2} lg={0}></Grid>
                        <Grid xs={8} lg={0} sx={{
                            alignItems: 'center',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            '& img': {
                                maxWidth: '40%',
                            }
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img
                                    alt=""
                                    src="/assets/logoQuickFds.svg"
                                />
                            </Box>
                        </Grid>
                        <Box component={Grid} xs={2} lg={0}  display={{ lg: "none"}}>         
                            <LanguageMenu />
                        </Box>
                    </Grid>
                    <Outlet />
                </Grid>
            </Grid>
        </Box>

    )
}

export default AuthLayout