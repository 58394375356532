import {combineReducers} from "redux";
import languageReducer from "./languageReducer";
import authReducer from "./authReducer";
import refDataReducer from "./refDataReducer";
import selectedItemsReducer from "./selectedItemsReducer";
import productReducer from "./productReducer";
import impersonationReducer from "./impersonationReducer";


const rootReducer = combineReducers({
    languageReducer,
    authReducer,
    refDataReducer,
    selectedItemsReducer,
    productReducer,
    impersonationReducer
})

export default rootReducer