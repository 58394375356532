import { useEffect, useState } from 'react';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces';

const useSearchByTypeId = (typeId:number) => {
  const [results, setResults] = useState([]);
  const refData = useSelector((state: RootState) => state.refDataReducer.refData);

  useEffect(() => {
    const filteredResults = refData.filter((item:CachedRefDataInterface) =>  item.typeId===typeId);
    setResults(filteredResults);
  }, []);

  return results;
};

export default useSearchByTypeId
