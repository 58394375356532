import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthLayout from './layout/AuthLayout';
import MainLayout from './layout/MainLayout';
import { RootState } from './redux/store';
import ClientDetails from './screens/ClientDetails';
import Clients from './screens/Clients';
import CommunRefData from './screens/CommunRefData';
import Login from './screens/Login';
import NewClient from './screens/NewClient';
import NewRefData from './screens/NewRefData';
import NewRefDataType from './screens/NewRefDataType';
import NewSection from './screens/NewSection';
import NewSite from './screens/NewSite';
import NewUser from './screens/NewUser';
import NewUserRef from './screens/NewUserRef';
import NewUserRefType from './screens/NewUserRefType';
import Product from './screens/Product';
import Products from './screens/Products';
import RefDataDetails from './screens/RefDataDetails';
import RequestResetPassword from './screens/RequestResetPassword';
import ResetPassword from './screens/ResetPassword';
import UserRefDetails from './screens/UserRefDetails';
import UsersListAdmin from './screens/UsersListAdmin';
import ProtectedRoute from './utils/ProtectedRoutes';
import NewDocument from './screens/NewDocument';



function App() {
    const user = useSelector((state: RootState) => state.authReducer.user);

    const routingByRole = () => {
        if (user.auth.includes('ROLE_SUPER_ADMIN')) {
            return (
                <>
                    <Route path='/' element={
                        <Clients />
                    } />
                    <Route path='clients/add' element={
                        <NewClient />
                    } />
                    <Route path='clients/edit/:clientId' element={
                        <NewClient />
                    } />
                    <Route path='client/:clientId/sites/add' element={
                        <NewSite />
                    } />
                    <Route path='client/:clientId/sites/edit/:siteId' element={
                        <NewSite />
                    } />
                    <Route path='client/:clientId/documents/add' element={
                        <NewDocument />
                    } />
                    <Route path='client/:clientId/documents/edit/:documentId' element={
                        <NewDocument />
                    } />
                    <Route path='client/:clientId/sections/add' element={
                        <NewSection />
                    } />
                    <Route path='client/:clientId/sections/edit/:sectionId' element={
                        <NewSection />
                    } />
                    <Route path='client/:clientId/users/add' element={
                        <NewUser />
                    } />
                    <Route path='client/:clientId/users/edit/:userId' element={
                        <NewUser />
                    } />
                    <Route path='client/:clientId' element={
                        <ClientDetails />
                    } />
                    <Route path='client/:clientId/referenceDataType/add' element={
                        <NewRefDataType />
                    } />
                    <Route path='client/:clientId/referenceDataType/edit/:typeId' element={
                        <NewRefDataType />
                    } />
                    <Route path='client/:clientId/referenceData/:typeId/list' element={
                        <RefDataDetails />
                    } />
                    <Route path='client/:clientId/referenceData/:typeId/keys/add' element={
                        <NewRefData />
                    } />
                    <Route path='client/:clientId/referenceData/:typeId/keys/edit/:keyId' element={
                        <NewRefData />
                    } />
                    <Route path='commun/referenceDataType/list' element={
                        <CommunRefData />
                    } />
                    <Route path='commun/referenceDataType/add' element={
                        <NewRefDataType />
                    } />
                    <Route path='commun/referenceDataType/edit/:typeId' element={
                        <NewRefDataType />
                    } />
                    <Route path='commun/referenceData/:typeId/list' element={
                        <RefDataDetails />
                    } />
                    <Route path='commun/referenceData/:typeId/keys/add' element={
                        <NewRefData />
                    } />
                    <Route path='commun/referenceData/:typeId/keys/edit/:keyId' element={
                        <NewRefData />
                    } />
                    <Route path='product/edit' element={
                        <Product />
                    } />
                    <Route path='client/:clientId/products' element={
                        <Products />
                    } />
                      <Route path='client/:clientId/userRefType/add' element={
                        <NewUserRefType />
                    } />
                    <Route path='client/:clientId/userRefType/edit/:typeId' element={
                        <NewUserRefType />
                    } />
                     <Route path='client/:clientId/userRef/:typeId/list' element={
                        <UserRefDetails />
                    } />
                    <Route path='client/:clientId/userRef/:typeId/keys/add' element={
                        <NewUserRef />
                    } />
                    <Route path='client/:clientId/userRef/:typeId/keys/edit/:keyId' element={
                        <NewUserRef />
                    } />
                </>
            )
        }
        else if(user.auth.includes('ROLE_USER')){
            return(
            <>
                    <Route path='/' element={
                        <Products />
                    } />
                    <Route path='product/edit' element={
                        <Product />
                    } />
            </>
            )
        // if(!user.auth){ return <></>}
        }else {
            return(
                <>
                    <Route path='/' element={
                        <Products />
                    } />
                    <Route path='product/edit' element={
                        <Product />
                    } />
                    <Route path='client/:clientId/users/add' element={
                        <NewUser />
                    } />
                    <Route path='client/:clientId/users/edit/:userId' element={
                        <NewUser />
                    } />
                    <Route path='/users' element={
                        <UsersListAdmin />
                    } />
                </>
            )
        }
    }

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path='/auth' element={<AuthLayout />}>
                    <Route path='login' element={<Login />} />
                    <Route path='requestResetPassword' element={<RequestResetPassword />} />
                    <Route path='resetPassword' element={<ResetPassword />} />
                </Route>
                <Route path='/' element={
                    <ProtectedRoute>
                        <MainLayout />
                    </ProtectedRoute>
                } >
                    {routingByRole()}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
