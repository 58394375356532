import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../redux/store";
import { removeAuth } from "../redux/reducers/authReducer";
import { removeRefData } from "../redux/reducers/refDataReducer";
const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const token = useSelector((state: RootState) => state.authReducer.token);
    const user = useSelector((state: RootState) => state.authReducer.user);
    const dispatch = useDispatch()

    const logOut = () => {
        dispatch(removeAuth())
        dispatch(removeRefData())

    }

    useEffect(() => {
        if (token) {
            const now = new Date().getTime() / 1000
            //@ts-ignore
            if (now > user.exp) {
                logOut()
            }
        }
    }, [token])

    return (
        <React.Fragment>
            {
                token ? children : <Navigate to="/auth/login" state={{ from: '' }} replace />
            }
        </React.Fragment>
    );

};
export default ProtectedRoute;