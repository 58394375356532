import { Box, Container, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUser } from '../api/client/userCrudApi';
import UserForm from '../components/UserForm';
import { RootState } from '../redux/store';

const NewUser = () => {
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const selectedClient = useSelector((state: RootState) => state.selectedItemsReducer.selectedClient);
  const { clientId, userId } = useParams()
  const [user, setUser] = useState()
  const getUserData = async () => {
    try {
      if (userId && clientId) {
        const resp = await getUser(parseInt(clientId), parseInt(userId), token)
        setUser(resp)
      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (clientId && userId) {
      getUserData()
    }
  }, [clientId, userId])

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
            <div>
              <Typography variant="h4">
              {!user ? t('main.user.form.addUser') : t('main.user.form.editUser')}
              </Typography>
              <Typography variant="subtitle2" color='text.secondary'>
              {t('general.selectedClient',{client:selectedClient.name})}
            </Typography>
            </div>
            <div>
              <Grid
                container
                spacing={3}
              >
                {/* <Grid
              xs={12}
              md={6}
              lg={4}
            >
              <AccountProfile />
            </Grid> */}
                <Grid
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <UserForm user={user} clientId={clientId? parseInt(clientId) : undefined} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    )
  }

  export default NewUser