import { Box, Card, CardContent } from '@mui/material';
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClientRefData } from '../api/client/clientRefDataApi';
import { getCommunRefData } from '../api/shared/communRefDataApi';
import { getTranslation } from '../helpers/translations';
import { RefDataInterface } from '../interfaces/refDataInterfaces';
import { RootState } from '../redux/store';
import CustomColumnMenu from '../utils/CustomColumnMenu';
import CustomActionsCell from './CustomActionsCell';
import CustomUrlCell from './CustomUrlCell';

interface ClientReferenceDataListPropsInterface {
  clientId?: string
  typeId?: string
  commun?: boolean
}
const ReferenceDataList = (props: ClientReferenceDataListPropsInterface) => {
  const { clientId, typeId, commun = false } = props
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const [refDataRows, setRefDataRows] = useState<RefDataInterface[]>([])
  const navigate = useNavigate()

  const getClientDetailsData = async () => {
    try {
      if (clientId) {
        const clientRefData = await getClientRefData(parseInt(clientId!), parseInt(typeId!), token)
        let tmpClientDataRef: any[] = []
        clientRefData.forEach((refData: RefDataInterface) => {
          tmpClientDataRef.push({
            id: refData.id,
            key: refData.key,
            imageUrl: refData.imageUrl,
            labelFR: getTranslation(refData, 'fr', true) || '',
            labelEN: getTranslation(refData, 'en', true)  || '',
            labelNL: getTranslation(refData, 'nl', true)  || '',
          });
        });
        setRefDataRows(tmpClientDataRef)

      }
    }
    catch (e) {
      console.warn(e)
    }
  }

  const getCommunDetailsData = async () => {
    try {
        const refData = await getCommunRefData(parseInt(typeId!), token)
        let tmpRefData: any[] = []
        refData.forEach((refData: RefDataInterface) => {
          tmpRefData.push({
            id: refData.id,
            key: refData.key,
            imageUrl: refData.imageUrl,
            labelFR: getTranslation(refData, 'fr', true) || '',
            labelEN: getTranslation(refData, 'en', true)  || '',
            labelNL: getTranslation(refData, 'nl', true)  || '',
          });
        });
        setRefDataRows(tmpRefData)

      
    }
    catch (e) {
      console.warn(e)
    }
  }

  useEffect(() => {
    if (commun) {
      getCommunDetailsData()
    } else {
      getClientDetailsData()
    }
  }, [commun])


  const handleEdit = (keyId: number) => {
    if (commun) {
      navigate(`/commun/referenceData/${typeId}/keys/edit/${keyId}`)

    } else {
      navigate(`/client/${clientId}/referenceData/${typeId}/keys/edit/${keyId}`)
    }
  }

  const handleEvent: GridEventListener<'cellClick'> = (
    params,  // GridCellParams<any>
    event,   // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    if(params.field !=='actions'){
    handleEdit(params.row.id)
  }
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', type: 'number', headerAlign: 'left', align:'left' },
    {
      field: 'key',
      headerName: `${t('main.refData.form.key')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'imageUrl',
      headerName: `${t('main.refData.form.image')}`,
      editable: false,
      minWidth: 160,
      renderCell: (params: GridRenderCellParams) => (
        <CustomUrlCell value={params.value} />
      )
    },
    {
      field: 'labelFR',
      headerName: `${t('main.refData.form.labelFR')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'labelEN',
      headerName: `${t('main.refData.form.labelEN')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'labelNL',
      headerName: `${t('main.refData.form.labelNL')}`,
      editable: false,
      flex: 2,
    },
    {
      field: 'actions',
      headerName: `${t('general.actions')}`,
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      maxWidth:160,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} />
      )
    }
  ];


  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={refDataRows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              }
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            onCellClick={handleEvent}
            slots={{ columnMenu: CustomColumnMenu }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default ReferenceDataList