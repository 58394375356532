import { baseDevUrl } from "../urls";

export interface AddClientBody {
    name : string;
    externalId:string;
    lang: string;
    autoPublished:boolean
}

export const  getClient = async (id : number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${id}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.client+json",
        "Access-Control-Allow-Origin": "*",
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}

export const  getClientsList = async (token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.client+json",
        "Access-Control-Allow-Origin": "*",
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}

export const  getClientDetails = async (id : number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${id}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.client-details+json",
        "Access-Control-Allow-Origin": "*",
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}


export const  addClient = async (body : AddClientBody,image:File | undefined, token:string) => {
    const formData = new FormData();
    const data = JSON.stringify(body)
    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')
    if(image){
    formData.append("image", image)
    }
	const response = await fetch(`${baseDevUrl}/api/admin/clients`, {
     
    method: "POST",
    body: formData,
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`CLIENT_NAME_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}


export const  editClient = async (body : AddClientBody, image:File | undefined, token:string, id:number) => {
    const formData = new FormData();
    const data = JSON.stringify(body)

    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')
    if(image){
    formData.append("image", image)
    }
	const response = await fetch(`${baseDevUrl}/api/admin/clients/${id}`, {
     
    method: "PUT",
    body: formData,
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`CLIENT_NAME_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}