import { Box, Grid, ImageListItem, Tooltip } from '@mui/material'
import { getTranslation } from '../helpers/translations'
import useSearchByKey from '../hooks/useSearchByKey'
import { CachedRefDataInterface } from '../interfaces/refDataInterfaces'

const ImageListDisplay = (props: any) => {
  const { values, selectedLanguage } = props
  const searchResults = useSearchByKey(values)


  return (
    <Box>
      <Grid
        container
      >
        {searchResults?.map((item: CachedRefDataInterface) => (
          <>
          {item.imageUrl!=null && <Tooltip key={item.key} title={getTranslation(item, selectedLanguage)} arrow >
            <ImageListItem sx={{ width: 120, height: 120, paddingRight: 1, paddingBottom: 1 }}>

              <img
                style={{ borderRadius: 12 }}
                src={`${item.imageUrl}`}
                alt={getTranslation(item, selectedLanguage)}
                loading="lazy"
              />
            </ImageListItem>
          </Tooltip>}
          </>
        ))}
      </Grid>
    </Box>
  )
}

export default ImageListDisplay