import { Box, Card, CardContent } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SectionInterface } from '../interfaces/sectionInterfaces'
import { RootState } from '../redux/store'
import CustomColumnMenu from '../utils/CustomColumnMenu'
import CustomActionsCell from './CustomActionsCell'
import CustomBooleanCell from './CustomBooleanCell'

interface SectionsListPropsInterface{
    sections:SectionInterface[]
    clientId:string
}
const SectionsList = (props : SectionsListPropsInterface) => {
    const {sections, clientId} = props
    const {t}= useTranslation()
    const token = useSelector((state: RootState) => state.authReducer.token);
    const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
    const navigate = useNavigate()



    const handleEdit = (sectionId: number) => {
      navigate(`/client/${clientId}/sections/edit/${sectionId}`)
  
    }
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 160, type: 'number', headerAlign: 'left', align:'left' },
        // { field: 'quickFdsId', headerName: 'quickFdsId', width: 160 },
        {
          field: 'name',
          headerName: `${t('main.section.form.name')}`,
          editable: false,
          minWidth:200,
          flex:2
        },
        {
          field: 'order',
          headerName: `${t('main.section.form.order')}`,
          editable: false,
          minWidth:160,
          flex:2
        },
        {
          field: 'autoValidated',
          headerName: `${t('main.section.form.autoValidation')}`,
          editable: false,
          minWidth:160,
          flex:2,
          headerAlign: 'center',
          align:'center',
          renderCell : (params : GridRenderCellParams) =>(
            <CustomBooleanCell value={params.value} />
          )
        },
        {
          field: 'actions',
          headerName: `${t('general.actions')}`,
          editable: false,
          flex: 1,
          maxWidth:160,
          sortable:false,
          filterable:false,
          disableColumnMenu:true,
          renderCell: (params: GridRenderCellParams) => (
            <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} />
          )
        }
      ];

      const handleEvent: GridEventListener<'cellClick'> = (
        params,  // GridCellParams<any>
        event,   // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
      ) => {
        if(params.field !=='actions'){
          handleEdit(params.row.id)
        }
      }
    


  return (
    <Card>
      
        <CardContent sx={{ p:1 }}>
    <Box sx={{ width: '100%' }}>
    <DataGrid
     componentsProps={{
        panel: {
            sx: {
                top: '-120px !important',
            },
        },
    }}
    localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
      autoHeight
      rows={sections}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        }
      }}
      pageSizeOptions={[5, 10]}
      disableRowSelectionOnClick
      onCellClick={handleEvent}
      slots={{ columnMenu: CustomColumnMenu }}
    />
  </Box>
  </CardContent>
  </Card>
  )
}

export default SectionsList