export const mergeSectionsData = (table1 : any[] , table2 : any[]) =>{
    const indexedTable2 = table2.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});
      
      const mergedData = table1.map((item1) => ({
        ...item1,
        ...indexedTable2[item1.id],
      }));
      mergedData.sort((a:any, b:any)=>(
         a.order-b.order
      ))

      return mergedData
}