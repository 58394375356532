import { Box, Card, CardContent } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams, GridValueGetterParams, enUS, frFR, nlNL } from '@mui/x-data-grid'
import jwt_decode from "jwt-decode"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { impersonate } from '../api/auth/loginApi'
import { getAllRefData } from '../api/client/clientRefDataApi'
import { getTemplate } from '../api/client/productsApi'
import { getAccountDetails } from '../api/shared/accountDetailsApi'
import { CachedRefDataInterface, RefDataInterface } from '../interfaces/refDataInterfaces'
import { SectionInterface } from '../interfaces/sectionInterfaces'
import { RoleBySectionInterface, UserInterface } from '../interfaces/userInterfaces'
import { setRoles, setToken, setUser } from '../redux/reducers/authReducer'
import { setImpersonator } from '../redux/reducers/impersonationReducer'
import { setDataGridTranslations, setSelectedLanguage } from '../redux/reducers/languageReducer'
import { setTemplate } from '../redux/reducers/productReducer'
import { setRefData } from '../redux/reducers/refDataReducer'
import { RootState } from '../redux/store'
import i18n from '../translations/config'
import CustomColumnMenu from '../utils/CustomColumnMenu'
import CustomActionsCell from './CustomActionsCell'
import CustomBooleanCell from './CustomBooleanCell'
import CustomSitesCell from './CustomSitesCell'


interface UsersListPropsInterface {
  users: UserInterface[]
  sections: SectionInterface[]
  clientId: string | number
}
const UsersList = (props: UsersListPropsInterface) => {
  const { users, sections, clientId } = props
  const { t } = useTranslation()
  const token = useSelector((state: RootState) => state.authReducer.token);
  const currentUserInfo = useSelector((state: RootState) => state.authReducer);
  const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleEdit = (userId: number) => {
    navigate(`/client/${clientId}/users/edit/${userId}`)

  }

  //Get the role of user by section
  const getRoleValue = (params: GridValueGetterParams) => {
    const roleIndex = params.row?.rolesBySections.findIndex((role: RoleBySectionInterface) => role?.sectionId.toString() === params.field)
    //Getting the role & nesting template strings to return the final value to display
    let role = `${params.row?.rolesBySections[roleIndex]?.role.toLowerCase()}`
    let stringToDisplay = `main.user.role.${role}`
    return `${t(stringToDisplay)}`
  }

  //Create the dynamic part of the users table by sorting through the sections and creating the columns
  const sectionsColumns: GridColDef[] = sections.map((section: SectionInterface) => (
    {
      field: `${section.id}`,
      headerName: section.name,
      editable: false,
      flex: 1,
      valueGetter: getRoleValue
    }
  ))

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex:1, type: 'number', headerAlign: 'left', align:'left' },
    // { field: 'quickFdsId', headerName: 'quickFdsId', width: 160 },
    {
      field: 'firstName',
      headerName: `${t('main.user.form.firstName')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: `${t('main.user.form.lastName')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'email',
      headerName: `${t('main.user.form.email')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'login',
      headerName: `${t('main.user.form.login')}`,
      editable: false,
      flex: 1,
    },
    {
      field: 'isAdmin',
      headerName: `${t('main.user.form.isAdmin')}`,
      editable: false,
      flex: 1,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        <CustomBooleanCell value={params.value} />
      )
    },
    {
      field: 'sites',
      headerName: `${t('main.user.form.sites')}`,
      editable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <CustomSitesCell value={params.value} />
      )
    },
    ...sectionsColumns,
    {
      field: 'isPublisher',
      headerName: `${t('main.user.form.isPublisher')}`,
      editable: false,
      flex: 1,
      headerAlign: 'center',
      align:'center',
      renderCell: (params: GridRenderCellParams) => (
        <CustomBooleanCell value={params.value} />
      )
    },
    {
      field: 'actions',
      headerName: `${t('general.actions')}`,
      editable: false,
      flex: 1,
      maxWidth:160,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }}  handleImpersonation={()=>{handleImpersonation(params.row.login)}}  impersonation={true} />
      )
    }
  ];

  const handleEvent: GridEventListener<'cellClick'> = (
    params,  // GridCellParams<any>
    event,   // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    if(params.field !=='actions'){
      handleEdit(params.row.id)
    }
  }

  const handleLanguageChange = (lang:string) => {
    i18n.changeLanguage(lang);
    dispatch(setSelectedLanguage(lang));
    switch (lang) {
      case 'fr':
        dispatch(setDataGridTranslations(frFR))
        break;
      case 'en':
        dispatch(setDataGridTranslations(enUS))
        break;
      case 'nl':
        dispatch(setDataGridTranslations(nlNL))
        break;
      default:
        break;
    }
    };
    
  const handleImpersonation = async (userName:string) => {
    // setIsLoading(true)
    // setShowErrorMessage(false)
    try{
      dispatch(setImpersonator(currentUserInfo))
     const token = await impersonate(currentUserInfo.token , userName)
     const accountDetails = await getAccountDetails(token.id_token)
     dispatch(setToken(token.id_token))
     const tokenDetails:any = jwt_decode(token.id_token)
     const user =  {
      sub: tokenDetails.sub,
      auth: tokenDetails.auth,
      exp: tokenDetails.exp,
      clientId: accountDetails.clientId,
      clientName: accountDetails.clientName,
      clientLang: accountDetails.clientLang,
      firstName:accountDetails.firstName,
      lastName:accountDetails.lastName,
      logoUrl: accountDetails.logoUrl ,
      autoPublished: accountDetails.autoPublished,
      sitesNames: accountDetails.sitesNames
    }
      const sectionsRoles = {
        rolesBySections : accountDetails.rolesBySections,
        isPublisher:accountDetails.isPublisher
      }
     dispatch(setUser(user))
     dispatch(setRoles(sectionsRoles))
      if (!user.auth.includes('ROLE_SUPER_ADMIN')) {
        handleLanguageChange(accountDetails.clientLang.toLowerCase())
        const temp = await getTemplate(token.id_token)
        dispatch(setTemplate(temp.sections))
     }
    //  setIsLoading(false)
     navigate('/', {replace:true})
     if(!user.auth.includes('ROLE_SUPER_ADMIN')){
      handleRefData(user.clientId, token.id_token)
    }
    }
    catch(e){
      // setShowErrorMessage(true)
      // setIsLoading(false)
      console.warn(e)
    }
  }

  const handleRefData = async(clientId:number,token:string) =>{
    const refData = await getAllRefData(clientId,token)
     let tmpRefData : CachedRefDataInterface[] = []
     refData.forEach((obj:any) => {
      obj.values.forEach((valueObj:RefDataInterface) => {
        tmpRefData.push({
          id: valueObj.id,
          typeId:obj.id,
          type: obj.type,
          key: valueObj.key,
          clientId: valueObj.clientId,
          imageUrl:valueObj.imageUrl,
          translations:valueObj.translations

        });
      });
    });
     dispatch(setRefData(tmpRefData))
  }

  return (
    <Card>
      <CardContent sx={{ p: 1 }}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            componentsProps={{
              panel: {
                sx: {
                  top: '-120px !important',
                },
              },
            }}
            localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
            autoHeight
            rows={users}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'asc' }],
              }
            }}
            pageSizeOptions={[5, 10]}
            disableRowSelectionOnClick
            onCellClick={handleEvent}
            slots={{ columnMenu: CustomColumnMenu }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default UsersList