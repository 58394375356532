import { Link, useTheme } from '@mui/material';
interface CustomUrlCellInterface {
    value: string
    link?:string
}
const CustomLinkCell = (props : CustomUrlCellInterface) => {
    const {value,link } = props
    const theme= useTheme()
  return (
    <div className='MuiDataGrid-cellContent' style={{display:'flex', alignItems:'center'}}>
   { value &&<Link
              href={`${link}`}
              underline="always"
              variant="subtitle2"
            //   target="_blank" 
              rel="noopener"
              onClick={(e) => {e.preventDefault()}}
            >
              {value}
            </Link>}
            {/* <OpenInNewIcon sx={{color:theme.palette.secondary.main, fontSize:'0.95rem', marginLeft:0.4}} /> */}
            </div>
  )
}

export default CustomLinkCell