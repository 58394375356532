import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { mergeSectionsData } from '../helpers/sectionsData'
import { ProductDocumentsInterface } from '../interfaces/productInterfaces'
import { SectionInterface, SectionStatusInterface } from '../interfaces/sectionInterfaces'
import CustomStatusCell from './CustomStatusCell'
import { Logo } from './Logo'

interface TabDataProps {
    sectionsData: SectionStatusInterface[]
    sections: SectionInterface[]
    selectedDoc: ProductDocumentsInterface | undefined
    documentCode:string
}
const DocumentsTabData = (props: TabDataProps) => {
    const { sectionsData, sections, selectedDoc, documentCode } = props
    const { t } = useTranslation()
    const [sectionsStatus, setSectionsStatus] = useState<any[]>([])
    const theme= useTheme()

    const getDate = (date: string) => {
        return date?.slice(0, 10) || ''
    }
    const getTime = (date: string) => {
        return date?.slice(11, 19) || ''
    }

    const getDateByStatus = (obj: any) => {
        switch (obj.status) {
            case "DRAFT":
                return getTime(obj.savedAt);
            case "PRE_VALIDATED":
                return getTime(obj.preValidatedAt);
            case "VALIDATED":
                return getTime(obj.validatedAt);
            case "PUBLISHED":
                return getTime(obj.validatedAt);
            default:
                return ''
        }
    }
    const getTimeByStatus = (obj: any) => {
        switch (obj.status) {
            case "DRAFT":
                return getDate(obj.savedAt);
            case "PRE_VALIDATED":
                return getDate(obj.preValidatedAt);
            case "VALIDATED":
                return getDate(obj.validatedAt);
            case "PUBLISHED":
                return getDate(obj.validatedAt);
            default:
                return ''
        }
    }


    useEffect(() => {
        const mergedSections = mergeSectionsData(sectionsData, sections)
        setSectionsStatus(mergedSections)
    }, [sections, sectionsData])


    return (
        <>
            {/* 
    // @ts-ignore */}
            <Stack spacing={2} paddingX={4} paddingY={4} direction="row" useFlexGap flexWrap="wrap">
                {sectionsStatus.map((section: any) => (
                    <Box key={section.name} sx={{ flex: 1 }}>
                        <Typography variant='subtitle1' color='secondary'>
                            {section?.name}
                        </Typography>
                        <div style={{ position: 'relative', left: -4 }}>
                            <CustomStatusCell value={section.status.toLowerCase()} fullText={true} />
                        </div>
                        <Typography variant='body2'>
                            {getDateByStatus(section)}
                        </Typography>
                        <Typography variant='body2'>
                            {getTimeByStatus(section)}
                        </Typography>
                    </Box>
                ))}

                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {'Document : ' + selectedDoc?.name}
                    </Typography>
                    <div style={{ position: 'relative', left: -4 }}>
                        <CustomStatusCell value={!selectedDoc?.changes?.underUpdate? 'published' : 'draft'} fullText={true} />
                    </div>
                    {selectedDoc && !selectedDoc?.changes?.underUpdate && 
                    <><Typography variant='body2'>
                        {getDate(selectedDoc?.lastVersions[0]?.publishedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {getTime(selectedDoc?.lastVersions[0]?.publishedAt)}
                    </Typography>
                    </>}
                    
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Link style={{ display: 'flex', alignItems: 'flex-start', flexDirection:'row', color: theme.palette.primary.main}} to={`https://www.quickfds.com/fds/${documentCode}`} target="_blank" rel="noopener noreferrer">
                        <Box
                            sx={{
                                display: 'inline-flex',
                                height: 22,
                                width: 22,
                                position:'relative',
                                bottom:6

                            }}
                        >
                            <Logo />
                        </Box>
                        <Typography sx={{  paddingX:0.5 }} variant='subtitle1' color='primary'>
                        {t('general.consultFds')}
                        </Typography>
                    </Link>
                </Box>
            </Stack>
        </>
    )
}

export default DocumentsTabData