import { styled } from '@mui/material/styles';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import SideNav from '../components/SideNav';
import TopNav from '../components/TopNav';
import { RootState } from '../redux/store';
import LayoutRoot from './LayoutRoot';



const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

export const MainLayout = () => {
  const {pathname} = useLocation();
  const [openNav, setOpenNav] = useState(false);
  const user = useSelector((state: RootState) => state.authReducer.user);


// const LayoutRoot = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flex: '1 1 auto',
//   maxWidth: '100%',
//   [theme.breakpoints.up('lg')]: {
//     paddingLeft:  user.auth.includes('ROLE_USER')? 0 : SIDE_NAV_WIDTH
//   }
// }));

  const handlePathnameChange = useCallback(
    () => {
      if (openNav) {
        setOpenNav(false);
      }
    },
    [openNav]
  );

  useEffect(
    () => {
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );
  return (
    <>
      <TopNav onNavOpen={() => setOpenNav(true)} />
      <SideNav
        onClose={() => setOpenNav(false)}
        open={openNav}
      />
      {/* 
    // @ts-ignore */}
      <LayoutRoot user={user}>
        <LayoutContainer>
          <Outlet />
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
}

export default MainLayout 
