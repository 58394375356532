import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { SectionStatusInterface } from '../interfaces/sectionInterfaces'
import CustomStatusCell from './CustomStatusCell'
import { Logo } from './Logo'
interface TabDataProps {
    tabData: SectionStatusInterface
    documentCode:string
}
const TabData = (props: TabDataProps) => {
    const { tabData, documentCode } = props
    const theme=useTheme()
    const { t } = useTranslation()
    const getDate = (date: string) => {
        return date?.slice(0, 10) || ''
    }
    const getTime = (date: string) => {
        return date?.slice(11, 19) || ''
    }
    return (
        <>
            {/* 
    // @ts-ignore */}
            <Stack spacing={2} paddingX={4} paddingY={4} direction="row" useFlexGap flexWrap="wrap">
                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.saved')}
                    </Typography>
                    <Typography variant='body2'>
                        {getDate(tabData?.savedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {getTime(tabData?.savedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {tabData?.savedBy}
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.pre_validated')}
                    </Typography>
                    <Typography variant='body2'>
                        {getDate(tabData?.preValidatedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {getTime(tabData?.preValidatedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {tabData?.preValidatedBy}
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.validated')}
                    </Typography>
                    <Typography variant='body2'>
                        {getDate(tabData?.validatedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {getTime(tabData?.validatedAt)}
                    </Typography>
                    <Typography variant='body2'>
                        {tabData?.validatedBy}
                    </Typography>
                </Box>
                {/* <Box>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.published')}
                    </Typography>
                    <Typography variant='body2'>
                        22/05/2023
                    </Typography>
                    <Typography variant='body2'>
                        13:29:05
                    </Typography>
                    <Typography variant='body2'>
                        Amine Chaffari
                    </Typography>
                </Box> */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.version')}
                    </Typography>
                    <Typography variant='body2'>
                        {tabData?.version}
                    </Typography>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Typography variant='subtitle1' color='secondary'>
                        {t('main.products.product.status')}
                    </Typography>
                    <div style={{ position: 'relative', left: -4 }}>
                        <CustomStatusCell value={tabData?.status.toLocaleLowerCase()} fullText={true} />
                    </div>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Link style={{ display: 'flex', alignItems: 'flex-start', flexDirection:'row', color: theme.palette.primary.main}} to={`https://www.quickfds.com/fds/${documentCode}`} target="_blank" rel="noopener noreferrer">
                        <Box
                            sx={{
                                display: 'inline-flex',
                                height: 22,
                                width: 22,
                                position:'relative',
                                bottom:6

                            }}
                        >
                            <Logo />
                        </Box>
                        <Typography sx={{  paddingX:0.5 }} variant='subtitle1' color='primary'>
                        {t('general.consultFds')}
                        </Typography>
                    </Link>
                </Box>

            </Stack>
        </>
    )
}

export default TabData