import { stringify } from "querystring";
import { RefDataTranslationInterface, RefDataTypeTranslationInterface } from "../../interfaces/refDataInterfaces";
import { baseDevUrl } from "../urls";

export interface AddRefDataTypeBody {
    type : string;
    translations: RefDataTypeTranslationInterface[];
}
export interface AddRefDataBody {
    key : string;
    translations:RefDataTranslationInterface[];
}


export const  getEditableRefDataTypes = async (clientId : number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.reference-data-types+json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}
export const  getEditableRefDataType = async (clientId : number, typeId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}

export const  addEditableRefDataType = async (body : AddRefDataTypeBody, clientId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`TYPE_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}

    return response.ok
}

export const  editEditableRefDataType = async (body : AddRefDataTypeBody, clientId:number, typeId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}`, {
     
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

    return response.ok
}

export const addEditableRefData = async (body : AddRefDataBody, clientId:number, typeId:number, token:string,) => {
    const formData = new FormData();
    const data = JSON.stringify(body)
    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')
const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}/keys`, {
     
    method: "POST",
    body: formData,     
    
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`KEY_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}

    return response.ok
}

export const editEditableRefData = async (body : AddRefDataBody, clientId:number, typeId:number,keyId:number, token:string,) => {
    const formData = new FormData();
    const data = JSON.stringify(body)
    //@ts-ignore
    formData.append("data", data)
    formData.append("image", '')

const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}/keys/${keyId}`, {
     
    method: "PUT",
    body: formData,     
    
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

    return response.ok
}

export const  getEditableRefDataByKey = async (clientId : number, typeId:number, keyId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}/keys/${keyId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}
export const  getEditableRefData = async (clientId : number, typeId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}/keys`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
}

export const  disableEditableRefData = async (clientId : number, typeId:number, keyId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/clients/${clientId}/reference-data/${typeId}/keys/${keyId}`, {
     
    method: "DELETE",
    headers: {
        "Authorization": `Bearer ${token}`,
        // "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	return response.ok
}