import { Box, Card, CardContent } from '@mui/material'
import { DataGrid, GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DocumentInterface } from '../interfaces/documentInterface'
import { RootState } from '../redux/store'
import CustomColumnMenu from '../utils/CustomColumnMenu'
import CustomActionsCell from './CustomActionsCell'

interface DocumentsListPropsInterface{
    clientId:string
    documents:DocumentInterface[]
}
const DocumentsList = (props : DocumentsListPropsInterface) => {
  const { t } = useTranslation()
    const {documents, clientId} = props
    const token = useSelector((state: RootState) => state.authReducer.token);
    const dataGridTranslations = useSelector((state: RootState) => state.languageReducer.dataGridTranslations);
    const navigate = useNavigate()


    const handleEdit = (documentId: number) => {
      navigate(`/client/${clientId}/documents/edit/${documentId}`)
  
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', minWidth: 160, flex: 1, type: 'number', headerAlign: 'left', align:'left' },
        {
            field: 'name',
            headerName: `${t('main.document.form.name')}`,
            editable: false,
            minWidth:200,
            flex: 3,
          },
        {
          field: 'comment',
          headerName: `${t('main.document.form.comment')}`,
          editable: false,
          minWidth:200,
          flex: 3,
        },
        {
          field: 'actions',
          headerName: `${t('general.actions')}`,
          editable: false,
          flex: 1,
          maxWidth:160,
          sortable:false,
          filterable:false,
          disableColumnMenu:true,
          renderCell: (params: GridRenderCellParams) => (
            <CustomActionsCell handleEdit={() => { handleEdit(params.row.id) }} />
          )
        }
      ];
    
      const handleEvent: GridEventListener<'cellClick'> = (
        params,  // GridCellParams<any>
        event,   // MuiEvent<React.MouseEvent<HTMLElement>>
        details, // GridCallbackDetails
      ) => {
        if(params.field !=='actions'){
          handleEdit(params.row.id)
        }
      }


  return (
    <Card>
      
        <CardContent sx={{ p:1 }}>
    <Box sx={{ width: '100%' }}>
    <DataGrid
     componentsProps={{
        panel: {
            sx: {
                top: '-120px !important',
            },
        },
    }}
    localeText={dataGridTranslations?.components.MuiDataGrid.defaultProps.localeText}
      autoHeight
      rows={documents}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        }
      }}
      pageSizeOptions={[5, 10]}
      disableRowSelectionOnClick
      onCellClick={handleEvent}
      slots={{ columnMenu: CustomColumnMenu }}
    />
  </Box>
  </CardContent>
  </Card>
  )
}

export default DocumentsList