import LockResetIcon from '@mui/icons-material/LockReset';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleResetFinishRequest } from '../api/auth/resetPasswordApi';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [showMessage, setShowMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { control, handleSubmit, getValues } = useForm({
    reValidateMode: 'onBlur'
  })
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');


  const handleResetPassword = async (data: FieldValues) => {
    setIsLoading(true)
    try {
      const body = {
        newPassword: data.newPassword,
        key: key || ''
      }
      const response = await handleResetFinishRequest(body)
      setIsLoading(false)
      navigate('/auth/login?reset=true', { replace: true })
    }
    catch (e) {
      setShowMessage(false)
      setIsLoading(false)
      console.warn(e)
    }

  }


  return (
    <Box
      sx={{
        // backgroundColor: 'red',
        flex: '1 1 auto',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 550,
          // maxHeight:800,
          // mr:12,
          px: 3,
          py: '20px',
          mb: 3,
          width: '80%',
          boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px rgba(0, 0, 0, 0.06)',
          backdropFilter: "blur(4px)",
          backgroundColor: 'rgba(255,255,255,0.4)',

        }}
      >
        <div>
          <Stack
            spacing={1}
            sx={{ mb: 5 }}
          >
            <Typography variant="h4">
              ProData
            </Typography>

          </Stack>
          <form onSubmit={handleSubmit(handleResetPassword)} >
            <Stack spacing={3}>
              <Controller
                control={control}
                name="newPassword"
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label={t('auth.form.newPassword')}
                    type="password"
                    error={error !== undefined}
                    helperText={error ? t(`auth.errors.${error.type}`) : ''}
                  />
                )}
              />
              <Controller
                control={control}
                name="confirmNewPassword"
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20,
                  validate: (value) => {
                    const { newPassword } = getValues();
                    return newPassword === value
                  }
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label={t('auth.form.confirmNewPassword')}
                    type="password"
                    error={error !== undefined}
                    helperText={error ? t(`auth.errors.${error.type}`) : ''}
                  />
                )}
              />
            </Stack>
            <LoadingButton
              loading={isLoading}
              fullWidth
              size="large"
              sx={{ mt: 5, mb: 2 }}
              type="submit"
              variant="contained"
              loadingPosition="start"
              startIcon={<LockResetIcon />}
            >
              {t('auth.buttons.resetPassword')}
            </LoadingButton>
          </form>
        </div>
      </Card>
      <Typography
        color="text.secondary"
        variant="body2"
      >
        Copyright © QuickFDS
      </Typography>
    </Box>
  )
}

export default ResetPassword