import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertColor,
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Unstable_Grid2 as Grid,
    Snackbar,
    TextField,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AddRefDataTypeBody, addClientRefDataType, editClientRefDataType } from '../api/client/clientRefDataApi';
import { addCommunRefDataType, editCommunRefDataType } from '../api/shared/communRefDataApi';
import { getTypeTranslation } from '../helpers/translations';
import { RootState } from '../redux/store';

export interface RefDataFormPros {
    type?: any
    clientId?: number
    commun?:boolean
}
const RefDataTypeForm = (props: RefDataFormPros) => {
    const { type,  clientId, commun=false } = props
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState('Create')
    const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>()
    const [open, setOpen] = useState(false);
    const token = useSelector((state: RootState) => state.authReducer.token);
    const theme = useTheme()
    const { control, handleSubmit, setValue, reset } = useForm({
        reValidateMode: 'onBlur'
    })

    const [errorType, setErrorType] = useState('')


    useEffect(() => {
        if (type) {
            setValue('type', type.type)
            setValue('typeFR', getTypeTranslation(type, 'fr') || '')
            setValue('typeEN', getTypeTranslation(type, 'en') || '')
            setValue('typeNL', getTypeTranslation(type, 'nl') || '')
            setMode('Edit')
        } else {
            setMode('Create')
        }

    }, [type])

    const handleAddType = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body : AddRefDataTypeBody = {
                type: data.type,
                translations:[
                    {
                        lang:'FR',
                        label:data.typeFR
                    },
                    {
                        lang:'EN',
                        label:data.typeEN
                    },
                    {
                        lang:'NL',
                        label:data.typeNL
                    }
                ]
            }
            if(commun){
                const resp = await addCommunRefDataType(body, token)

            }else{
                const resp = await addClientRefDataType(body, clientId!, token)
            }
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            reset()
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }

    const handleEditType = async (data: FieldValues) => {
        setIsLoading(true)
        setAlertSeverity(undefined)
        setErrorType('')
        try {
            let body : AddRefDataTypeBody = {
                type: data.type,
                translations:[
                    {
                        lang:'FR',
                        label:data.typeFR
                    },
                    {
                        lang:'EN',
                        label:data.typeEN
                    },
                    {
                        lang:'NL',
                        label:data.typeNL
                    }
                ]
            }
            if(commun){
                const resp = await editCommunRefDataType(body, type?.id!, token)
            }else{
                const resp = await editClientRefDataType(body, clientId!, type?.id!, token)
            }
            setIsLoading(false)
            setAlertSeverity('success')
            setOpen(true)
            //  navigate('/', {replace:true})
        }
        catch (e: unknown) {
            if (e instanceof Error) {
              setErrorType(e.message !=='uknown'? e.message : '')
            }else{
              setErrorType('')
            }
            setOpen(true)
            setAlertSeverity('error')
            setIsLoading(false)
            console.warn(e)
        }
    }
    const handleFormSubmit = async (data: FieldValues) => {
        if (!type) {
            handleAddType(data)
        } else {
            handleEditType(data)
        }
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string | undefined) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)} >
            <Card>
                <CardHeader
                    subheader={t('main.refData.form.addTypeDesc')}
                // title="Profile"
                />
                <CardContent sx={{ pt: 0 }}>
                    <Box sx={{ m: -1.5 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="type"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        // minLength: 4,
                                        // maxLength: 20
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            required
                                            fullWidth
                                            label={t('main.refData.form.type')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="typeFR"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.typeFR')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="typeEN"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.typeEN')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                            >
                                <Controller
                                    control={control}
                                    name="typeNL"
                                    defaultValue=""
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            label={t('main.refData.form.typeNL')}
                                            type="text"
                                            error={error !== undefined}
                                            helperText={error ? t(`main.refData.errors.${error.type}`) : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions sx={{ justifyContent: 'flex-end' }}>

                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        size="large"
                        sx={{ mt: 5, mb: 2 }}
                        type="submit"

                    >{t('general.save')}
                    </LoadingButton>
                </CardActions>
            </Card>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity={alertSeverity} sx={{ width: '100%', color: 'white' }} variant='filled' >
                    {t(`main.refData.alertMessage.${alertSeverity}${mode}Type${errorType}`)}
                </Alert>
            </Snackbar>
        </form>
    );
}

export default RefDataTypeForm