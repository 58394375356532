import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Card,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { enUS, frFR, nlNL } from '@mui/x-data-grid';
import jwt_decode from "jwt-decode";
import { useState } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { handleLoginRequest } from '../api/auth/loginApi';
import { getAllRefData } from '../api/client/clientRefDataApi';
import { getTemplate } from '../api/client/productsApi';
import { getAccountDetails } from '../api/shared/accountDetailsApi';
import { CachedRefDataInterface, RefDataInterface } from '../interfaces/refDataInterfaces';
import { setRoles, setToken, setUser } from '../redux/reducers/authReducer';
import { setDataGridTranslations, setSelectedLanguage } from '../redux/reducers/languageReducer';
import { setTemplate } from '../redux/reducers/productReducer';
import { setRefData } from '../redux/reducers/refDataReducer';
import i18n from '../translations/config';


const Login = () => {
  const { t } = useTranslation();
  const theme = useTheme()
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onBlur'
  })
  const [searchParams] = useSearchParams();
  const showResetMessage = searchParams.get('reset') || false ;

  const handleLanguageChange = (lang:string) => {
    i18n.changeLanguage(lang);
    dispatch(setSelectedLanguage(lang));
    switch (lang) {
      case 'fr':
        dispatch(setDataGridTranslations(frFR))
        break;
      case 'en':
        dispatch(setDataGridTranslations(enUS))
        break;
      case 'nl':
        dispatch(setDataGridTranslations(nlNL))
        break;
      default:
        break;
    }
    };

  const handleLogin = async (data : FieldValues) => {
    setIsLoading(true)
    setShowErrorMessage(false)
    try{
      let body = {
        username: data.username,
        password: data.password,
        rememberMe: true,
      }
     const token = await handleLoginRequest(body)
     const accountDetails = await getAccountDetails(token.id_token)
     dispatch(setToken(token.id_token))
     const tokenDetails:any = jwt_decode(token.id_token)
     const user =  {
      sub: tokenDetails.sub,
      auth: tokenDetails.auth,
      exp: tokenDetails.exp,
      clientId: accountDetails.clientId,
      clientName: accountDetails.clientName,
      clientLang: accountDetails.clientLang,
      firstName:accountDetails.firstName,
      lastName:accountDetails.lastName,
      logoUrl: accountDetails.logoUrl ,
      autoPublished: accountDetails.autoPublished,
      sitesNames: accountDetails.sitesNames
    }
      const sectionsRoles = {
        rolesBySections : accountDetails.rolesBySections,
        isPublisher:accountDetails.isPublisher
      }
     dispatch(setUser(user))
     dispatch(setRoles(sectionsRoles))
     if(!user.auth.includes('ROLE_SUPER_ADMIN')){
     const temp = await getTemplate(token.id_token)
     dispatch(setTemplate(temp.sections))
     handleLanguageChange(user.clientLang.toLowerCase())
     }
     setIsLoading(false)
     navigate('/', {replace:true})
     if(!user.auth.includes('ROLE_SUPER_ADMIN')){
       handleRefData(user.clientId, token.id_token)
    }
    }
    catch(e){
      setShowErrorMessage(true)
      setIsLoading(false)
      console.warn(e)
    }
  }

  const handleRefData = async(clientId:number,token:string) =>{
    const refData = await getAllRefData(clientId,token)
     let tmpRefData : CachedRefDataInterface[] = []
     refData.forEach((obj:any) => {
      obj.values.forEach((valueObj:RefDataInterface) => {
        tmpRefData.push({
          id: valueObj.id,
          typeId:obj.id,
          type: obj.type,
          key: valueObj.key,
          clientId: valueObj.clientId,
          imageUrl:valueObj.imageUrl,
          translations:valueObj.translations

        });
      });
    });
     dispatch(setRefData(tmpRefData))
  }

  return (
    <Box
      sx={{
        // backgroundColor: 'red',
        flex: '1 1 auto',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: 550,
          // maxHeight:800,
          // mr:12,
          px: 3,
          py: '20px',
          mb: 3,
          width: '80%',
          boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.03), 0px 5px 22px rgba(0, 0, 0, 0.06)',
          backdropFilter: "blur(4px)",
          backgroundColor: 'rgba(255,255,255,0.4)',

        }}
      >
        <div>
          <Stack
            spacing={1}
            sx={{ mb: 5 }}
          >
            <Typography variant="h4">
              {t('general.login')}
            </Typography>

          </Stack>
          <form onSubmit={handleSubmit(handleLogin)} >
          {showResetMessage && <Alert
              color="success"
              icon={<CheckCircleIcon />}
              sx={{ mt: 1, backgroundColor: 'transparent' }}
            >
              <Typography
                color={theme.palette.success.main}
                // color="text.secondary"
                variant="body2"
              >
                {t('auth.form.resetPasswordSuccess')}
              </Typography>
            </Alert>}
            <Stack spacing={3}>
              <Controller
                control={control}
                name="username"
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label={t('auth.form.login')}
                    type="text"
                    error={error !== undefined}
                    helperText={error ? t(`auth.errors.${error.type}`) : ''}
                  />
                )}
              />
              <Controller
                control={control}
                name="password"
                defaultValue=""
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label={t('auth.form.password')}
                    type="password"
                    error={error !== undefined}
                    helperText={error ? t(`auth.errors.${error.type}`) : ''}
                  />
                )}
              />
            </Stack>
            <Link
              href="/auth/requestResetPassword"
              underline="hover"
              variant="subtitle2"
            >
              {t('auth.buttons.forgotPassword')}
            </Link>
            {showErrorMessage && (
              <Alert
              color="error"
              icon={<ErrorIcon />}
              sx={{ mt: 1, backgroundColor: 'transparent' }}
            >
              <Typography
                color="error"
                variant="body2"
              >
                {t("auth.errors.wrongCredentials")}
              </Typography>
              </Alert>
            )}
            <LoadingButton
            fullWidth
        loading={isLoading}
        loadingPosition="start"
        startIcon={<LoginIcon />}
        variant="contained"
        size="large"
        sx={{ mt: 5, mb: 2 }}
        type="submit"

      >{t('auth.buttons.signIn')}</LoadingButton>
            {/* <Button
              fullWidth
              size="large"
              sx={{ mt: 5, mb: 2 }}
              type="submit"
              variant="contained"
              startIcon={<LoginIcon />}
            >
              {t('auth.buttons.signIn')}
            </Button> */}
          </form>
        </div>
      </Card>
      <Card
        sx={{
          maxWidth: 550,
          mb: 2,
          px: 3,
          py: '20px',
          width: '80%',

        }}
      >
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {t('auth.form.noAccount')}
          <Link
            // href='mailto:support.client@quickfds.com'
            onClick={(e) => {
              window.location.href = 'mailto:support.client@quickfds.com'
              e.preventDefault();
          }}
            underline="hover"
            variant="subtitle2"
          >
            {t('auth.buttons.contact')}
          </Link>
        </Typography>
      </Card>
      <Typography
        color="text.secondary"
        variant="body2"
      >
        Copyright © QuickFDS
      </Typography>
    </Box>
  );
}

export default Login;
