import { baseDevUrl } from "../urls";

export interface AddSiteBody {
    name : string;
    ref:string;
}


export const  getSite = async (clientId : number, siteId:number, token:string) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sites/${siteId}`, {
     
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    
	const data = await response.json();
    return data
    // return response.ok
}

export const  addSite = async (body : AddSiteBody, clientId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sites`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`CLIENT_NAME_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}

export const  editSite = async (body : AddSiteBody, clientId:number, siteId:number, token:string,) => {

	const response = await fetch(`${baseDevUrl}/api/admin/clients/${clientId}/sites/${siteId}`, {
     
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		if(response.status=== 409){
            throw new Error(`SITE_ALREADY_USED`);
        }else{
            throw new Error('uknown');

        }
	}
    
	// const data = await response.json();
    // return data
    return response.ok
}
