import { baseDevUrl } from "../urls";

interface SearchProductsInterface{
    name:string
}

export const  getProducts = async (clientId : number, token:string, params:any) => {
//param client id
let sortString = params.sort? `&sort=${params.sort}&direction=${params.direction}` : ''
 
  const queryString = Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

 let url =`${baseDevUrl}/api/products?clientId=${clientId}&${queryString}`
	const response = await fetch(url, {
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.product-detail+json",
        "Access-Control-Allow-Origin": "*",
        
    },
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
    const totalCount = parseInt(`${response.headers.get('X-Total-Count') || 0}`) 
	const data = await response.json();
    return {data, totalCount}
    // return response.ok
}
export const  getProduct = async (productId : number, token:string, clientId?:number) => {
    const queryParams= clientId? `?clientId=${clientId}`: ''
	const response = await fetch(`${baseDevUrl}/api/products/${productId}${queryParams}`, {
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}


export const  searchProducts = async (body : SearchProductsInterface, token:string, clientId?:number) => {
    const queryParams= clientId? `?clientId=${clientId}`: ''
	const response = await fetch(`${baseDevUrl}/api/products${queryParams}`, {
     
    method: "POST",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Accept": "application/vnd.quickfds.prodata.product-name+json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}

export const  publishProductDocument = async ( token:string, productId:number, documentId:number) => {

	const response = await fetch(`${baseDevUrl}/api/products/${productId}/documents/${documentId}`, {
     
    method: "POST",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	// const data = await response.json();
    // return data
    return response.ok
}

export const  updateProduct = async (body : any, token:string, productId:number) => {

	const response = await fetch(`${baseDevUrl}/api/products/${productId}`, {
     
    method: "PATCH",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	// const data = await response.json();
    // return data
    return response.ok
}
export const  updateProductSectionStatus = async (body : any, token:string, productId:number, sectionId:number) => {

	const response = await fetch(`${baseDevUrl}/api/products/${productId}/sections/${sectionId}`, {
  
    method: "PUT",
    body: JSON.stringify(body),     
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	// const data = await response.json();
    // return data
    return response.ok
}

export const  getProductPdfVisualisation = async (productId : number, token:string, templateName:string) => {
//ajouter templateId et et templateName
	const response = await fetch(`${baseDevUrl}/renderer/product/${productId}?template=${templateName}&showDiff=true`, {
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Accept-Language": "fr-FR,fr;q=0.9"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.text()
    return data
    // return response.ok
}

export const  getProductPdf = async (productId : number, token:string, templateName:string) => {

	const response = await fetch(`${baseDevUrl}/pdf-renderer/product/${productId}?template=${templateName}`, {
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/pdf",
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.blob()
    const url = window.URL.createObjectURL(data);
    const newWindow = window.open(url, '_blank');
    return data
    // return response.ok
}

export const  getTemplate = async ( token:string, clientId? : number) => {
    let clientIdString = clientId? `?clientId=${clientId}` : ''

	const response = await fetch(`${baseDevUrl}/api/forms${clientIdString}`, {
    method: "GET",
    headers: {
        "Authorization": `Bearer ${token}`,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
})
	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
	const data = await response.json();
    return data
    // return response.ok
}