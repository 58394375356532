import { alpha, useTheme } from '@mui/material';
import { ProductDocumentsInterface } from '../interfaces/productInterfaces';

interface CustomDocumentsCellInterface {
    documents?: ProductDocumentsInterface[]
}
const CustomDocumentsCell = (props : CustomDocumentsCellInterface) => {
    const { documents  } = props
    const theme = useTheme()
    const cellStyle={
        alignItems: 'center',
        borderRadius: 12,
        cursor: 'default',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        // fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 2,
        fontWeight: 600,
        justifyContent: 'center',
        letterSpacing: 0.5,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        minWidth: 20,
        marginBottom:documents && documents?.length>0 ? 6 : 0,
        
        
    }
  return (
    <div style={{display:'flex', flexDirection:'column',}}>
    {documents && documents?.length>0 && documents?.map((document :ProductDocumentsInterface )=>(
        document.changes.underUpdate? <div key={document.id} className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color:theme.palette.grey[600], backgroundColor:alpha(theme.palette.grey[600], 0.12)}} >{document.name}</span></div>:
        <div key={document.id} className='MuiDataGrid-cellContent'> <span style={{ ...cellStyle ,color:theme.palette.success.main, backgroundColor:alpha(theme.palette.success.main, 0.12)}} >{document.name}</span></div>
    ))
    }
    </div>
  )
}

export default CustomDocumentsCell