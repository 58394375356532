import React from 'react'
import { GridColumnMenu, GridColumnMenuProps } from "@mui/x-data-grid";

const CustomColumnMenu = (props: GridColumnMenuProps) => {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Hide `columnMenuColumnsItem`
          columnMenuColumnsItem: null,
        }}
      />
    );
  }

export default CustomColumnMenu