import { MenuItem, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { getTranslation } from '../helpers/translations'
import useSearchByTypeId from '../hooks/useSearchByTypeId'

const MultiSelectRefData = (props:any) => {
    const {control, fieldName, rules, value, selectedLanguage, errors, label, optionsByTypeId, formState, handleFieldChange, clientLang, disabled=false} = props
    const options = useSearchByTypeId(optionsByTypeId)
        


  return (
    <Controller
                    control={control}
                    name={fieldName}
                    rules={rules}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            required={rules.required ? rules.required : false}
                            fullWidth
                            disabled={disabled}
                            label={label[selectedLanguage] || label[clientLang]}
                            select
                            SelectProps={{
                                multiple: true,
                            }}
                            error={error !== undefined}
                            value={formState[`${fieldName}`] || []}
                            onChange={handleFieldChange}
                            helperText={error ? `${errors[error.type][selectedLanguage]}` : ''}
                        >
                            {options.map((option: any) => (
                                <MenuItem
                                    key={option.key}
                                    value={option.key}
                                >
                                    {getTranslation(option,selectedLanguage)}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
  )
}

export default MultiSelectRefData