import TableCellsIcon from '@heroicons/react/24/solid/TableCellsIcon';
import UsersIcon from '@heroicons/react/24/solid/UsersIcon';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Box,
    Divider,
    Drawer,
    Stack,
    SvgIcon,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../redux/store';
import { Logo } from './Logo';
import { Scrollbar } from './ScrollBar';
import SideNavItem from './SideNavItem';
const version = require('../../package.json').version


interface SideNavProps {
    onClose: () => void;
    open: boolean
}


export interface ItemTypes {
    active?: boolean;
    title: string;
    path: string;
    icon?: ReactElement;
    disabled?: boolean;
    external?: boolean;
}
const superAdminItems: ItemTypes[] = [
    {
        title: 'clients',
        path: '/',
        icon: (
            <SvgIcon fontSize="small">
                <UsersIcon />
            </SvgIcon>
        )
    },
    {
        title: 'referenceData',
        path: '/commun/referenceDataType/list',
        icon: (
            <SvgIcon fontSize="small">
                <TableCellsIcon />
            </SvgIcon>
        )
    },
    // {
    //     title: 'account',
    //     path: '/account',
    //     icon: (
    //         <SvgIcon fontSize="small">
    //             <UserIcon />
    //         </SvgIcon>
    //     )
    // },
    // {
    //     title: 'settings',
    //     path: '/settings',
    //     icon: (
    //         <SvgIcon fontSize="small">
    //             <CogIcon />
    //         </SvgIcon>
    //     )
    // }
];
const userItems: ItemTypes[] = [
    {
        title: 'products',
        path: '/',
        icon: (
            <SvgIcon fontSize="small">
                <ArticleIcon />
            </SvgIcon>
        )
    }
];
const adminItems: ItemTypes[] = [
    {
        title: 'products',
        path: '/',
        icon: (
            <SvgIcon fontSize="small">
                <ArticleIcon />
            </SvgIcon>
        )
    },
    {
        title: 'users',
        path: '/users',
        icon: (
            <SvgIcon fontSize="small">
                <UsersIcon />
            </SvgIcon>
        )
    }
];


export const SideNav = (props: SideNavProps) => {
    const { t } = useTranslation()
    const { open, onClose } = props;
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const { pathname } = useLocation();
    const user = useSelector((state: RootState) => state.authReducer.user);
    const theme = useTheme()

    const items = user?.auth.includes('ROLE_SUPER_ADMIN') ? superAdminItems : user?.auth.includes('ROLE_USER')? userItems : adminItems

    const content = (
        <Scrollbar
            sx={{
                height: '100%',
                '& .simplebar-content': {
                    height: '100%'
                },
                '& .simplebar-scrollbar:before': {
                    background: 'neutral.400'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'

                        }}
                    >

                        <Box
                            sx={{
                                display: 'inline-flex',
                                height: 38,
                                width: 38
                            }}
                        >
                            <Logo />
                        </Box>
                        <Typography variant='h5' color='primary.contrastText' marginLeft={2}>
                            {t('general.proData')}
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ borderColor: 'neutral.700' }} />
                <Box
                    component="nav"
                    sx={{
                        flexGrow: 1,
                        px: 2,
                        py: 3
                    }}
                >
                    <Stack
                        component="ul"
                        spacing={0.5}
                        sx={{
                            listStyle: 'none',
                            p: 0,
                            m: 0
                        }}
                    >
                        {items.map((item) => {
                            const active = item.path ? (pathname === item.path) : false;

                            return (
                                <SideNavItem
                                    active={active}
                                    disabled={item.disabled}
                                    external={item.external}
                                    icon={item.icon}
                                    key={item.title}
                                    path={item.path}
                                    title={t(`main.nav.${item.title}`)}
                                />
                            );
                        })}
                    </Stack>
                </Box>
            </Box>
            <div style={{ position: 'absolute', bottom: 10, left:10 }}>
                <p style={{ fontSize: 12 }}>
                    <span>{user.clientName? t('general.selectedClient',{client:user.clientName}) : ''}</span>{' '}
                </p>
                <p style={{ fontSize: 12 }}>
                    <span>{'Version : ' + version}</span>{' '}
                </p>
            </div>
        </Scrollbar>
    );

    if (lgUp && !user.auth.includes('ROLE_USER')) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280
                    }
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        );
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.800',
                    color: 'common.white',
                    width: 280
                }
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    );
};

export default SideNav